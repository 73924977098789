import { Theme } from '@mui/material'
import Button from './button'
import Input from './input'
import Alert from './alert'
import Typography from './typography'
import Avatar from './avatar'
import Stepper from './stepper'
import Accordion from './accordion'

export default function ComponentsOverrides(theme: Theme) {
  return Object.assign(
    Input(theme),
    Button(theme),
    Alert(theme),
    Typography(theme),
    Avatar(theme),
    Stepper(theme),
    Accordion(theme)
  ) as any
}
