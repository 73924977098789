import { Typography, Box, Chip } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { StoreContext } from "../../../stores/store.context";
import { Link } from "react-router-dom";

const ResearchReportMaterials: React.FC = () => {
  const { researchStore } = useContext(StoreContext);

  return (
    researchStore.researchAssessmentData && (
      <Box>
        {researchStore.researchAssessmentData.assessment.materials && (
          <Box sx={{ display: "flex" }}>
            {researchStore.researchAssessmentData.assessment.materials.map((material, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: 2,
                    minHeight: "360px",
                    maxHeight: "360px",
                    borderColor: "grey.cf2",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    width: "250px",
                    ml: index > 0 ? 2 : 0,
                  }}
                >
                  <Chip
                    label={
                      <Typography variant="body2" fontWeight={500} color="black">
                        {material.type}
                      </Typography>
                    }
                    sx={{
                      alignSelf: "flex-start",
                      backgroundColor: "grey.ce9",
                      mb: 2,
                    }}
                  />
                  <Box
                    component="img"
                    src={`${process.env.REACT_APP_ASSESSMENT_SERVICE}/uploads/assessment/materials/${material.image}`}
                  />

                  <Typography variant="h5" color="black" sx={{ my: 2 }}>
                    {material.title}
                  </Typography>

                  <Typography
                    variant="body2"
                    color="grey.c59"
                    sx={{ maxHeight: "140px", overflow: "auto" }}
                  >
                    {material.description}
                  </Typography>

                  <Box sx={{ marginTop: "auto" }}>
                    <Link to={material.link} target="_blank">
                      Saiba mais
                    </Link>
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    )
  );
};

export default observer(ResearchReportMaterials);
