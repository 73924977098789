interface IconDirectionProps {
  color?: string;
}

const IconDirection = (props: IconDirectionProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3535 21.0531L12.7427 17.8543C12.2822 17.5918 11.7173 17.5918 11.2568 17.8544L5.64678 21.0531C5.42042 21.1821 5.16428 21.2522 4.90376 21.2478C4.1421 21.2348 3.45574 21.1466 3.2823 20.5454C3.22215 20.3369 3.28732 20.1174 3.37823 19.9203L11.082 3.22457C11.1801 3.01192 11.3332 2.82373 11.564 2.78396C11.8279 2.73849 12.1751 2.73868 12.4375 2.78454C12.6649 2.82429 12.8154 3.01023 12.9121 3.21986L20.6217 19.9204C20.7126 20.1174 20.7778 20.3368 20.7178 20.5453C20.5446 21.1466 19.8582 21.2348 19.0965 21.2478C18.836 21.2522 18.5799 21.1821 18.3535 21.0531Z"
        stroke={props.color ?? "white"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12 13.0054V17.1277"
        stroke={props.color ?? "white"}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconDirection;
