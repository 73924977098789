import { observer } from "mobx-react-lite";
import { Fragment, useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Container } from "@mui/material";
import { StoreContext } from "../stores/store.context";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import TokenValidation from "../components/token-validation";
import { motion } from "framer-motion";

const pageVariants = {
  initial: {
    transform: "translateX(100%)",
    opacity: 0,
  },
  in: {
    transform: "translateX(0)",
    opacity: 1,
  },
  out: {
    transform: "translateX(100%)",
    opacity: 0,
  },
};

const pageTransition = {
  type: "tween",
  ease: "linear",
  duration: 0.5,
};

const AuthenticatedLayout: React.FC = () => {
  const { themeStore, userStore, toastStore, keycloakStore } = useContext(StoreContext);
  const { promiseInProgress } = usePromiseTracker({ area: "validating" });

  const location = useLocation();

  useEffect(() => {
    const loadUserData = async () => {
      if (keycloakStore.authenticated && keycloakStore.keycloak) {
        try {
          keycloakStore.token = keycloakStore.keycloak.token || null;

          await trackPromise(userStore.userTokenValidate(), "validating");
        } catch (error) {
          toastStore.pushToast({ message: "Failed to retrieve user data", type: "error" });
        }
      }
    };
    loadUserData();
  }, [keycloakStore, toastStore, userStore]);

  return (
    <Fragment>
      {keycloakStore.authenticated && !promiseInProgress ? (
        userStore.user && (
          <>
            <Navbar />
            <Container
              sx={{
                maxWidth: {
                  xs: themeStore.theme.breakpoints.values.lg,
                  xl: themeStore.theme.breakpoints.values.xl,
                },
              }}
            >
              <motion.div
                key={location.pathname}
                initial="initial"
                animate="in"
                variants={pageVariants}
                transition={pageTransition}
              >
                <Outlet />
              </motion.div>
            </Container>
            <Footer />
          </>
        )
      ) : (
        <TokenValidation />
      )}
    </Fragment>
  );
};

export default observer(AuthenticatedLayout);
