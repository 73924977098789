import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../../../stores/store.context";
import { useContext } from "react";
import StarsIcon from "@mui/icons-material/Stars";
import StatusCircle from "../../../components/status-circle";

const CompanyKeyUserDataCard: React.FC = () => {
  const { companyStore, generalStore } = useContext(StoreContext);

  return (
    companyStore.company && (
      <Box
        sx={{
          borderWidth: 1,
          borderColor: "grey.ca3",
          borderRadius: "8px",
          borderStyle: "solid",
          width: "100%",
          p: 4,
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "170px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <StarsIcon sx={{ fontSize: "40px", mr: 2, color: "primary.main" }} />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body1" color="secondary.lighter">
                Cliente master:
              </Typography>
              <Typography variant="h5" color="primary.dark" sx={{ mt: 1 }}>
                {companyStore.company.keyUser!.firstName}
              </Typography>
            </Box>
          </Box>

          {/* <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Typography variant="body1" color="secondary.lighter">
              Status:
            </Typography>

            <Box sx={{ ml: 1, mr: 2, display: "flex", alignItems: "center" }}>
              <StatusCircle status={companyStore.company.keyUser!.enabled!} />
              <Typography variant="body2" color="text.primary">
                {generalStore.getStatusText(companyStore.company.keyUser!.enabled!)}
              </Typography>
            </Box>
          </Box> */}

          {/* TODO: Validar o status do usuário no serviço da Meta */}
          {/* <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Typography variant="body1" color="secondary.lighter">
              Status:
            </Typography>

            <Box sx={{ ml: 1, mr: 2, display: "flex", alignItems: "center" }}>
              <StatusCircle status={companyStore.company.keyUser.status} />
              <Typography variant="body2" color="text.primary">
                {generalStore.getStatusText(companyStore.company.keyUser.status)}
              </Typography>
            </Box>
          </Box> */}
        </Box>
      </Box>
    )
  );
};

export default observer(CompanyKeyUserDataCard);
