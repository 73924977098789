import { Theme } from "@mui/material";
import { makeAutoObservable } from "mobx";
import { createTheme } from "@mui/material";
import Palette from "../theme/palette";
import componentsOverride from "../theme/overrides";
import typography from "../theme/typography";

type ThemeMode = "light" | "dark";

class ThemeStore {
  private _themeMode: ThemeMode = "light";
  private _theme: Theme;

  constructor() {
    makeAutoObservable(this);
    this._theme = createTheme({
      palette: Palette("light"),
      typography: typography,
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1170,
          xl: 1615,
        },
      },
    });
    this._theme.components = componentsOverride(this._theme);
  }

  setThemeMode(mode: ThemeMode) {
    this._themeMode = mode;
    this._theme = createTheme({ ...this._theme, palette: Palette(mode), typography: typography });
    this._theme.components = componentsOverride(this._theme);
  }

  get themeMode() {
    return this._themeMode;
  }

  get theme() {
    return this._theme;
  }
}

export default ThemeStore;
