import { Box, Divider, Typography } from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { howItWorksStepsClientAdmin } from "../constants/how-it-works-steps-client-admin";
import { howItWorksStepsConsultant } from "../constants/how-it-works-steps-consultant";
import { StoreContext } from "../../../stores/store.context";
import { DXIRoles } from "../../../enums/dxi_roles.enum";

const HowItWorks: React.FC = () => {
  const { userStore } = useContext(StoreContext);
  const [steps, setSteps] = useState<Array<{ title: String; description: String }>>([]);

  useEffect(() => {
    setSteps(() => {
      let newSteps =
        userStore.userRole === DXIRoles.DXI_CONSULTANT
          ? howItWorksStepsConsultant
          : howItWorksStepsClientAdmin;

      return newSteps;
    });
  }, [userStore.userRole]);

  return (
    <Fragment>
      {steps &&
        steps.map((step, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              p: 4,
              backgroundColor: "background.default",
              alignItems: "center",
              mt: index > 0 ? 3 : 0,
            }}
          >
            <Typography
              fontSize="72px"
              lineHeight="56px"
              fontWeight={800}
              sx={{
                color: "success.main",
                display: "flex",
                alignItems: "center",
                minWidth: "150px",
              }}
            >
              0{index + 1} <ArrowRightIcon sx={{ color: "success.main", fontSize: "40px" }} />
            </Typography>

            <Typography
              variant="h4"
              color="primary.dark"
              sx={{ minWidth: { xs: "320px", xl: "500px" } }}
            >
              {step.title}
            </Typography>

            <Divider orientation="vertical" flexItem sx={{ mx: 6 }} />

            <Typography variant="body1" color="grey.c59" sx={{ maxWidth: "500px" }}>
              {step.description}
            </Typography>
          </Box>
        ))}
    </Fragment>
  );
};

export default HowItWorks;
