import { makeAutoObservable } from "mobx";
import { ResearchService } from "../services/research.service";
import { Pagination } from "../models/pagination.model";
import {
  Research,
  ResearchAssessmentUserAnswer,
  ResearchAssessments,
} from "../models/research.model";
import { ToastStore } from "./toast.store";
import { UserData } from "../models/user.model";

export class ResearchStore {
  private _researches: Pagination<Array<Research>> | null = null;
  private _research: Research | null = null;
  private _researchAssessments: ResearchAssessments[] | null = null;
  private _selectedResearch: Research | null = null;
  private _researchAssessmentData: ResearchAssessments | null = null;
  private _researchAssessmentUser: Array<ResearchAssessmentUserAnswer> | null = null;
  private _lastAccessResearch: Research | null = null;

  toastStore?: ToastStore;

  constructor(toastStore?: ToastStore) {
    makeAutoObservable(this);
    if (toastStore) {
      this.toastStore = toastStore;
    }
  }

  async addResearch(companyId: string, research: Research) {
    try {
      const researchService = new ResearchService();
      const res: Research = await researchService.addResearch(companyId, research);
      return res;
    } catch (e: any) {
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e.toString(), type: "error" });
      }
    }
  }

  async changeResearchStatus(researchId: string, status: string) {
    try {
      const researchService = new ResearchService();
      const res: Research = await researchService.changeResearchStatus(researchId, status);
      this.research!.status! = res.status!;
      return res;
    } catch (e: any) {
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e.toString(), type: "error" });
      }
    }
  }

  async addAssessmentsToResearch(researchId: string, assessments: Array<string>) {
    try {
      const researchService = new ResearchService();
      const res: Research = await researchService.addAssessmentsToResearch(researchId, assessments);
      return res;
    } catch (e: any) {
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e.toString(), type: "error" });
      }
    }
  }

  async listResearches(
    page: number,
    limit: number,
    orderBy?: string,
    orderbyDirection?: string,
    filter?: string
  ): Promise<void> {
    try {
      const researchService = new ResearchService();
      const res: Pagination<Array<Research>> = await researchService.listResearches(
        page,
        limit,
        orderBy,
        orderbyDirection,
        filter
      );

      if (res && page === 1) {
        this.researches = res;
      } else {
        this.pushResearches(res.paginatedData);
      }
    } catch (e: any) {
      this.researches = null;
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e.toString(), type: "error" });
      }
    }
  }

  async listResearchesByCompany(
    companyId: string,
    page: number,
    limit: number,
    orderBy?: string,
    orderbyDirection?: string,
    filter?: string
  ): Promise<void> {
    try {
      const companyService = new ResearchService();
      const res: Pagination<Array<Research>> = await companyService.listResearchesByCompany(
        companyId,
        page,
        limit,
        orderBy,
        orderbyDirection,
        filter
      );
      if (res && page === 1) {
        this.researches = res;
      } else {
        this.pushResearches(res.paginatedData);
      }
    } catch (e: any) {
      this.researches = null;
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e, type: "error" });
      }
    }
  }

  async getResearchAssessmentsByUser(researchId: string, userId: string): Promise<any> {
    try {
      const researchService = new ResearchService();
      const res: ResearchAssessments[] = await researchService.getResearchAssessmentsByUser(
        researchId,
        userId
      );

      if (res) {
        this.researchAssessments = res;
      }
    } catch (e: any) {
      this.researchAssessments = null;

      if (this.toastStore) {
        this.toastStore.pushToast({ message: e, type: "error" });
      }
    }
  }

  async getResearchById(id: string): Promise<void> {
    try {
      const researchService = new ResearchService();
      const res: Research = await researchService.getResearchById(id);

      if (res) {
        this.research = res;
      }
    } catch (e: any) {
      this.research = null;

      if (this.toastStore) {
        this.toastStore.pushToast({ message: e, type: "error" });
      }
    }
  }

  async getResearchAssessmentData(id: string, assessmentId: string): Promise<void> {
    try {
      const researchService = new ResearchService();
      const res: any = await researchService.getResearchAssessmentData(id, assessmentId);

      if (res) {
        this.researchAssessmentData = res;
      }
    } catch (e: any) {
      this.researchAssessmentData = null;

      if (this.toastStore) {
        this.toastStore.pushToast({ message: e, type: "error" });
      }
    }
  }

  async getUserResearchAssessment(
    researchId: string,
    assessmentId: string,
    userId: string
  ): Promise<void> {
    try {
      const researchService = new ResearchService();
      const res: any = await researchService.getUserResearchAssessment(
        researchId,
        assessmentId,
        userId
      );

      if (res) {
        this.researchAssessmentUser = res;
      }
    } catch (e: any) {
      this.researchAssessmentUser = null;

      if (this.toastStore) {
        this.toastStore.pushToast({ message: e, type: "error" });
      }
    }
  }

  async saveResearchAssessmentAnswer(
    researchId: string,
    assessmentId: string,
    userId: string,
    question: { questionId: string; answer: number }
  ): Promise<boolean> {
    try {
      const researchService = new ResearchService();
      await researchService.saveResearchAssessmentAnswer(
        researchId,
        assessmentId,
        userId,
        question
      );
      return true;
    } catch (e: any) {
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e.toString(), type: "error" });
      }
      throw e;
    }
  }

  async getLastAccessedResearch(userId: string): Promise<void> {
    try {
      const researchService = new ResearchService();
      const res: Research = await researchService.getLastAccessedResearch(userId);

      if (res) {
        this.lastAccessedResearch = res;
      }
    } catch (e: any) {
      this.lastAccessedResearch = null;
    }
  }

  async inviteUserToResearch(researchId: string, user: UserData | string, assessments: string[]) {
    try {
      const researchService = new ResearchService();
      await researchService.inviteUserToResearch(researchId, user, assessments);
      return true;
    } catch (e: any) {
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e.toString(), type: "error" });
      }
      throw e;
    }
  }

  set researches(researchesList: Pagination<Array<Research>> | null) {
    this._researches = researchesList;
  }

  get researches() {
    return this._researches;
  }

  set research(researchData: Research | null) {
    this._research = researchData;
  }

  get research() {
    return this._research;
  }

  set researchAssessments(assessments: ResearchAssessments[] | null) {
    this._researchAssessments = assessments;
  }

  get researchAssessments() {
    return this._researchAssessments;
  }

  set selectedResearch(research: Research | null) {
    this._selectedResearch = research;
  }

  get selectedResearch() {
    return this._selectedResearch;
  }

  set researchAssessmentData(researchAssessmentData: ResearchAssessments | null) {
    this._researchAssessmentData = researchAssessmentData;
  }

  get researchAssessmentData() {
    return this._researchAssessmentData;
  }

  set researchAssessmentUser(researchAssessmentUser: Array<ResearchAssessmentUserAnswer> | null) {
    this._researchAssessmentUser = researchAssessmentUser;
  }

  get researchAssessmentUser() {
    return this._researchAssessmentUser;
  }

  get lastAccessedResearch() {
    return this._lastAccessResearch;
  }

  set lastAccessedResearch(research: Research | null) {
    this._lastAccessResearch = research;
  }

  setResearches = (response: Pagination<Array<Research>> | null) => (this._researches = response);

  pushResearches = (researches: Array<Research>) =>
    (this._researches!.paginatedData = [...this._researches!.paginatedData, ...researches]);
}
