import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Fragment } from "react";
import LoginBackground from "../../assets/images/login-bg";
import MetaDxiWhiteLogo from "../../assets/images/meta-dxi-logo-white";
import SocialMediaIcons from "../../components/social-media";

interface LoginPageProps {
  onLoginType: (type: string) => void;
}

const LoginPage: React.FC<LoginPageProps> = (props: LoginPageProps) => {
  return (
    <Fragment>
      <Grid container justifyContent="center" alignItems="center" sx={{ overflowY: "hidden" }}>
        <Grid
          container
          item
          xs={6}
          display={{ xs: "none", sm: "flex" }}
          sx={{ position: "relative", minHeight: "100vh" }}
        >
          <LoginBackground />
          <Container
            sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}
          >
            <Box sx={{ alignSelf: "start", mt: 3 }}>
              <MetaDxiWhiteLogo />
            </Box>

            <Typography variant="h1" color={"white"}>
              Seja bem-vindo à plataforma de Diagnóstico e Avaliação de Maturidade Digital{" "}
            </Typography>

            <Box sx={{ mb: 3 }}>
              <SocialMediaIcons sitePos="right" />
            </Box>
          </Container>
        </Grid>
        <Grid
          container
          direction={"column"}
          item
          xs={12}
          sm={6}
          paddingLeft={6}
          paddingRight={6}
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: "100vh" }}
        >
          <Button
            variant="outlined"
            sx={{ my: 4 }}
            onClick={() => {
              localStorage.setItem("dxi_login_type", "client");
              props.onLoginType("client");
            }}
          >
            Quero acessar como cliente
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              localStorage.setItem("dxi_login_type", "consultant");
              props.onLoginType("consultant");
            }}
          >
            Quero acessar como consultor
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default observer(LoginPage);
