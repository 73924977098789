import { Grid, Chip, Typography, Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../../../stores/store.context";
import { useContext } from "react";

const ResearchReportPillars: React.FC = () => {
  const { researchStore } = useContext(StoreContext);

  const getPillarBorder = (score: number) => {
    if (score === 0) {
      return "#FF4A4A";
    }

    if (score >= 1 && score <= 2) {
      return "#FFA800";
    }

    if (score === 3) {
      return "#C2DF3B";
    }

    if (score >= 4) {
      return "#56C5C9";
    }

    return "";
  };

  return researchStore.researchAssessmentData && researchStore.researchAssessmentData.pillars ? (
    <Grid container spacing={3} sx={{ mt: 6 }}>
      {researchStore.researchAssessmentData.pillars.map((pillarResult, index) => {
        return (
          <Grid item xs={12} sm={6} key={index}>
            <Box
              sx={{
                p: 2,
                borderColor: "grey.ce9",
                borderWidth: "1px",
                borderStyle: "solid",
                borderRadius: "8px",
                position: "relative",
              }}
            >
              <Chip
                label={
                  <Typography variant="body2" fontWeight={500}>
                    Nota {Math.floor(pillarResult.average!)}
                  </Typography>
                }
                sx={{
                  alignSelf: "flex-start",
                  backgroundColor: "grey.ce9",
                }}
              />

              <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
                <Box
                  component="img"
                  src={`${process.env.REACT_APP_ASSESSMENT_SERVICE}/uploads/pillar/${
                    pillarResult.pillar!.pillarImage
                  }`}
                  sx={{ mr: 2, color: "primary.dark" }}
                />
                <Typography variant="h5" sx={{ color: "primary.dark" }}>
                  {pillarResult.pillar!.pillarName}
                </Typography>
              </Box>

              <Typography variant="body1" color="grey.c59" sx={{ pr: 2 }}>
                {pillarResult.grade}
              </Typography>

              <Box
                sx={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  height: "100%",
                  width: "20px",
                  backgroundColor: getPillarBorder(Math.floor(pillarResult.average!)),
                  borderTopRightRadius: "8px",
                  borderBottomRightRadius: "8px",
                }}
              />
            </Box>
          </Grid>
        );
      })}
    </Grid>
  ) : (
    <></>
  );
};

export default observer(ResearchReportPillars);
