import { Theme } from '@mui/material/styles'

export default function Typography(theme: Theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          verticalAlign: '-webkit-baseline-middle',
        },
      },
    },
  }
}
