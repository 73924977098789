import { observer } from "mobx-react-lite";
import { Snackbar, Alert, Button, Box, LinearProgress, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ToastOptions } from "./models/toast.model";
import { StoreContext } from "./stores/store.context";
import { getKeycloakLoginType } from "./keycloak";
import Router from "./routes";
import ThemeCustomization from "./theme";
import CustomModal from "./components/modal";
import LoginPage from "./pages/login";

const App: React.FC = () => {
  const { toastStore, keycloakStore } = useContext(StoreContext);
  const [loginType, setLoginType] = useState<string | null>(
    null || localStorage.getItem("dxi_login_type")
  );
  const [changeLoginType, setChangeLoginType] = useState<boolean>(false);

  useEffect(() => {
    if (loginType) {
      const keycloak = getKeycloakLoginType(loginType);
      var currentURL = window.location.href;
      var url = new URL(currentURL);
      if (url.pathname === "/") {
        setChangeLoginType(true);

        const timer = setTimeout(() => {
          keycloak
            .init({
              onLoad: "login-required",
              checkLoginIframe: false,
              redirectUri: "/secure/home",
            })
            .then((authenticated) => {
              keycloakStore.keycloak = keycloak;
              keycloakStore.authenticated = authenticated;
              setChangeLoginType(false);
            });
        }, 5000);

        return () => clearTimeout(timer);
      } else {
        keycloak
          .init({ onLoad: "login-required", checkLoginIframe: false, redirectUri: url.pathname })
          .then((authenticated) => {
            keycloakStore.keycloak = keycloak;
            keycloakStore.authenticated = authenticated;
            setChangeLoginType(false);
          });
      }
    }
  }, [keycloakStore, loginType]);

  if (changeLoginType) {
    return (
      <ThemeCustomization>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100vh",
          }}
        >
          <Typography variant="body2">Direcionando para autenticação...</Typography>
          <LinearProgress sx={{ my: 2, width: "300px" }} color="primary" />
          <Button
            onClick={() => {
              setLoginType(null);
              setChangeLoginType(false);
            }}
          >
            Trocar forma de acesso
          </Button>
        </Box>
      </ThemeCustomization>
    );
  }

  if (keycloakStore.keycloak) {
    if (keycloakStore.authenticated) {
      return (
        <ThemeCustomization>
          <Router>
            <CustomModal key={1} />
          </Router>

          {toastStore.toast.map((toast: ToastOptions, index: React.Key) => (
            <Snackbar
              anchorOrigin={{
                vertical: toast.position ? toast.position.vertical : "top",
                horizontal: toast.position ? toast.position.horizontal : "right",
              }}
              open={true}
              key={index}
            >
              <Alert severity={toast.type}>{toast.message}</Alert>
            </Snackbar>
          ))}
        </ThemeCustomization>
      );
    } else return <div>Falha ao autenticar</div>;
  }

  return !loginType ? (
    <ThemeCustomization>
      <LoginPage onLoginType={(e) => setLoginType(e)} />
    </ThemeCustomization>
  ) : (
    <></>
  );
};

export default observer(App);
