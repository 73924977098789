export const howItWorksStepsConsultant = [
  {
    title: "Cadastre um cliente",
    description: "Você só vai precisar dos principais dados da empresa para realizar o cadastro.",
  },
  {
    title: "Indique um Cliente Master",
    description:
      "Ele é o seu contato principal dentro da organização e será o usuário responsável por distribuir as avaliações dentro da empresa para outros colaboradores.",
  },
  {
    title: "Crie uma pesquisa",
    description:
      "Selecionar quais avaliações disponíveis você deseja enviar para o cliente responder.",
  },
  {
    title: "Cliente Master convida participantes ",
    description:
      "O Cliente Master será informado que uma pesquisa foi aberta e ele deverá convidar o time interno para responder de acordo com os assuntos das avaliações.",
  },
  {
    title: "Finalize a pesquisa e gere os relatórios",
    description:
      "Você poderá acompanhar em tempo real e definir quando as avaliações serão encerradas e o relatório será gerado.",
  },
  {
    title: "Estude os diagnósticos e faça entregas de valor",
    description:
      "Com os dados em mãos, fica muito mais fácil entender as necessidades do cliente e oferecer a melhor solução para promover a Transformação Digital.",
  },
];
