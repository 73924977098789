import Keycloak from "keycloak-js";

export const getKeycloakLoginType = (type: string) => {
  if (type === "consultant") {
    return new Keycloak({
      url: process.env.REACT_APP_KEYCLOAK_IAM_LOGIN || "",
      realm: process.env.REACT_APP_KEYCLOAK_IAM_REALM || "",
      clientId: process.env.REACT_APP_KEYCLOAK_IAM_CLIENT_ID || "",
    });
  }

  return new Keycloak({
    url: process.env.REACT_APP_KEYCLOAK_CIAM_LOGIN || "",
    realm: process.env.REACT_APP_KEYCLOAK_CIAM_REALM || "",
    clientId: process.env.REACT_APP_KEYCLOAK_CIAM_CLIENT_ID || "",
  });
};
