import { Box, Button, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { StoreContext } from "../../../stores/store.context";
import { useNavigate } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Company } from "../../../models/company.model";

interface CompanyAddedProps {
  companyAdded: Company | null;
}

const CompanyAdded: React.FC<CompanyAddedProps> = (props: CompanyAddedProps) => {
  const { companyStore } = useContext(StoreContext);
  const navigate = useNavigate();

  return (
    props.companyAdded && (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <CheckCircleOutlineIcon sx={{ fontSize: "64px", color: "success.dark", mb: 3 }} />

        <Typography variant="h5">Cadastro realizado com sucesso!</Typography>

        <Box display="inline" sx={{ my: 3, textAlign: "center", maxWidth: "600px" }}>
          <Typography variant="body1">
            O usuário{" "}
            {props.companyAdded && props.companyAdded.keyUser && (
              <Box component="span" fontWeight={700}>
                {props.companyAdded.keyUser.firstName + " " + props.companyAdded.keyUser.lastName}{" "}
              </Box>
            )}
            receberá um email de convite para acessar a plataforma. Ele poderá convidar outros
            usuários para participar da pesquisa aplicada por você.
          </Typography>
        </Box>

        <Box display="inline">
          <Typography variant="body1">
            Vamos iniciar a pesquisa da empresa{" "}
            <Box component="span" fontWeight={700}>
              {props.companyAdded.name} ?
            </Box>
          </Typography>
        </Box>

        {props.companyAdded && (
          <Button
            sx={{ my: 3 }}
            variant="contained"
            onClick={() => {
              if (props.companyAdded) {
                navigate(
                  `/secure/research/add?company=${props.companyAdded._id}&companyName=${props.companyAdded.name}`
                );
              }
            }}
          >
            Sim, criar pesquisa
          </Button>
        )}

        <Button
          variant="text"
          sx={{ textDecoration: "underline", textUnderlineOffset: "8px" }}
          onClick={() => (companyStore.companyFormStep = 0)}
        >
          Não, cadastrar outra empresa
        </Button>
      </Box>
    )
  );
};

export default observer(CompanyAdded);
