import { observer } from "mobx-react-lite";
import { Fragment, useContext, useEffect } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { StoreContext } from "../../../stores/store.context";
import {
  FormControl,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
  Button,
} from "@mui/material";
import CircularLoader from "../../../components/circular-loader";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import NoData from "../../../components/no-data";
import { action } from "mobx";

interface ResearchComboProps {
  loadByUser?: string;
}

const ResearchCombo: React.FC<ResearchComboProps> = (props: ResearchComboProps) => {
  const { promiseInProgress } = usePromiseTracker({ area: "loading-data" });
  const { companyStore, gridStore, userStore, researchStore } = useContext(StoreContext);

  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    action(() => {
      researchStore.selectedResearch = null;
    })();

    const getResearchesByCompany = async () => {
      var filter = JSON.stringify({
        name: gridStore.searchText ? gridStore.searchText : "",
        status: "",
      });

      await trackPromise(
        researchStore.listResearchesByCompany(
          companyStore.company._id!,
          gridStore.currentPage,
          0,
          gridStore.orderBy.field !== "" ? gridStore.orderBy.field : "name",
          gridStore.orderBy.direction,
          filter
        ),
        "loading-data"
      );
    };

    const getUserResearchAssessmentData = async () =>
      await trackPromise(
        researchStore.getResearchAssessmentsByUser(
          researchStore.selectedResearch!._id!,
          props.loadByUser!
        ),
        "loading-data"
      );

    if (props.loadByUser && props.loadByUser !== "" && researchStore.selectedResearch) {
      getUserResearchAssessmentData();
    }

    if (!companyStore.company._id) {
      const getCompanyByUser = async () => {
        await trackPromise(companyStore.getCompanyByUser(id ?? userStore.user!.id), "loading-data");
        getResearchesByCompany();
      };

      if (userStore.user) {
        getCompanyByUser();
      }
    } else {
      getResearchesByCompany();
    }
  }, [companyStore, gridStore, props.loadByUser, researchStore, userStore.user]);

  return (
    <Fragment>
      {!promiseInProgress ? (
        researchStore.researches && researchStore.researches.totalItems > 0 ? (
          <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <Typography variant="body2" color="primary.main">
              Escolha entre as pesquisas disponíveis:
            </Typography>
            <FormControl fullWidth>
              <Select
                id="research-combo"
                value={researchStore.selectedResearch ? researchStore.selectedResearch._id : ""}
                onChange={async (e: SelectChangeEvent) => {
                  const selectedResearchId = e.target.value;
                  const selectedResearch = researchStore.researches!.paginatedData.find(
                    (research) => research._id === selectedResearchId
                  );
                  researchStore.selectedResearch = selectedResearch || null;

                  if (props.loadByUser && props.loadByUser !== "") {
                    await trackPromise(
                      researchStore.getResearchAssessmentsByUser(
                        selectedResearchId,
                        props.loadByUser === "logged" ? userStore.user!.id : props.loadByUser
                      ),
                      "loading-research-assessments"
                    );
                  } else {
                    await trackPromise(
                      researchStore.getResearchById(selectedResearchId),
                      "loading-research-assessments"
                    );
                  }
                }}
              >
                {researchStore.researches &&
                  researchStore.researches.paginatedData.map((research, index) => {
                    return (
                      <MenuItem value={research._id} key={index}>
                        {research.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
        ) : (
          <Box sx={{ width: "100%" }}>
            <NoData
              text={"Essa empresa ainda não possui pesquisas abertas. Comece agora!"}
              button={
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate(
                      `/secure/research/add?company=${companyStore.company!._id}&companyName=${
                        companyStore.company!.name
                      }`
                    )
                  }
                >
                  <AddIcon sx={{ mr: 2 }} /> Nova pesquisa
                </Button>
              }
            />
          </Box>
        )
      ) : (
        <CircularLoader />
      )}
    </Fragment>
  );
};

export default observer(ResearchCombo);
