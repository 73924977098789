import "../request.interceptor";
import { observer } from "mobx-react-lite";
import { LocationProvider } from "./location-provider";
import { RoutesWithAnimation } from "./routes-animation";

const AppRoutes = ({ children }: any) => {
  return (
    <LocationProvider>
      <RoutesWithAnimation>{children}</RoutesWithAnimation>
    </LocationProvider>
  );
};

export default observer(AppRoutes);
