import { observer } from "mobx-react-lite";
import { Fragment, useContext } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../../../stores/store.context";
import AssessmentsList from "../../assessment/components/assessment-list";
import Banner from "../../../assets/images/banner-home.png";
import GirlARGlasses from "../../../assets/images/girl-augmented-reality-glasses.png";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ResearchList from "../../research/components/research-list";

const HomeConsultant: React.FC = () => {
  const { userStore, themeStore } = useContext(StoreContext);

  const navigate = useNavigate();

  return (
    <Fragment>
      <Box
        sx={{
          backgroundImage: `url("${Banner}")`,
          minHeight: { xs: "295px", xl: "420px" },
          width: "100vw",
          marginLeft: "calc(-50vw + 50%)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          position: "relative",
        }}
      >
        <Box display="inline" sx={{ my: 3, textAlign: "center" }}>
          <Container
            sx={{
              maxWidth: {
                xs: themeStore.theme.breakpoints.values.lg,
                xl: themeStore.theme.breakpoints.values.xl,
              },
            }}
          >
            <Typography
              variant="h1"
              color="white"
              sx={{
                maxWidth: "492px",
                textAlign: "left",
                position: "absolute",
                top: "50%",
                marginTop: "-115px",
              }}
            >
              Vamos{" "}
              <Box component="span" fontWeight={800} color="secondary.light">
                juntos{" "}
              </Box>
              realizar a{" "}
              <Box component="span" fontWeight={800} color="secondary.light">
                transformação{" "}
              </Box>
              digital no Brasil?
            </Typography>
          </Container>
        </Box>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h1" color="primary.dark">
          Olá, {userStore.userFullName}
        </Typography>
        <Typography variant="h3" color="grey.c59" sx={{ mt: 2 }}>
          Crie e acompanhe suas pesquisas em andamento e gerencie seus clientes.
        </Typography>
      </Box>

      <Fragment>
        <Box sx={{ mt: 8, mb: 4, display: "flex", alignItems: "center" }}>
          <Typography variant="h2" color="primary.dark">
            Minhas pesquisas
          </Typography>

          <Button
            startIcon={<RemoveRedEyeOutlinedIcon fontSize="small" />}
            variant="text"
            sx={{ ml: 4, textDecoration: "underline", textUnderlineOffset: "8px" }}
            onClick={() => navigate("/secure/research")}
          >
            Ver tudo
          </Button>
        </Box>

        <ResearchList gridColorScheme="grey" />
      </Fragment>

      <Fragment>
        <Box sx={{ mt: 8, mb: 4, display: "flex", alignItems: "center" }}>
          <Typography variant="h2" color="primary.dark">
            Avaliações
          </Typography>

          <Button
            startIcon={<RemoveRedEyeOutlinedIcon fontSize="small" />}
            variant="text"
            sx={{ ml: 4, textDecoration: "underline", textUnderlineOffset: "8px" }}
            onClick={() => navigate("/secure/assessment")}
          >
            Ver tudo
          </Button>
        </Box>

        <AssessmentsList
          cardCanBeSelected={false}
          hasHeader={false}
          direction="horizontal"
          hasProgress={false}
        />
      </Fragment>

      <Fragment>
        <Box sx={{ mt: 8, alignItems: "center" }}>
          <Typography variant="h2" color="primary.dark">
            Como utilizar
          </Typography>

          <Box
            sx={{
              borderRadius: "8px",
              backgroundColor: "background.default",
              display: "flex",
              mt: 4,
              px: 8,
              py: 6,
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", mr: 6, mt: 4, maxWidth: "490px" }}>
              <Typography variant="h4" color="primary.dark" sx={{ maxWidth: "375px" }}>
                Vamos juntos realizar a transformação digital no Brasil?
              </Typography>

              <Typography variant="body1" color="grey.c59" sx={{ my: 2 }}>
                Fechar negócios e gerenciar as expectativas cada vez mais elevadas dos clientes é um
                trabalho fundamental e que nosso time realiza com maestria.
              </Typography>

              <Typography variant="body1" color="grey.c59">
                Com a plataforma DXi esse trabalho ficará ainda melhor, pois você poderá tomar
                decisões de forma mais analítica e estratégica a partir da maturidade digital do
                cliente.
              </Typography>

              <Button
                onClick={() => navigate("/secure/about")}
                variant="contained"
                sx={{ mt: 4, alignSelf: "self-start" }}
              >
                Entenda mais sobre o DXi
              </Button>
            </Box>

            <Box component="img" src={GirlARGlasses} sx={{ alignSelf: "center" }} />
          </Box>
        </Box>
      </Fragment>
    </Fragment>
  );
};

export default observer(HomeConsultant);
