import { makeAutoObservable } from "mobx";
import Keycloak from "keycloak-js";

export class KeycloakStore {
  private _keycloak: Keycloak | null = null;
  private _autenticated: boolean = false;
  private _token: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  set keycloak(keycloakInstance: Keycloak | null) {
    this._keycloak = keycloakInstance;
  }

  get keycloak() {
    return this._keycloak;
  }

  set authenticated(isAuthenciated: boolean) {
    this._autenticated = isAuthenciated;
  }

  get authenticated() {
    return this._autenticated;
  }

  set token(token: string | null) {
    this._token = token;
  }

  get token() {
    return this._token;
  }
}
