import { deepOrange, grey } from "@mui/material/colors";

const Palette = (mode: string) => {
  return {
    ...(mode === "light"
      ? {
          primary: {
            main: "#002FB9",
            contrastText: "#fff",
            dark: "#00164F",
            light: "#0050D6",
          },
          secondary: {
            main: "#541A7C",
            contrastText: "#000000",
            lighter: "#595959",
            dark: "#9747ff52",
            light: "#0075E1",
          },
          info: {
            main: "#56C5C9",
          },
          warning: {
            main: "#FFA800",
          },
          error: {
            main: "#FF4A4A",
          },
          grey: { ...grey, cf2: "#F1F2F6", c59: "#595959", ca3: "#A3A3A3", ce9: "#E3E4E9" },
          success: {
            main: "#C2DF3B",
            dark: "#28AA5C",
          },
          background: {
            default: "#ffffff",
            paper: "#F1F2F6",
          },
        }
      : {
          primary: {
            main: deepOrange[700],
            contrastText: "#fff",
            dark: deepOrange[700],
            light: "#0050D6",
          },
          secondary: {
            main: "#541A7C",
            contrastText: "#000",
            lighter: "#fff",
            dark: "#9747ff52",
          },
          grey: grey,
          success: {
            main: "#C2DF3B",
            dark: "#28AA5C",
          },
          background: {
            default: "#474747",
            paper: "#F1F2F6",
          },
        }),
  };
};

export default Palette;
