import { makeAutoObservable } from "mobx";
import { Company } from "../models/company.model";
import { CompanyService } from "../services/company.service";
import { Pagination } from "../models/pagination.model";
import { ToastStore } from "./toast.store";
import { UserData } from "../models/user.model";

export class CompanyStore {
  private _companyFormStep: number = 0;
  private _selectedCompany: Company | null = null;
  private _companies: Pagination<Array<Company>> | null = null;
  private _company: Company = new Company();
  private _companyUsers: Pagination<Array<UserData>> | null = null;

  toastStore?: ToastStore;

  constructor(toastStore?: ToastStore) {
    makeAutoObservable(this);
    if (toastStore) {
      this.toastStore = toastStore;
    }
  }

  async add(companyData: Company) {
    try {
      const companyService = new CompanyService();
      var formData = new FormData();
      if (companyData.logo) {
        formData.append("logo", companyData.logo);
      }

      formData.append("data", JSON.stringify(companyData));
      const addedCompany = await companyService.addCompany(formData);
      this.company = addedCompany;
      return addedCompany;
    } catch (e: any) {
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e.toString(), type: "error" });
      }
    }
  }

  async getCompanyByUser(id: string): Promise<void> {
    try {
      const companyService = new CompanyService();
      const res: Company = await companyService.getCompanyByUser(id);

      if (res) {
        this.company = res;
      }
    } catch (e: any) {
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e.toString(), type: "error" });
      }
    }
  }

  async getCompanyById(id: string): Promise<void> {
    try {
      const companyService = new CompanyService();
      const res: Company = await companyService.getCompanyById(id);

      if (res) {
        this.company = res;
      }
    } catch (e: any) {
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e.toString(), type: "error" });
      }
    }
  }

  async listCompanies(
    page: number,
    limit: number,
    orderBy?: string,
    orderbyDirection?: string,
    filter?: string
  ): Promise<void> {
    try {
      const companyService = new CompanyService();
      const res: Pagination<Array<Company>> = await companyService.listCompanies(
        page,
        limit,
        orderBy,
        orderbyDirection,
        filter
      );
      if (res && page === 1) {
        this.companies = res;
      } else {
        this.pushCompanies(res.paginatedData);
      }
    } catch (e: any) {
      this.companies = null;
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e, type: "error" });
      }
    }
  }

  async listConsultantCompanies(
    consultantId: string,
    page: number,
    limit: number,
    orderBy?: string,
    orderbyDirection?: string,
    filter?: string
  ): Promise<void> {
    try {
      const companyService = new CompanyService();
      const res: Pagination<Array<Company>> = await companyService.listConsultantCompanies(
        consultantId,
        page,
        limit,
        orderBy,
        orderbyDirection,
        filter
      );
      if (res && page === 1) {
        this.companies = res;
      } else {
        this.pushCompanies(res.paginatedData);
      }
    } catch (e: any) {
      this.companies = null;
      throw e;
    }
  }

  async listCompanyUsers(
    companyId: string,
    page: number,
    limit: number,
    orderBy?: string,
    orderbyDirection?: string,
    filter?: string
  ): Promise<void> {
    try {
      const companyService = new CompanyService();
      const res: Pagination<Array<UserData>> = await companyService.listCompanyUsers(
        companyId,
        page,
        limit,
        orderBy,
        orderbyDirection,
        filter
      );
      if (res && page === 1) {
        this.companyUsers = res;
      } else {
        this.pushCompanyUsers(res.paginatedData);
      }
    } catch (e: any) {
      this.companyUsers = null;
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e, type: "error" });
      }
    }
  }

  async editCompany(id: string, formData: Company): Promise<boolean> {
    try {
      delete formData.logo;
      const companyService = new CompanyService();
      await companyService.editCompany(id, formData);

      return true;
    } catch (e: any) {
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e, type: "error" });
      }
      throw e;
    }
  }

  async changeCompanyLogo(logo: File, id: string): Promise<boolean> {
    try {
      const companyService = new CompanyService();
      await companyService.changeCompanyLogo(logo, id);
      return true;
    } catch (e: any) {
      throw e;
    }
  }

  addDomain(domain: string) {
    if (!/^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,7}$/.test(domain)) {
      return false;
    }
    var domainExists = false;
    const checkDomain = (obj: any) => obj === domain;
    if (this.company) {
      domainExists = this.company.allowedDomains.some(checkDomain);
      if (!domainExists) {
        this.company = {
          ...this.company,
          allowedDomains: [...this.company.allowedDomains, domain],
        };
      }
    }
  }

  removeDomain = (domain: string) => {
    this.company = {
      ...this.company,
      allowedDomains: this.company!.allowedDomains.filter((e) => e !== domain),
    };

    if (this.company!.allowedDomains.length === 0) {
      this.company = { ...this.company, allowedDomains: [] };
    }
  };

  pushCompanies = (companies: Array<Company>) =>
    (this._companies!.paginatedData = [...this._companies!.paginatedData, ...companies]);

  pushCompanyUsers = (companyUsers: Array<UserData>) =>
    (this.companyUsers!.paginatedData = [...this.companyUsers!.paginatedData, ...companyUsers]);

  get companyUsers() {
    return this._companyUsers;
  }

  set companyUsers(companyData: Pagination<Array<UserData>> | null) {
    this._companyUsers = companyData;
  }

  set companyFormStep(step: number) {
    this._companyFormStep = step;
  }

  get companyFormStep() {
    return this._companyFormStep;
  }

  set companies(data: Pagination<Array<Company>> | null) {
    this._companies = data;
  }

  get companies() {
    return this._companies;
  }

  set company(companyData: Company) {
    this._company = companyData;
  }

  get company() {
    return this._company;
  }

  set selectedCompany(company: Company | null) {
    this._selectedCompany = company;
  }

  get selectedCompany() {
    return this._selectedCompany;
  }
}
