import { Page, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import { ResearchAssessments } from "../../../models/research.model";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
  },
});

interface ResearchPDFProps {
  researchData: ResearchAssessments;
  chart: any;
}

export const ResearchPDF: React.FC<ResearchPDFProps> = (props: ResearchPDFProps) => {
  return (
    <Document>
      {props.researchData && (
        <Page
          size="A4"
          wrap={false}
          style={{
            ...styles.page,
            borderRadius: "8px",
            backgroundColor: "white",
            padding: "15px",
          }}
        >
          <View style={{ width: "100%" }}>{props.chart && <Image src={props.chart} />}</View>
        </Page>
      )}
    </Document>
  );
};
