import {
  Box,
  Grid,
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../../stores/store.context";
import { Formik } from "formik";
import { CompanySectorsEnum } from "../../../enums/company_sectors.enum";
import { CompanySizesEnum } from "../../../enums/company_sizes.enum";
import { companySchema } from "../schemas/company-schema";
import { getInitialValues } from "../constants/company-form-initial-values";
import CompanyImage from "./company-image";
import MaskedInput from "../../../components/masked-input";
import CompanyFormDomains from "./company-form-domains";
import { trackPromise } from "react-promise-tracker";
import { useParams } from "react-router-dom";
import ButtonLoader from "../../../components/button-loader";

interface CompanyFormProps {
  edit?: boolean;
}

const CompanyForm: React.FC<CompanyFormProps> = (props: CompanyFormProps) => {
  const { companyStore, userStore, toastStore } = useContext(StoreContext);
  const [stepsValid, setstepsValid] = useState<boolean>(false);

  const companySizes = Object.entries(CompanySizesEnum);
  const companySectors = Object.entries(CompanySectorsEnum);

  let { id } = useParams();

  const handleUpdate = async () => {
    if (companyStore.company && id) {
      const companyUpdated = await trackPromise(
        companyStore.editCompany(id, companyStore.company),
        "update-company"
      );

      if (companyUpdated) {
        toastStore.pushToast({ message: "Alterações salvas com sucesso", type: "success" });
      }
    }
  };

  useEffect(() => {
    setstepsValid(companyStore.company.allowedDomains.length <= 0 || !companyStore.company.logo);
  }, [companyStore.company.allowedDomains, companyStore.company.logo]);

  return (
    <Box sx={{ px: 4 }}>
      <Grid container sx={{ mt: 4 }}>
        <Grid item xs={12} sm={5}>
          <CompanyImage />
        </Grid>

        <Grid item xs={12} sm={7} sx={{ mt: 0 }}>
          <Formik
            enableReinitialize={true}
            validateOnMount={props.edit ? false : true}
            initialValues={getInitialValues(companyStore, userStore, props.edit)}
            validationSchema={companySchema}
            onSubmit={async () => {}}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isValid, touched, values }) => {
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.name && errors.name)}
                        id="name"
                        type="text"
                        value={values.name}
                        name="name"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          companyStore.company = {
                            ...companyStore.company,
                            name: e.target.value,
                          };
                        }}
                        placeholder="Nome da empresa"
                        variant="outlined"
                        label="Empresa"
                        inputProps={{
                          autoComplete: "company-name",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      />
                      {touched.name && errors.name && (
                        <FormHelperText error>{errors.name}</FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={7}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.cnpj && errors.cnpj)}
                        id="cnpj"
                        type="text"
                        value={values.cnpj}
                        name="cnpj"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);

                          companyStore.company = {
                            ...companyStore.company,
                            cnpj: e.target.value,
                          };
                        }}
                        placeholder="Digite o CNPJ"
                        variant="outlined"
                        label="CNPJ"
                        inputProps={{
                          mask: "00.000.000/0000-00",
                          autoComplete: "company-cnpj",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                        InputProps={{
                          inputComponent: MaskedInput as any,
                        }}
                      />

                      {touched.cnpj && errors.cnpj && (
                        <FormHelperText error>{errors.cnpj}</FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={5}>
                      <FormControl
                        fullWidth
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            borderColor: "grey.200",
                          },
                        }}
                      >
                        <InputLabel id="size">Tamanho da empresa</InputLabel>
                        <Select
                          id="size"
                          name="size"
                          labelId="size"
                          value={values.size}
                          label="Tamanho da empresa"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);

                            companyStore.company = {
                              ...companyStore.company,
                              size: e.target.value,
                            };
                          }}
                        >
                          {companySizes.map(([key, value], index) => {
                            return (
                              <MenuItem value={value} key={index}>
                                {value}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {touched.size && errors.size && (
                          <FormHelperText error>{errors.size}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl
                        fullWidth
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            borderColor: "grey.200",
                          },
                        }}
                      >
                        <InputLabel id="sector">Setor/ramo de atividade</InputLabel>
                        <Select
                          id="sector"
                          name="sector"
                          labelId="sector"
                          value={values.sector}
                          label="Setor/ramo de atividade"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            companyStore.company = {
                              ...companyStore.company,
                              sector: e.target.value,
                            };
                          }}
                        >
                          {companySectors.map(([key, value], index) => {
                            return (
                              <MenuItem value={value} key={index}>
                                {value}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {touched.sector && errors.sector && (
                          <FormHelperText error>{errors.sector}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    {companyStore.company.sector === "Outros" && (
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          id="otherSector"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            companyStore.company = {
                              ...companyStore.company,
                              otherSector: e.target.value,
                            };
                          }}
                          type="text"
                          value={values.otherSector}
                          name="otherSector"
                          placeholder="Nome do setor/ramo"
                          variant="outlined"
                          label="Nome do setor/ramo"
                          inputProps={{
                            autoComplete: "company-otherSector",
                            form: {
                              autocomplete: "off",
                            },
                          }}
                        />
                      </Grid>
                    )}

                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="keyUser"
                        type="text"
                        value={userStore.userFullName}
                        name="keyUser"
                        disabled
                        placeholder="Consultor Meta responsável"
                        variant="outlined"
                        label="Consultor Meta responsável"
                        inputProps={{
                          autoComplete: "company-keyUser",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.website && errors.website)}
                        id="website"
                        type="text"
                        value={values.website}
                        name="website"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          companyStore.company = {
                            ...companyStore.company,
                            website: e.target.value,
                          };
                        }}
                        placeholder="Digite o site"
                        variant="outlined"
                        label="Website"
                        inputProps={{
                          autoComplete: "company-website",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      />

                      {touched.website && errors.website && (
                        <FormHelperText error>{errors.website}</FormHelperText>
                      )}
                    </Grid>

                    <CompanyFormDomains
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                    />

                    <Grid item xs={12} sx={{ textAlign: "right" }}>
                      {!props.edit ? (
                        <Button
                          onClick={() => companyStore.companyFormStep++}
                          variant="contained"
                          disabled={!isValid || stepsValid}
                        >
                          Avançar
                        </Button>
                      ) : (
                        <ButtonLoader
                          type="button"
                          loadingText="Processando..."
                          text="Atualizar"
                          area="update-company"
                          onClick={handleUpdate}
                          disabled={
                            !isValid ||
                            companyStore.company.allowedDomains.length <= 0 ||
                            !companyStore.company.logo
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Box>
  );
};

export default observer(CompanyForm);
