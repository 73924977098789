import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { StoreContext } from "./stores/store.context";
import axios from "axios";

const AxiosInterceptor = ({ children }: any) => {
  const { keycloakStore } = useContext(StoreContext);

  axios.interceptors.request.use(
    async (config) => {
      if (config && config.headers) {
        config.headers.Authorization = `Bearer ${keycloakStore.token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return children;
};

export default observer(AxiosInterceptor);
