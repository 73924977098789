import { makeAutoObservable } from "mobx";
import { SearchStatus } from "../models/search";

interface OrderBy {
  direction: "asc" | "desc";
  field: string;
}

export class GridStore {
  private _searchText: string = "";
  private _searchStatus: SearchStatus = "all";
  private _currentPage: number = 1;
  private _limit: number = 6;
  private _orderBy: OrderBy = { direction: "asc", field: "" };

  constructor() {
    makeAutoObservable(this);
  }

  set searchText(q: string) {
    this._searchText = q;
  }

  get searchText() {
    return this._searchText;
  }

  set currentPage(page: number) {
    this._currentPage = page;
  }

  get currentPage() {
    return this._currentPage;
  }

  set limit(newLimit: number) {
    this._limit = newLimit;
  }

  get limit() {
    return this._limit;
  }

  set orderBy(orderByData: OrderBy) {
    this._orderBy = orderByData;
  }

  get orderBy() {
    return this._orderBy;
  }

  set searchStatus(status: SearchStatus) {
    this._searchStatus = status;
  }

  get searchStatus() {
    return this._searchStatus;
  }
}
