import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";

export const pillars = [
  {
    icon: <GridViewOutlinedIcon fontSize="small" sx={{ color: "secondary.main" }} />,
    title: "Estratégia",
  },
  {
    icon: <ChatOutlinedIcon fontSize="small" sx={{ color: "secondary.main" }} />,
    title: "Cultura",
  },
  {
    icon: <TextSnippetOutlinedIcon fontSize="small" sx={{ color: "secondary.main" }} />,
    title: "Processos",
  },
  {
    icon: <BoltOutlinedIcon fontSize="small" sx={{ color: "secondary.main" }} />,
    title: "Tecnologia e Ferramentas",
  },
];
