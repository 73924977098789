import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../../../stores/store.context";
import { useContext, useEffect } from "react";
import StatusCircle from "../../../components/status-circle";
import { useSearchParams } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";

const CompanyDataCard: React.FC = () => {
  const { companyStore, generalStore } = useContext(StoreContext);

  const [searchParams] = useSearchParams();
  const companyId = searchParams.get("company");

  var getCompanyData = async () =>
    await trackPromise(companyStore.getCompanyById(companyId as string), "loading-data");

  useEffect(() => {
    if (companyId && !companyStore.company._id) {
      getCompanyData();
    }
  }, []);

  return (
    companyStore.company && (
      <Box
        sx={{
          width: "100%",
          borderWidth: 1,
          borderColor: "grey.ca3",
          borderRadius: "8px",
          borderStyle: "solid",
          p: 1,
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
          minHeight: "170px",
        }}
      >
        {companyStore.company && (
          <Box sx={{ width: "160px", maxWidth: "160px", maxHeight: "160px" }}>
            <Box
              sx={{ objectFit: "cover", width: "120px", margin: "auto" }}
              component="img"
              src={
                process.env.REACT_APP_COMPANY_SERVICE +
                "/uploads/company/" +
                companyStore.company.logo
              }
            />
          </Box>
        )}

        <Box sx={{ display: "flex", flexDirection: "column", ml: 6 }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1" color="secondary.lighter">
              Empresa:
            </Typography>
            <Typography variant="h2" color="primary.dark" sx={{ mt: 1 }}>
              {companyStore.company.name}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Typography variant="body1" color="secondary.lighter">
              Status:
            </Typography>

            <Box sx={{ ml: 1, mr: 2, display: "flex", alignItems: "center" }}>
              <StatusCircle status={companyStore.company.status!} />
              <Typography variant="body2" color="text.primary">
                {generalStore.getStatusText(companyStore.company.status!)}
              </Typography>
            </Box>
            {/* <Button
              onClick={() => modalStore.openModal(<ChangeCompanyStatusModal />)}
              variant="text"
              color="primary"
              sx={{ display: "flex", alignItems: "center" }}
            >
              {companyStore.company.status === "inactive" ? (
                <AutorenewOutlinedIcon sx={{ color: "primary.main", mr: 1, fontSize: 16 }} />
              ) : (
                <CancelOutlinedIcon sx={{ color: "primary.main", mr: 1, fontSize: 16 }} />
              )}
              {companyStore.company.status === "inactive" ? "Ativar" : "Inativar"} empresa
            </Button> */}
          </Box>
        </Box>
      </Box>
    )
  );
};

export default observer(CompanyDataCard);
