import { Theme } from "@mui/material/styles";

export default function Stepper(theme: Theme) {
  return {
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.Mui-active": {
            color: theme.palette.success.main,
            ".MuiStepIcon-text": {
              fill: theme.palette.primary.dark,
            },
          },
        },
      },
    },
  };
}
