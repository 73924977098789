import { observer } from "mobx-react-lite";
import { Fragment, useContext } from "react";
import { StoreContext } from "../../stores/store.context";
import { DXIRoles } from "../../enums/dxi_roles.enum";
import HomeConsultant from "./components/home-consultant";
import HomeUser from "./components/home-user";

const HomePage: React.FC = () => {
  const { userStore } = useContext(StoreContext);

  return (
    <Fragment>
      {userStore.userRole === DXIRoles.DXI_CONSULTANT && <HomeConsultant />}
      {userStore.userRole !== DXIRoles.DXI_CONSULTANT && <HomeUser />}
    </Fragment>
  );
};

export default observer(HomePage);
