import { Box, Typography } from "@mui/material";
import NoDataIcon from "../assets/images/no-data-icon";
import { ReactElement } from "react";

interface NoDataProps {
  text: string;
  button?: ReactElement;
}

const NoData: React.FC<NoDataProps> = (props: NoDataProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        mb: 4,
      }}
    >
      <NoDataIcon />
      <Typography
        variant="body1"
        color="secondary.lighter"
        sx={{ maxWidth: "450px", textAlign: "center" }}
      >
        {props.text}
      </Typography>
      {props.button && <Box sx={{ mt: 3 }}>{props.button}</Box>}
    </Box>
  );
};

export default NoData;
