import { observer } from "mobx-react-lite";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { Fragment } from "react";
import { pillars } from "./constants/pillars";
import { useNavigate } from "react-router-dom";
import Banner from "../../assets/images/banner-about.png";
import GirlARGlasses from "../../assets/images/girl-augmented-reality-glasses.png";
import HowItWorks from "./components/how-it-works";
import AssessmentsList from "../assessment/components/assessment-list";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { stages } from "./constants/stages";

const AboutPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Box
        sx={{
          backgroundImage: `url("${Banner}")`,
          minHeight: { xs: "420px", xl: "530px" },
          width: "100vw",
          marginLeft: "calc(-50vw + 50%)",
          backgroundSize: "cover",
        }}
      />

      <Box
        sx={{
          borderRadius: "8px",
          backgroundColor: "background.default",
          display: "flex",
          mt: 8,
          px: 6,
          py: 6,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", mr: 6, mt: 4 }}>
          <Typography variant="h2" color="primary.dark" sx={{ maxWidth: "375px" }}>
            Sobre o Meta DXi
          </Typography>

          <Typography variant="body1" color="grey.c59" sx={{ my: 2 }}>
            O Meta DXi é uma plataforma exclusiva, desenvolvida pela Meta para que você possa
            avaliar o estágio de Maturidade Digital das empresas a partir de quatro pilares.
          </Typography>

          <Typography variant="body1" color="grey.c59">
            Com um diagnóstico detalhado gerado automaticamente após a participação ativa do
            cliente, você terá em mãos dados fundamentais para pensar jornadas end-to-end ainda mais
            inteligentes e otimizadas, focada em impulsionar resultados e promover a Transformação
            Digital dentro de empresas de diferentes segmentos.
          </Typography>

          <Typography variant="body1" fontWeight={700} color="grey.c59" sx={{ my: 2 }}>
            Esse é mais um passo rumo ao nosso Sonho Grande e você faz parte dessa história!
          </Typography>
        </Box>

        <Box component="img" src={GirlARGlasses} />
      </Box>

      <Fragment>
        <Box sx={{ mt: 12 }}>
          <Typography variant="h2" color="primary.dark">
            Como funciona
          </Typography>

          <Typography variant="body1" color="grey.c59" sx={{ mt: 2 }}>
            Confira o passo a passo dessa jornada de Transformação Digital: Do cadastro do cliente
            ao Diagnóstico!
          </Typography>
        </Box>

        <Box sx={{ mt: 4 }}>
          <HowItWorks />
        </Box>
      </Fragment>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ mt: 12 }}>
          <Typography variant="h2" color="primary.dark">
            Avaliações
          </Typography>

          <Typography variant="body1" color="grey.c59" sx={{ mt: 2 }}>
            São 06 assuntos diferentes que sempre serão avaliados a partir de quatro pilares:
          </Typography>
        </Box>

        <Box sx={{ mt: 4, display: "flex" }}>
          {pillars.map((pillar, index) => {
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "background.default",
                  justifyContent: "center",
                  p: 2,
                  ml: index > 0 ? 4 : 0,
                  width: "270px",
                }}
              >
                {pillar.icon}
                <Typography
                  variant="caption"
                  color="secondary.contrastText"
                  fontWeight={500}
                  sx={{ ml: 2 }}
                >
                  {pillar.title}
                </Typography>
              </Box>
            );
          })}
        </Box>

        <Typography variant="body1" color="grey.c59" sx={{ my: 4 }}>
          Entenda o que cada um deles vai avaliar e elabore uma pesquisa personalizada para o seu
          cliente.
        </Typography>

        <AssessmentsList
          cardCanBeSelected={false}
          hasHeader={false}
          direction="horizontal"
          hasProgress={false}
        />

        <Button
          variant="text"
          sx={{ mt: 4, textDecoration: "underline", textUnderlineOffset: "8px", mx: "auto" }}
          onClick={() => navigate("/secure/assessment")}
        >
          Ver tudo sobre Avaliações
        </Button>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Box sx={{ mt: 12, display: "flex", flexDirection: "column" }}>
          <Typography variant="h2" color="primary.dark">
            Estágios de maturidade
          </Typography>

          <Typography variant="body1" color="grey.c59" sx={{ my: 2 }}>
            No diagnóstico seu cliente poderá ser classificado em cinco níveis diferentes.{" "}
          </Typography>

          {stages.map((stage, index) => {
            return (
              <Accordion key={index} sx={{ backgroundColor: "transparent" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ flexDirection: "row-reverse", px: 4, py: 0, backgroundColor: stage.bg }}
                >
                  <Box sx={{ display: "flex" }}>
                    {stage.icon("white")}
                    <Typography variant="h4" color="white" sx={{ ml: 2 }}>
                      {stage.title}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: "white", p: 4 }}>
                  <Typography variant="body1" color="#595959">
                    {stage.description}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}

          <Typography variant="h4" color="primary.dark" sx={{ mt: 10, mb: 4, alignSelf: "center" }}>
            Comece agora a Transformação Digital dos seus clientes
          </Typography>

          <Button
            variant="contained"
            onClick={() => navigate("/secure/company/add")}
            sx={{ alignSelf: "center" }}
          >
            Cadastrar empresa
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
};

export default observer(AboutPage);
