import { Grid, TextField, IconButton, Typography, FormHelperText, Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Fragment, useContext, useRef } from "react";
import { FormikErrors, FormikTouched } from "formik";
import { StoreContext } from "../../../stores/store.context";
import { Company } from "../../../models/company.model";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

interface CompanyFormDomainsProps {
  touched: FormikTouched<Company>;
  errors: FormikErrors<Company>;
  values: Company;
  handleBlur: any;
  handleChange: any;
}

const CompanyFormDomains: React.FC<CompanyFormDomainsProps> = (props: CompanyFormDomainsProps) => {
  const { companyStore } = useContext(StoreContext);

  const handleReset = () => {
    document.getElementById("addAllowedDomain")!.focus();
    props.values.addAllowedDomain = "";
    document.getElementById("addAllowedDomain")!.blur();
  };

  return (
    <Fragment>
      <Grid item xs={12}>
        <TextField
          fullWidth
          error={Boolean(props.touched.addAllowedDomain && props.errors.addAllowedDomain)}
          id="addAllowedDomain"
          type="text"
          value={props.values.addAllowedDomain}
          name="addAllowedDomain"
          onBlur={props.handleBlur}
          onChange={props.handleChange}
          onKeyDown={(e) => {
            if (props.values.addAllowedDomain) {
              if (e.key === "Enter") {
                companyStore.addDomain(props.values.addAllowedDomain);
                handleReset();
              }
            }
          }}
          placeholder="exemplo.com.br"
          variant="outlined"
          label="Domínios permitidos para convite"
          inputProps={{
            autoComplete: "company-addAllowedDomain",
            form: {
              autocomplete: "off",
            },
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() => {
                  props.values.addAllowedDomain &&
                    companyStore.addDomain(props.values.addAllowedDomain);
                  handleReset();
                }}
              >
                <AddOutlinedIcon sx={{ color: "secondary.lighter" }} />
              </IconButton>
            ),
          }}
        />
        <Typography variant="caption" color="secondary.lighter">
          * Clique no "+" ou pressione enter para adicionar
        </Typography>
        {props.touched.addAllowedDomain && props.errors.addAllowedDomain && (
          <FormHelperText error>{props.errors.addAllowedDomain}</FormHelperText>
        )}
      </Grid>

      <Grid item xs={12}>
        {companyStore.company &&
          companyStore.company.allowedDomains.map((domain, index) => {
            return (
              <Box
                sx={{
                  backgroundColor: "grey.cf2",
                  py: 1,
                  px: 2,
                  borderRadius: 3,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 1,
                }}
                key={index}
              >
                <Typography variant="body2">{domain}</Typography>
                <IconButton
                  onClick={() => {
                    companyStore.removeDomain(domain);
                    handleReset();
                  }}
                >
                  <CancelOutlinedIcon sx={{ color: "secondary.lighter" }} />
                </IconButton>
              </Box>
            );
          })}
        {companyStore.company &&
          companyStore.company.allowedDomains.length <= 0 &&
          props.touched.addAllowedDomain && (
            <FormHelperText error>Ao menos um domínio deve ser adicionado</FormHelperText>
          )}
      </Grid>
    </Fragment>
  );
};

export default observer(CompanyFormDomains);
