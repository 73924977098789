/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography, Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../stores/store.context";
import { trackPromise } from "react-promise-tracker";
import { Company } from "../../models/company.model";
import { UserData } from "../../models/user.model";
import CompanyFormStepper from "./components/company-form-stepper";
import CompanyForm from "./components/company-form";
import Breadcrumb from "../../components/breadcrumb";
import CompanyAdded from "./components/company-added";
import ButtonLoader from "../../components/button-loader";
import UserForm from "../user/user-form";

const CompanyAddPage: React.FC = () => {
  const { breadcrumbStore, companyStore, userStore, toastStore } = useContext(StoreContext);
  const [formsKey, setFormsKey] = useState(0);
  const [companyAdded, setCompanyAdded] = useState<Company | null>(null);

  useEffect(() => {
    breadcrumbStore.breadcrumb = [{ name: "Cadastrar empresa" }];

    if (companyStore.company) {
      resetForms();
    }
  }, [breadcrumbStore, companyStore, userStore]);

  const handleSave = async () => {
    if (userStore.userForm && userStore.user) {
      companyStore.company = { ...companyStore.company, keyUser: userStore.userForm };

      companyStore.company.createdBy = {
        id: userStore.user.id,
        firstName: userStore.user.firstName,
        lastName: userStore.user.lastName,
        email: userStore.user.email,
        enabled: false,
      };

      const companyAdded = await trackPromise(
        companyStore.add(companyStore.company),
        "add-company"
      );

      if (companyAdded) {
        setCompanyAdded(companyAdded);
        toastStore.pushToast({ message: "Empresa adicionada com sucesso!", type: "success" });
        companyStore.companyFormStep++;
        resetForms();
      }
    }
  };

  const resetForms = () => {
    setFormsKey(Math.floor(Math.random() * 100));
    companyStore.company = new Company();
    userStore.userForm = new UserData();
  };

  return (
    <Box sx={{ mt: 6 }}>
      {breadcrumbStore.breadcrumb && (
        <Box sx={{ my: 3 }}>
          <Breadcrumb />
        </Box>
      )}

      <Grid container sx={{ mt: 6 }}>
        <Grid item xs={12}>
          <Typography variant="h2" sx={{ color: "primary.dark" }}>
            Vamos começar! Cadastre seu cliente
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          mt: 4,
          borderRadius: "8px",
          backgroundColor: "background.default",
          display: "flex",
          px: 8,
          py: 6,
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <CompanyFormStepper />
          </Grid>

          <Box sx={{ display: companyStore.companyFormStep === 0 ? "block" : "none" }}>
            <CompanyForm key={formsKey} />
          </Box>

          <Box sx={{ display: companyStore.companyFormStep === 1 ? "block" : "none" }}>
            <UserForm key={formsKey} />

            <Grid item xs={12} sx={{ textAlign: "right" }}>
              <ButtonLoader
                type="button"
                loadingText="Processando..."
                text="Avançar"
                area="add-company"
                onClick={handleSave}
                // disabled={companyStore.companyFormState}
              />
            </Grid>
          </Box>

          <Box sx={{ display: companyStore.companyFormStep === 2 ? "flex" : "none", flexGrow: 1 }}>
            <CompanyAdded companyAdded={companyAdded} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default observer(CompanyAddPage);
