import { observer } from "mobx-react-lite";
import { Fragment, useContext, useEffect, useState } from "react";
import { StoreContext } from "../../stores/store.context";
import {
  Box,
  Button,
  Typography,
  LinearProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { action } from "mobx";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { ArrowBackOutlined } from "@mui/icons-material";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { DXIRoles } from "../../enums/dxi_roles.enum";
import { barTextPosition } from "../../helpers/";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Breadcrumb from "../../components/breadcrumb";
import ButtonLoader from "../../components/button-loader";
import DoneIcon from "@mui/icons-material/Done";
import AssessmentDoneModal from "./modals/assessment-done";

const AssessmentAnswerPage: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker({ area: "loading-data" });
  const { breadcrumbStore, assessmentStore, userStore, researchStore, modalStore } =
    useContext(StoreContext);
  const [searchParams] = useSearchParams();
  const [answer, setAnswer] = useState<number>(1);
  const location = useLocation();
  const { assessmentId, researchId, userId } = useParams();
  const [progress, setProgress] = useState(
    searchParams.get("progress") ? parseInt(searchParams.get("progress")!) : null
  );

  const questionOptions = [
    { title: "Discordo totalmente", value: 1 },
    { title: "Discordo parcialmente", value: 2 },
    { title: "Nem concordo Nem discordo", value: 3 },
    { title: "Concordo parcialmente", value: 4 },
    { title: "Concordo totalmente", value: 5 },
  ];

  useEffect(() => {
    const getAssessmentById = async (id: string) => {
      await trackPromise(assessmentStore.getAssessmentById(id), "loading-data");
      if (assessmentStore.assessment) {
        breadcrumbStore.breadcrumb = [
          { name: "Avaliações", route: "/secure/assessment" },
          { name: assessmentStore.assessment.name! },
        ];
      }

      if (researchId && assessmentId && userId) {
        getUserResearchAssessment(researchId, assessmentId, userId);
      }
    };

    const getUserResearchAssessment = async (
      researchId: string,
      assessmentId: string,
      userId: string
    ) => {
      await trackPromise(
        researchStore.getUserResearchAssessment(researchId, assessmentId, userId),
        "loading-data"
      );

      if (assessmentStore.assessment && assessmentStore.assessment.questions) {
        assessmentStore.assessment.questions.map((question) => {
          if (researchStore.researchAssessmentUser) {
            researchStore.researchAssessmentUser.forEach((userAnswer) => {
              if (question._id === userAnswer.question) {
                question.answer = userAnswer.answer;
              }
            });
          }
          return question;
        });
        assessmentStore.currentQuestion = researchStore.researchAssessmentUser!.length + 1;
      }
    };

    if (assessmentId) {
      getAssessmentById(assessmentId);
    }
  }, [
    assessmentStore,
    breadcrumbStore,
    assessmentId,
    location.state,
    researchId,
    userId,
    researchStore,
  ]);

  const handleSave = async (question: { questionId: string; answer: number }) => {
    if (researchId && assessmentId && userId) {
      await trackPromise(
        researchStore.saveResearchAssessmentAnswer(researchId, assessmentId, userId, question),
        "saving-answer"
      );

      if (assessmentStore.currentQuestion < assessmentStore.assessment!.questions!.length) {
        assessmentStore.currentQuestion++;
        setProgress(
          Math.round(
            ((assessmentStore.currentQuestion - 1) /
              assessmentStore.assessment!.questions!.length) *
              100
          )
        );
      } else if (
        assessmentStore.currentQuestion === assessmentStore.assessment!.questions!.length
      ) {
        modalStore.openModal(<AssessmentDoneModal />);
        setProgress(100);
      }
    }
  };

  return !promiseInProgress ? (
    <Box sx={{ mt: 6 }}>
      <Fragment>
        <Box sx={{ my: 3 }}>
          <Breadcrumb />
        </Box>
      </Fragment>

      {assessmentStore.assessment && assessmentStore.assessment.questions && (
        <Box sx={{ mt: 8 }}>
          <Typography variant="h2" color="primary.dark">
            {assessmentStore.assessment.name}
          </Typography>

          {progress && (
            <Box
              sx={{
                width: "100%",
                height: "32px",
                position: "relative",
                mt: 3,
                display: "flex",
                alignItems: "center",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "grey.ce9",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  left: 0,
                  backgroundColor: "success.main",
                  height: "100%",
                  width: progress === 0 ? 2 + "%" : progress + "%",
                }}
              ></Box>
              <Typography
                variant="body2"
                fontWeight={500}
                sx={{
                  zIndex: 99,
                  marginLeft: barTextPosition(progress!),
                }}
              >
                {progress}% concluído
              </Typography>
            </Box>
          )}

          {assessmentStore.assessment.questions.map((question, index) => {
            return (
              <Box
                key={index}
                sx={{
                  px: 8,
                  py: 4,
                  borderRadius: "8px",
                  mt: 8,
                  backgroundColor: "background.default",
                  display: assessmentStore.currentQuestion === index + 1 ? "block" : "none",
                }}
              >
                {question.pillar && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      component="img"
                      src={`${process.env.REACT_APP_ASSESSMENT_SERVICE}/uploads/pillar/${question.pillar.pillarImage}`}
                      sx={{ mr: 2 }}
                    />
                    <Typography variant="body2">{question.pillar.pillarName}</Typography>
                  </Box>
                )}

                <Typography variant="h4" color="primary.dark" sx={{ mt: 4 }}>
                  {question.title}
                </Typography>

                <RadioGroup
                  row
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    my: 10,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      width: "82%",
                      top: "50%",
                      height: "2px",
                      backgroundColor: "#757575",
                      marginTop: "-25px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      zIndex: 1,
                    }}
                  />

                  {questionOptions.map((option, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        labelPlacement="bottom"
                        value={option.value}
                        checked={Boolean(question.answer && question.answer === index + 1)}
                        onChange={(e) => {
                          setAnswer((e.target as any).value);
                          action(() => {
                            question.answer = parseInt((e.target as any).value);
                          })();
                        }}
                        control={
                          <Radio
                            disabled={userStore.userRole === DXIRoles.DXI_CONSULTANT ? true : false}
                            sx={{
                              "&:hover": {
                                background: "white",
                              },
                              zIndex: 10,
                              "& .MuiSvgIcon-root": {
                                fontSize: 40,
                              },
                              background: "white",
                              padding: 0,
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant="body1"
                            sx={{ maxWidth: "130px", textAlign: "center" }}
                          >
                            {option.title}
                          </Typography>
                        }
                      />
                    );
                  })}
                </RadioGroup>

                {progress !== 100 && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: 4,
                    }}
                  >
                    <Button
                      variant="text"
                      onClick={() => assessmentStore.currentQuestion--}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        visibility: assessmentStore.currentQuestion > 1 ? "visible" : "hidden",
                      }}
                    >
                      <ArrowBackOutlined fontSize="small" sx={{ mr: 2 }} /> Voltar
                    </Button>

                    <Typography>
                      {assessmentStore.currentQuestion} de{" "}
                      {assessmentStore.assessment!.questions!.length} perguntas
                    </Typography>

                    <ButtonLoader
                      loadingText="Carregando..."
                      text={
                        <>
                          {assessmentStore.currentQuestion <
                          assessmentStore.assessment!.questions!.length ? (
                            <>
                              Avançar <ArrowForwardIcon fontSize="small" sx={{ ml: 2 }} />
                            </>
                          ) : (
                            <>
                              Finalizar <DoneIcon fontSize="small" sx={{ ml: 2 }} />
                            </>
                          )}
                        </>
                      }
                      type="button"
                      area="saving-answer"
                      onClick={() => {
                        if (userStore.userRole !== DXIRoles.DXI_CONSULTANT && answer) {
                          handleSave({ questionId: question._id!, answer });
                        }

                        if (userStore.userRole === DXIRoles.DXI_CONSULTANT) {
                          assessmentStore.currentQuestion++;
                        }
                      }}
                      style={{
                        visibility:
                          assessmentStore.currentQuestion >= 1 &&
                          assessmentStore.currentQuestion <=
                            assessmentStore.assessment!.questions!.length
                            ? "visible"
                            : "hidden",
                      }}
                    />
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  ) : (
    <LinearProgress sx={{ position: "absolute", width: "100%", top: 0, left: 0 }} />
  );
};

export default observer(AssessmentAnswerPage);
