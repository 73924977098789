import { Box } from '@mui/material'

const SocialFacebook = () => {
  return (
    <>
      <Box sx={{ width: '100%', height: '100%' }}>
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="17" cy="17" r="17" fill="white" />
          <g clipPath="url(#clip0_56_48882)">
            <path
              d="M18.3891 25.017V17.7113H20.8775L21.2509 14.8634H18.3891V13.0453C18.3891 12.221 18.6206 11.6593 19.8219 11.6593L21.3516 11.6587V9.11132C21.0871 9.07746 20.179 8.99982 19.1221 8.99982C16.9151 8.99982 15.4041 10.3268 15.4041 12.7633V14.8634H12.9082V17.7113H15.4041V25.017H18.3891Z"
              fill="#0050D6"
            />
          </g>
          <defs>
            <clipPath id="clip0_56_48882">
              <rect width="16.2601" height="16.0172" fill="white" transform="translate(9 9)" />
            </clipPath>
          </defs>
        </svg>
      </Box>
    </>
  )
}

export default SocialFacebook
