import { observer } from "mobx-react-lite";
import { Fragment, useContext, useEffect } from "react";
import { StoreContext } from "../../../stores/store.context";
import { Box, Button, Divider, LinearProgress, Typography } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { infoBoxes } from "../constants/assessment-info-boxes";
import BackButton from "../../../components/back-button";
import Breadcrumb from "../../../components/breadcrumb";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { DXIRoles } from "../../../enums/dxi_roles.enum";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";

const AssessmentIntro: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker({ area: "loading-data" });
  const [searchParams] = useSearchParams();
  const { breadcrumbStore, assessmentStore, userStore } = useContext(StoreContext);

  const navigate = useNavigate();
  const { id } = useParams();

  const assessmentName = searchParams.get("name");

  useEffect(() => {
    const getAssessmentById = async (id: string) =>
      await trackPromise(assessmentStore.getAssessmentById(id), "loading-data");

    if (assessmentName) {
      breadcrumbStore.breadcrumb = [
        { name: "Avaliações", route: "/secure/assessment" },
        { name: assessmentName },
      ];
    }
    if (id) {
      getAssessmentById(id);
    }
  }, [assessmentName, assessmentStore, breadcrumbStore, id]);
  return !promiseInProgress ? (
    <Box sx={{ mt: 6 }}>
      {breadcrumbStore.breadcrumb && (
        <Fragment>
          <Box sx={{ my: 3 }}>
            <Breadcrumb />
          </Box>
          <Box sx={{ my: 3 }}>
            <BackButton route={"previous"} />
          </Box>
        </Fragment>
      )}

      {assessmentStore.assessment && (
        <Box
          sx={{
            borderRadius: "8px",
            mt: 8,
            backgroundColor: "background.default",
          }}
        >
          <Box
            sx={{
              maxHeight: "180px",
              width: "100%",
              objectFit: "fill",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
            component="img"
            src={`${process.env.REACT_APP_ASSESSMENT_SERVICE}/uploads/assessment/${assessmentStore.assessment.image}`}
          />
          <Box sx={{ p: 4, display: "flex", flexDirection: "column" }}>
            <Typography variant="h3">Análise de maturidade:</Typography>

            <Typography variant="h2" color="primary.dark" sx={{ mt: 2 }}>
              {assessmentStore.assessment.name}
            </Typography>

            <Typography variant="caption">
              Esta pesquisa é geralmente concluída em até 10 minutos.
            </Typography>

            <Divider sx={{ my: 4 }} />

            <Typography variant="body1">
              O Modelo de Maturidade da Meta busca compreender o nível de envolvimento em que as
              organizações se encontram em relação às práticas digitais e, dessa forma, identificar
              os desafios para a escalabilidade do negócio, otimização de processos e apetite de
              inovação.
            </Typography>

            <Typography variant="body1" sx={{ my: 2 }}>
              Isso é possível relacionando o quanto cada um dos pontos expostos fazem parte da
              rotina da organização em relação à intensidade, frequência ou abrangência em que
              ocorrem e classificando entre os níveis:
            </Typography>

            <Box
              sx={{
                display: "flex",
                mt: 4,
                gap: 2,
                alignItems: "start",
                justifyContent: "space-between",
              }}
            >
              {infoBoxes.map((box, index) => {
                return (
                  <Box key={index} sx={{ maxWidth: "170px" }}>
                    <Typography
                      variant="body1"
                      sx={{ px: 2, py: 1, backgroundColor: "background.paper" }}
                      color="primary.dark"
                      fontWeight={700}
                      textAlign={"center"}
                    >
                      {box.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        px: 3,
                        py: 1,
                        backgroundColor: "background.default",
                        wordBreak: "break-word",
                      }}
                      textAlign={"center"}
                    >
                      {box.description}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            <Button
              variant="contained"
              sx={{ mt: 4, display: "flex", placeSelf: "end" }}
              onClick={() =>
                navigate(`/secure/assessment/${assessmentStore.assessment!._id}/answer`)
              }
            >
              {userStore.userRole === DXIRoles.DXI_CONSULTANT ? "Visualizar" : "Iniciar"}
              <ArrowForwardIcon fontSize="small" sx={{ ml: 2 }} />
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  ) : (
    <LinearProgress sx={{ position: "absolute", width: "100%", top: 0, left: 0 }} />
  );
};

export default observer(AssessmentIntro);
