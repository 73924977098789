import { makeAutoObservable } from "mobx";
import { UserService } from "../services/user.service";
import { MainMenu } from "../models/menu.model";
import { DXIRoles } from "../enums/dxi_roles.enum";
import { MetaUserData, UserData } from "../models/user.model";

export class UserStore {
  private _menu: MainMenu | null = null;
  private _user: MetaUserData | null = null;
  private _userForm: UserData = new UserData();
  private _userRole: DXIRoles | null = null;
  private _userFormStep: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  userTokenValidate = async () => {
    try {
      const userService = new UserService();
      const res = await userService.userTokenValidate();
      const role: DXIRoles = res.roles.filter(
        (role: string) => role.split(".")[4] === "menu"
      )[0] as DXIRoles;
      this.userRole = role as DXIRoles;
      this.user = res;
    } catch (e: any) {
      this.userRole = null;
      this.user = null;
    }
  };

  getUserMenu = async (role: DXIRoles): Promise<void> => {
    try {
      const userService = new UserService();
      const menu = await userService.getMenu(role);
      this.menu = menu;
    } catch (e: any) {
      this.menu = null;
    }
  };

  get menu() {
    return this._menu;
  }

  set menu(menu: MainMenu | null) {
    this._menu = menu;
  }

  set user(userData: MetaUserData | null) {
    this._user = userData;
  }

  get user() {
    return this._user;
  }

  get userForm() {
    return this._userForm;
  }

  set userForm(userData: UserData) {
    this._userForm = userData;
  }

  set userRole(role: DXIRoles | null) {
    this._userRole = role;
  }

  get userRole() {
    return this._userRole;
  }

  get userFullName() {
    return this.user?.firstName + " " + this.user?.lastName;
  }

  set userFormStep(step: number) {
    this._userFormStep = step;
  }

  get userFormStep() {
    return this._userFormStep;
  }
}
