import {
  Avatar,
  Container,
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Typography,
  AppBar,
  Box,
  Toolbar,
  Button,
} from "@mui/material";
import { Link as RouterLink, useMatch, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../stores/store.context";
import { MainMenuItem } from "../models/menu.model";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import MetaDxiLogo from "../assets/images/meta-dxi.logo";

const Navbar: React.FC = () => {
  const { userStore, themeStore, keycloakStore } = useContext(StoreContext);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorElUser(event.currentTarget);
  const handleCloseUserMenu = () => setAnchorElUser(null);

  const location = useLocation();
  const match = useMatch(location.pathname);

  const isActive = {
    color: themeStore.theme.palette.primary.main,
    fontWeight: 600,
    fontSize: "14px",
  };

  const linkProps = {
    "&:hover": {
      color: themeStore.theme.palette.primary.main,
    },
  };

  const handleLogout = () => {
    if (keycloakStore.keycloak) {
      keycloakStore.keycloak.logout({ redirectUri: window.location.origin });
      handleCloseUserMenu();
      localStorage.removeItem("dxi_login_type");
    }
  };

  useEffect(() => {
    const getMenu = async () => await userStore.getUserMenu(userStore.userRole!);
    if (userStore.user) {
      getMenu();
    }
  }, [userStore]);

  const getStyle = (menu: MainMenuItem) => {
    return (match && location.pathname.startsWith(menu.routeLink)) ||
      (location.pathname === "/" && menu.routeLink === "/secure/home") ||
      (location.pathname.startsWith("/secure/company/") &&
        location.pathname.endsWith("/manage/") &&
        menu.routeLink === "/secure/company/list")
      ? isActive
      : undefined;
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: "background.default" }}>
        <Container
          sx={{
            maxWidth: {
              xs: themeStore.theme.breakpoints.values.lg,
              xl: themeStore.theme.breakpoints.values.xl,
            },
          }}
        >
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1 }}>
              <Link underline="none" component={RouterLink} to="/secure/home">
                <MetaDxiLogo />
              </Link>
            </Box>

            <Box sx={{ display: "flex", flexGrow: 1, justifyContent: "right" }}>
              {userStore.menu &&
                userStore.menu.items.map((menu: MainMenuItem, index: number) => {
                  return (
                    <Box key={index} sx={{ display: "contents" }}>
                      <Link
                        sx={{ ...linkProps, mx: 2, color: "grey.c59" }}
                        underline="none"
                        component={RouterLink}
                        to={menu.routeLink}
                        style={getStyle(menu)}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: getStyle(menu) ? 600 : 400,
                          }}
                        >
                          {menu.name}
                          <span className="line"></span>
                        </Typography>
                      </Link>
                    </Box>
                  );
                })}
            </Box>

            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, ml: 4 }}>
              <Avatar src="/static/images/avatar/2.jpg" />{" "}
              {!anchorElUser ? (
                <KeyboardArrowDownOutlinedIcon sx={{ color: "primary.main" }} />
              ) : (
                <KeyboardArrowUpOutlinedIcon sx={{ color: "primary.main" }} />
              )}
            </IconButton>

            <Menu
              sx={{
                mt: "45px",
                "& .MuiPaper-root": {
                  backgroundColor: "background.default",
                },
              }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {
                <MenuItem onClick={handleCloseUserMenu} sx={{ cursor: "default" }}>
                  <Typography
                    variant="body2"
                    textAlign="center"
                    sx={{ display: "flex" }}
                    color="secondary.lighter"
                  >
                    {userStore.userFullName}
                  </Typography>
                </MenuItem>
              }
              <Divider sx={{ my: 2 }} />
              <MenuItem onClick={handleLogout}>
                <Button variant="text">
                  <Typography
                    variant="body2"
                    textAlign="center"
                    sx={{ display: "flex" }}
                    color="secondary.lighter"
                  >
                    <LogoutOutlined sx={{ mr: 2, color: "secondary.lighter" }} />
                    Sair
                  </Typography>
                </Button>
              </MenuItem>
            </Menu>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};

export default observer(Navbar);
