import { Box } from '@mui/material'

const SocialYoutube = () => {
  return (
    <>
      <Box sx={{ width: '100%', height: '100%' }}>
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="17" cy="17" r="17" fill="white" />
          <g clipPath="url(#clip0_56_48870)">
            <path
              d="M26.1504 13.0041C26.0454 12.6313 25.841 12.2938 25.5588 12.0275C25.2686 11.7529 24.9129 11.5565 24.5254 11.4568C23.0749 11.0761 17.2636 11.0761 17.2636 11.0761C14.841 11.0487 12.419 11.1694 10.0112 11.4376C9.62363 11.5447 9.26863 11.7454 8.97779 12.0221C8.69202 12.2958 8.48503 12.6335 8.3769 13.0034C8.11715 14.3967 7.99095 15.8114 7.99999 17.2285C7.99072 18.6443 8.11661 20.0586 8.3769 21.4537C8.48271 21.822 8.68893 22.1581 8.97548 22.4296C9.26202 22.7011 9.61885 22.8972 10.0112 23.001C11.481 23.3809 17.2636 23.3809 17.2636 23.3809C19.6894 23.4084 22.1145 23.2877 24.5254 23.0195C24.9129 22.9198 25.2686 22.7234 25.5588 22.4488C25.8445 22.1773 26.0477 21.8397 26.1496 21.4721C26.4162 20.0793 26.5458 18.664 26.5366 17.2462C26.5566 15.8224 26.4272 14.4004 26.1504 13.0034V13.0041ZM15.4162 19.8617V14.5961L20.2511 17.2293L15.4162 19.8617Z"
              fill="#0050D6"
            />
          </g>
          <defs>
            <clipPath id="clip0_56_48870">
              <rect width="18.5366" height="18.4571" fill="white" transform="translate(8 8)" />
            </clipPath>
          </defs>
        </svg>
      </Box>
    </>
  )
}

export default SocialYoutube
