import { Typography, Box } from "@mui/material";
import { useContext } from "react";
import { StoreContext } from "../../../stores/store.context";
import { observer } from "mobx-react-lite";

const ResearchReportRecommendation: React.FC = () => {
  const { researchStore } = useContext(StoreContext);

  return (
    researchStore.researchAssessmentData && (
      <Box sx={{ backgroundColor: "grey.cf2", borderRadius: "8px", p: 2, mt: 2, mb: 6 }}>
        <Typography variant="h4" color="primary.dark">
          Recomendação
        </Typography>
        <Typography variant="body1" color="grey.c59" sx={{ mt: 2 }}>
          Para ajudá-lo a alcançar um estágio de implementação, a Meta DXi recomenda as seguintes
          ações:{" "}
        </Typography>

        {researchStore.researchAssessmentData.assessment && (
          <Typography variant="body2" color="grey.c59" sx={{ mt: 4 }}>
            {
              researchStore.researchAssessmentData.assessment.levels![
                researchStore.researchAssessmentData!.activeLevel!
              ].recommendation
            }
          </Typography>
        )}
      </Box>
    )
  );
};

export default observer(ResearchReportRecommendation);
