import { Box, Button, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { StoreContext } from "../../../stores/store.context";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { trackPromise } from "react-promise-tracker";
import ButtonLoader from "../../../components/button-loader";

interface ResearchFinishModalProps {
  id: string;
}

const ResearchFinishModal: React.FC<ResearchFinishModalProps> = (
  props: ResearchFinishModalProps
) => {
  const { modalStore, researchStore, toastStore } = useContext(StoreContext);

  const handleFinish = async () => {
    if (props.id) {
      const res = await trackPromise(
        researchStore.changeResearchStatus(props.id, "done"),
        "finishing-research"
      );
      if (res) {
        toastStore.pushToast({ message: "Pesquisa finalizada com sucesso!", type: "success" });
      }
      modalStore.handleClose();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: 1,
      }}
    >
      <ErrorOutlineOutlinedIcon sx={{ fontSize: "64px", color: "warning.main" }} />

      <Typography variant="h4" color="black">
        Deseja finalizar esta pesquisa?
      </Typography>

      <Typography variant="body2" color="grey.c59" sx={{ my: 2, textAlign: "center" }}>
        Os convidados que não concluiram serão desconsiderados e o diagnóstico será gerado com base
        nas respostas válidas.
      </Typography>

      <Typography variant="body2" color="grey.c59" sx={{ textAlign: "center" }}>
        Não será possível retornar para o status Aberto.
      </Typography>

      <ButtonLoader
        loadingText="Processando..."
        text={"Sim, finalizar e gerar diagnóstico"}
        variant="contained"
        area="finishing-research"
        type="button"
        onClick={handleFinish}
        style={{ my: 3 }}
      />

      <Button variant="text" onClick={() => modalStore.handleClose()}>
        Não, desejo manter o status atual
      </Button>
    </Box>
  );
};

export default observer(ResearchFinishModal);
