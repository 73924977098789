import * as Yup from "yup";
import {
  Box,
  Grid,
  Typography,
  LinearProgress,
  TextField,
  FormHelperText,
  Divider,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { Fragment, useContext, useEffect, useState } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import BackButton from "../../components/back-button";
import Breadcrumb from "../../components/breadcrumb";
import CompanyDataCard from "../company/components/company-data-card";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StoreContext } from "../../stores/store.context";
import { Formik } from "formik";
import AssessmentList from "../assessment/components/assessment-list";
import ButtonLoader from "../../components/button-loader";
import CompanyCombo from "../company/components/company-combo";
import { action } from "mobx";

const ResearchAddPage: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker({ area: "loading-data" });
  const { breadcrumbStore, companyStore, researchStore, assessmentStore, userStore, toastStore } =
    useContext(StoreContext);
  const [redirecting, setRedirecting] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const researchSchema = Yup.object().shape({
    name: Yup.string().max(255).required("Campo obrigatório"),
  });

  const companyId = searchParams.get("company");
  const companyName = searchParams.get("companyName");

  useEffect(() => {
    action(() => (assessmentStore.selectedAssessments = []))();
  }, []);

  useEffect(() => {
    if (companyId && companyName) {
      breadcrumbStore.breadcrumb = [
        { name: "Meus clientes", route: `/secure/company/list` },
        { name: companyName, route: `/secure/company/${companyId}/manage/` },
        { name: "Nova pesquisa" },
      ];
    } else {
      let companyObj = null;
      if (companyStore.selectedCompany) {
        companyObj = {
          name: companyStore.selectedCompany.name,
          route: `/secure/company/${companyStore.selectedCompany._id}/manage`,
        };
      }

      const breadcrumb = [
        { name: "Minhas pesquisas", route: `/secure/research/` },
        ...(companyObj ? [companyObj] : []),
        { name: "Nova pesquisa" },
      ];

      breadcrumbStore.breadcrumb = breadcrumb;
    }
  }, [
    breadcrumbStore,
    companyStore,
    searchParams,
    assessmentStore.selectedAssessments,
    companyId,
    companyName,
    companyStore.selectedCompany,
  ]);

  const handleAdd = async () => {
    if (companyId || companyStore.selectedCompany) {
      const id = companyId ?? companyStore.selectedCompany!._id;

      researchStore.research = {
        ...researchStore.research,
        assessments: assessmentStore.selectedAssessments,
        createdBy: {
          email: userStore.user!.email,
          firstName: userStore.user!.firstName,
          lastName: userStore.user!.lastName,
        },
      };
      const addedResearch = await trackPromise(
        researchStore.addResearch(id!, researchStore.research),
        "add-research"
      );

      if (addedResearch) {
        toastStore.pushToast({ message: "Pesquisa adicionada com sucesso!", type: "success" });

        setRedirecting(true);
        setTimeout(() => {
          researchStore.research = null;
          navigate("/secure/research/" + addedResearch._id);
        }, 3000);
      }
    }
  };

  return !promiseInProgress ? (
    <Box sx={{ mt: 6 }}>
      {breadcrumbStore.breadcrumb && (
        <Fragment>
          <Box sx={{ my: 3 }}>
            <Breadcrumb />
          </Box>
          <Box sx={{ my: 3 }}>
            <BackButton route={"/secure/research"} />
          </Box>
        </Fragment>
      )}

      <Fragment>
        <Typography variant="h2" color="primary.dark" sx={{ mt: 8 }}>
          Nova pesquisa
        </Typography>

        <Box
          sx={{
            borderRadius: "8px",
            backgroundColor: "background.default",
            mt: 4,
            px: 8,
            py: 4,
          }}
        >
          {!companyId && !companyStore.selectedCompany && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h3" color="grey.c59" sx={{ my: 2 }}>
                Escolha uma empresa cadastrada para iniciar uma pesquisa
              </Typography>

              <CompanyCombo />
            </Box>
          )}

          {(companyId || companyStore.selectedCompany) && (
            <Grid container>
              <Grid item xs={12}>
                <CompanyDataCard />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h4" color="primary.dark" sx={{ mt: 4 }}>
                  Comece, definindo um nome para facilitar a identificação
                </Typography>
              </Grid>

              <Grid container sx={{ mt: 4 }}>
                <Formik
                  enableReinitialize={true}
                  validateOnMount={true}
                  initialValues={{ name: "" }}
                  validationSchema={researchSchema}
                  onSubmit={async () => {}}
                >
                  {({ errors, handleChange, touched, values }) => (
                    <form noValidate>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          error={Boolean(touched.name && errors.name)}
                          id="name"
                          type="text"
                          value={values.name}
                          name="name"
                          onChange={(e) => {
                            handleChange(e);
                            researchStore.research = {
                              ...researchStore.research,
                              name: e.target.value,
                            };
                          }}
                          placeholder="Acompanhamento 2 SEM. 2023"
                          variant="outlined"
                          label="Nome da pesquisa"
                          inputProps={{
                            autoComplete: "research-name",
                            form: {
                              autocomplete: "off",
                            },
                          }}
                        />
                        {touched.name && errors.name && (
                          <FormHelperText error>{errors.name}</FormHelperText>
                        )}
                      </Grid>

                      <Grid item xs={12} sx={{ my: 6 }}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12} sx={{ mt: 4 }}>
                        <AssessmentList
                          cardCanBeSelected={true}
                          hasHeader={true}
                          listHeaderText="Selecione as avaliações que estarão disponíveis nessa pesquisa."
                        />
                      </Grid>

                      <Grid item xs={12} sx={{ mt: 4, textAlign: "center" }}>
                        {!redirecting ? (
                          <ButtonLoader
                            loadingText="Carregando..."
                            text="Iniciar pesquisa"
                            area="add-research"
                            type="button"
                            disabled={
                              values.name === "" || assessmentStore.selectedAssessments.length === 0
                            }
                            onClick={handleAdd}
                          />
                        ) : (
                          <Fragment>
                            <Typography variant="body2" sx={{ mb: 2 }}>
                              Aguarde, redirecionando para a nova pesquisa
                            </Typography>

                            <LinearProgress sx={{ width: "300px", margin: "auto" }} />
                          </Fragment>
                        )}
                      </Grid>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          )}
        </Box>
      </Fragment>
    </Box>
  ) : (
    <LinearProgress sx={{ position: "absolute", width: "100%", top: 0, left: 0 }} />
  );
};

export default observer(ResearchAddPage);
