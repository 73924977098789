import { observer } from "mobx-react-lite";
import { Fragment, useContext, useEffect } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { StoreContext } from "../../../stores/store.context";
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import CircularLoader from "../../../components/circular-loader";

const CompanyCombo: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker({ area: "loading-data" });
  const { companyStore, gridStore, userStore } = useContext(StoreContext);

  useEffect(() => {
    const getConsultantCompanies = async () => {
      var filter = JSON.stringify({
        name: gridStore.searchText ? gridStore.searchText : "",
        status: "",
        createdBy: userStore.user!.id,
      });

      await trackPromise(
        companyStore.listConsultantCompanies(
          userStore.user!.id,
          gridStore.currentPage,
          0,
          gridStore.orderBy.field !== "" ? gridStore.orderBy.field : "name",
          gridStore.orderBy.direction,
          filter
        ),
        ""
      );
    };

    getConsultantCompanies();
  }, [companyStore, gridStore, userStore.user]);

  return (
    <Fragment>
      {!promiseInProgress ? (
        <FormControl fullWidth>
          <InputLabel id="company-combo-label">Empresa</InputLabel>
          <Select
            labelId="company-combo-label"
            id="company-combo"
            value={companyStore.selectedCompany ? companyStore.selectedCompany._id : ""}
            label="Selecionar empresa"
            onChange={(e: SelectChangeEvent) => {
              const selectedCompanyId = e.target.value;
              const selectedCompany = companyStore.companies!.paginatedData.find(
                (company) => company._id === selectedCompanyId
              );
              companyStore.selectedCompany = selectedCompany || null;

              companyStore.company = selectedCompany!;
            }}
          >
            {companyStore.companies &&
              companyStore.companies.paginatedData.map((company, index) => {
                return (
                  <MenuItem value={company._id} key={index}>
                    {company.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      ) : (
        <CircularLoader />
      )}
    </Fragment>
  );
};

export default observer(CompanyCombo);
