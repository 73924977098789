import { Pagination } from "../models/pagination.model";
import { Assessment } from "../models/assessment.model";
import axios from "axios";
import { errHandler } from "../helpers/error-handler";

export class AssessmentService {
  async listAssessments(
    page: number,
    limit: number,
    orderBy?: string,
    orderbyDirection?: string
  ): Promise<Pagination<Array<Assessment>>> {
    try {
      const res = await axios.get(
        process.env.REACT_APP_ASSESSMENT_SERVICE +
          `/assessment/list?page=${page}&limit=${limit}&orderBy=${
            orderBy || "createdAt"
          }&orderByDirection=${orderbyDirection || "desc"}`
      );

      if (res.status !== 200) throw new Error("Erro ao carregar avaliações");

      const parsedResponse: Pagination<Array<Assessment>> = await res.data.body;

      parsedResponse.paginatedData = parsedResponse.paginatedData.map((assessment: Assessment) => {
        return {
          _id: assessment._id,
          name: assessment.name,
          description: assessment.description,
          image: assessment.image,
          status: assessment.status,
        };
      });

      return parsedResponse;
    } catch (e: any) {
      throw errHandler(e);
    }
  }

  async getAssessmentById(id: string): Promise<Assessment> {
    try {
      const res = await axios.get(process.env.REACT_APP_ASSESSMENT_SERVICE + `/assessment/${id}`);

      const parsedResponse: Assessment = await res.data.body;

      return parsedResponse;
    } catch (e: any) {
      throw errHandler(e);
    }
  }

  async updateAssessmentAccess(
    assessmentId: string,
    researchId: string,
    userId: string
  ): Promise<Assessment> {
    try {
      const res = await axios.put(
        process.env.REACT_APP_ASSESSMENT_SERVICE +
          `/assessment/${assessmentId}/research/${researchId}/user/${userId}/access`
      );
      const parsedResponse: Assessment = await res.data.body;
      return parsedResponse;
    } catch (e: any) {
      throw errHandler(e);
    }
  }
}
