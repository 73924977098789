import { Box } from '@mui/material'

const SocialLinkedIn = () => {
  return (
    <>
      <Box sx={{ width: '100%', height: '100%' }}>
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="17" cy="17" r="17" fill="white" />
          <g clipPath="url(#clip0_56_48876)">
            <path
              d="M25.1165 23.2299V23.2293H25.1203V17.6786C25.1203 14.9631 24.5269 12.8713 21.3042 12.8713C19.7549 12.8713 18.7153 13.7088 18.2908 14.5028H18.246V13.1248H15.1904V23.2293H18.3721V18.226C18.3721 16.9086 18.6257 15.6347 20.2818 15.6347C21.9137 15.6347 21.938 17.1381 21.938 18.3105V23.2299H25.1165Z"
              fill="#0050D6"
            />
            <path d="M10.0098 13.1252H13.1953V23.2297H10.0098V13.1252Z" fill="#0050D6" />
            <path
              d="M11.6009 8.09497C10.5823 8.09497 9.75586 8.9091 9.75586 9.91241C9.75586 10.9157 10.5823 11.7469 11.6009 11.7469C12.6194 11.7469 13.4459 10.9157 13.4459 9.91241C13.4452 8.9091 12.6188 8.09497 11.6009 8.09497V8.09497Z"
              fill="#0050D6"
            />
          </g>
          <defs>
            <clipPath id="clip0_56_48876">
              <rect width="15.3644" height="15.1349" fill="white" transform="translate(9.75586 8.09497)" />
            </clipPath>
          </defs>
        </svg>
      </Box>
    </>
  )
}

export default SocialLinkedIn
