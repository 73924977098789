/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Fragment, useContext, useEffect } from "react";
import { StoreContext } from "../../../stores/store.context";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { useLocation, useNavigate } from "react-router-dom";
import GridFilter from "../../../components/grid-filter";
import GridTable from "../../../components/grid-table";
import AddIcon from "@mui/icons-material/Add";
import CircularLoader from "../../../components/circular-loader";
import NoData from "../../../components/no-data";
import { DXIRoles } from "../../../enums/dxi_roles.enum";

interface ResearchListProps {
  hasFilter?: boolean;
  gridColorScheme?: "grey" | "blue";
  filterCompany?: boolean;
  page?: string;
}

const ResearchList: React.FC<ResearchListProps> = (props: ResearchListProps) => {
  const { promiseInProgress } = usePromiseTracker({ area: "loading-grid-data" });
  const { researchStore, gridStore, companyStore, userStore } = useContext(StoreContext);

  const navigate = useNavigate();

  const tableColumns = [
    { field: "companyName", text: "Empresa", ordered: { orderBy: "company.name" } },
    { field: "name", text: "Pesquisa" },
    { field: "createdAt", text: "Criado", ordered: { orderBy: "createdAt" } },
    { field: "finishedAt", text: "Finalizado" },
    { field: "status", text: "Status" },
    {
      field: "action",
      text: "Ver detalhes",
      link: `/secure/research/:_id/?page=${props.page}`,
    },
  ];

  const location = useLocation();

  const getResearches = async () => {
    if (companyStore.company) {
      var filter = JSON.stringify({
        name: gridStore.searchText ? gridStore.searchText : "",
        status: gridStore.searchStatus !== "all" ? gridStore.searchStatus : "",
        company: props.filterCompany ? companyStore.company._id : "",
      });

      await trackPromise(
        researchStore.listResearches(
          gridStore.currentPage,
          gridStore.limit,
          gridStore.orderBy.field !== "" ? gridStore.orderBy.field : "name",
          gridStore.orderBy.direction,
          filter
        ),
        gridStore.currentPage > 1 ? "load-more" : "loading-grid-data"
      );
    }
  };

  const getCompanies = async () => {
    await trackPromise(companyStore.listCompanies(1, gridStore.limit, "name", "asc", ""));
  };

  useEffect(() => {
    gridStore.currentPage = 1;
    gridStore.searchText = "";
    gridStore.searchStatus = "all";
    gridStore.orderBy = { direction: "asc", field: "" };
  }, [location.pathname]);

  useEffect(() => {
    if (userStore.userRole === DXIRoles.DXI_CONSULTANT) {
      getCompanies();
    }
    getResearches();
  }, [
    researchStore,
    gridStore.currentPage,
    gridStore.limit,
    gridStore.searchText,
    companyStore.company,
    gridStore.orderBy.field,
    gridStore.orderBy.direction,
    gridStore.searchStatus,
    props.filterCompany,
  ]);

  const getCustomText = () => {
    if (
      companyStore.companies &&
      companyStore.companies.totalItems > 0 &&
      researchStore.researches &&
      researchStore.researches.totalItems <= 0
    ) {
      if (
        location.pathname === "/secure/home" ||
        location.pathname === "/" ||
        location.pathname === "/secure/research"
      ) {
        return "Suas empresas cadastradas ainda não possuem pesquisas abertas. Comece agora!";
      } else {
        return "Essa empresa ainda não possui pesquisas abertas. Comece agora!";
      }
    } else {
      if (userStore.userRole !== DXIRoles.DXI_CONSULTANT) {
        return "Essa empresa ainda não possui pesquisas abertas. Comece agora!";
      }
      return "Você ainda não possui nenhuma empresa cadastrada, cadastre para criar uma nova pesquisa.";
    }
  };

  const getCustomButton = () => {
    if (
      companyStore.companies &&
      companyStore.companies.totalItems > 0 &&
      researchStore.researches &&
      researchStore.researches.totalItems <= 0
    ) {
      return (
        <Button
          variant="contained"
          onClick={() => {
            if (companyStore.company && companyStore.company._id && props.filterCompany) {
              navigate(
                `/secure/research/add?company=${companyStore.company!._id}&companyName=${
                  companyStore.company!.name
                }`
              );
            } else {
              companyStore.selectedCompany = null;
              navigate(`/secure/research/add`);
            }
          }}
        >
          <AddIcon sx={{ mr: 2 }} /> Nova pesquisa
        </Button>
      );
    } else {
      if (userStore.userRole !== DXIRoles.DXI_CONSULTANT) {
        return (
          <Button
            variant="contained"
            onClick={() =>
              navigate(
                `/secure/research/add?company=${companyStore.company!._id}&companyName=${
                  companyStore.company!.name
                }`
              )
            }
          >
            <AddIcon sx={{ mr: 2 }} /> Nova pesquisa
          </Button>
        );
      }
      return (
        <Button variant="contained" onClick={() => navigate("/secure/company/add")}>
          <AddIcon sx={{ mr: 2 }} /> Cadastrar empresa
        </Button>
      );
    }
  };

  return (
    <Fragment>
      {props.hasFilter && researchStore.researches && (
        <Grid container sx={{ display: "flex", alignItems: "center", px: 4, py: 2, mt: 2, mb: 4 }}>
          <Grid item xs={12} sm={9}>
            <GridFilter inputPlaceholder="Procure pelo nome da pesquisa" />
          </Grid>
          <Grid item xs={12} sm={3} textAlign={"right"}>
            <Button
              variant="contained"
              onClick={() => {
                if (companyStore.company && companyStore.company._id && props.filterCompany) {
                  navigate(
                    `/secure/research/add?company=${companyStore.company!._id}&companyName=${
                      companyStore.company!.name
                    }`
                  );
                } else {
                  companyStore.selectedCompany = null;
                  navigate(`/secure/research/add`);
                }
              }}
            >
              <AddIcon sx={{ mr: 1 }} />
              Nova pesquisa
            </Button>
          </Grid>
        </Grid>
      )}

      <Grid
        container
        sx={{
          borderRadius: "10px",
          backgroundColor: "background.default",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {researchStore.researches && !promiseInProgress && (
          <GridTable
            tableColumns={tableColumns}
            tableData={researchStore.researches}
            colorScheme={props.gridColorScheme}
            customNoData={<NoData text={getCustomText()} button={getCustomButton()} />}
            researchList={true}
          />
        )}

        {promiseInProgress && <CircularLoader />}
      </Grid>
    </Fragment>
  );
};

export default observer(ResearchList);
