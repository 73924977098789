import { observer } from "mobx-react-lite";
import ResearchList from "./components/research-list";
import { Box, Grid, Typography } from "@mui/material";
import Breadcrumb from "../../components/breadcrumb";
import { useContext, useEffect } from "react";
import { StoreContext } from "../../stores/store.context";

const ResearchPage: React.FC = () => {
  const { breadcrumbStore } = useContext(StoreContext);

  useEffect(() => {
    breadcrumbStore.breadcrumb = [{ name: "Minhas pesquisas" }];
  }, [breadcrumbStore]);

  return (
    <Box sx={{ mt: 6 }}>
      {breadcrumbStore.breadcrumb && (
        <Box sx={{ my: 3 }}>
          <Breadcrumb />
        </Box>
      )}

      <Grid container sx={{ mt: 6 }}>
        <Grid item xs={12}>
          <Typography variant="h2" sx={{ color: "primary.dark" }}>
            Minhas pesquisas
          </Typography>
        </Grid>

        <Grid
          container
          sx={{
            mt: 4,
            borderRadius: "10px",
            backgroundColor: "background.default",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ResearchList hasFilter={true} page="research" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default observer(ResearchPage);
