import axios from "axios";
import { DXIRoles } from "../enums/dxi_roles.enum";
import { errHandler } from "../helpers/error-handler";

export class UserService {
  async userTokenValidate() {
    try {
      const res = await axios.get(process.env.REACT_APP_META_SERVICE + `/api/v1/auth/validate`);
      return res.data;
    } catch (e: any) {
      throw errHandler(e);
    }
  }

  async getMenu(DXIRole: DXIRoles) {
    try {
      const res = await axios.get(process.env.REACT_APP_USER_SERVICE + `/user/menu/${DXIRole}`);
      return res.data;
    } catch (e: any) {
      throw errHandler(e);
    }
  }
}
