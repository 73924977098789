import { Typography, Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../../../stores/store.context";
import { useContext } from "react";

const ResearchReportSolutions: React.FC = () => {
  const { researchStore } = useContext(StoreContext);

  return (
    researchStore.researchAssessmentData && (
      <Box>
        <Typography variant="h3" color="primary.dark" sx={{ mb: 2 }}>
          Soluções que podem evoluir seu negócio
        </Typography>

        {researchStore.researchAssessmentData.assessment.solutions && (
          <Box sx={{ display: "flex" }}>
            {researchStore.researchAssessmentData.assessment.solutions.map((solution, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: 2,
                    minHeight: "360px",
                    maxHeight: "360px",
                    borderColor: "grey.cf2",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    width: "250px",
                    ml: index > 0 ? 2 : 0,
                  }}
                >
                  <Box
                    component="img"
                    src={`${process.env.REACT_APP_ASSESSMENT_SERVICE}/uploads/assessment/solutions/${solution.image}`}
                  />

                  <Typography variant="h5" color="black" sx={{ my: 2 }}>
                    {solution.title}
                  </Typography>

                  <Typography
                    variant="body2"
                    color="grey.c59"
                    sx={{ maxHeight: "140px", overflow: "auto" }}
                  >
                    {solution.description}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    )
  );
};

export default observer(ResearchReportSolutions);
