import { Theme } from "@mui/material/styles";

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          "&.Mui-disabled": {
            backgroundColor: theme.palette.grey[400],
            color: theme.palette.primary.contrastText,
          },
          "&.custom-hover:hover": {
            backgroundColor: theme.palette.primary.main,
          },
          "&.no-hover:hover": {
            backgroundColor: theme.palette.background.default,
            cursor: "initial",
          },
        },
      },
    },
  };
}
