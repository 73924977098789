/* eslint-disable react-hooks/exhaustive-deps */
import { observer } from "mobx-react-lite";
import { Fragment, useContext, useEffect, useState } from "react";
import { StoreContext } from "../../stores/store.context";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { useParams, Link as RouterLink } from "react-router-dom";
import BackButton from "../../components/back-button";
import Breadcrumb from "../../components/breadcrumb";
import { Box, CircularProgress, Divider, LinearProgress, Link, Typography } from "@mui/material";
import MetaDxiLogo from "../../assets/images/meta-dxi.logo";
import { stages } from "../about/constants/stages";
import ResearchReportSolutions from "./components/research-report-solutions";
import ResearchReportRecommendation from "./components/research-report-recommendation";
import ResearchReportPillars from "./components/research-report-pillars";
import ResearchReportMaturityLevel from "./components/research-report-maturity-level";
import ResearchReportMaterials from "./components/research-report-materials";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import ButtonLoaderComponent from "../../components/button-loader";
import DownloadIcon from "@mui/icons-material/Download";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ResearchPDF } from "./components/research-pdf";
import { domToPng } from "modern-screenshot";

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

interface ChartData {
  labels: Array<string>;
  datasets: [
    {
      label: string;
      data: Array<number>;
      backgroundColor: string;
      borderColor: string;
      borderWidth: number;
    }
  ];
}

const ResearchReportPage: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker({ area: "getting-data" });
  const { breadcrumbStore, researchStore, themeStore } = useContext(StoreContext);
  const [chart, setChart] = useState<ChartData>();
  const [reportImage, setReportImage] = useState<any>();

  let { id, assessmentId } = useParams();

  useEffect(() => {
    breadcrumbStore.breadcrumb = [{ name: "Meus clientes" }];

    async function getReportData() {
      await trackPromise(
        researchStore.getResearchAssessmentData(id as string, assessmentId as string),
        "getting-data"
      );

      if (researchStore.researchAssessmentData) {
        var labels: Array<string> = [];
        var data: Array<number> = [];
        researchStore.researchAssessmentData.pillars!.forEach((researchAssessmentData) => {
          labels.push(researchAssessmentData.pillar!.pillarName);
          data.push(researchAssessmentData.average!);
        });
        setChart(() => {
          let newValue: ChartData = {
            labels: labels,
            datasets: [
              {
                label: "",
                data: data,
                backgroundColor: "transparent",
                borderColor: themeStore.theme.palette.error.main,
                borderWidth: 2,
              },
            ],
          };

          return newValue;
        });
      }
    }

    getReportData();
  }, [assessmentId, breadcrumbStore, id, researchStore, themeStore]);

  const generateReportImage = () => {
    var node = document.getElementById("report-image");
    if (node) {
      domToPng(node).then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "screenshot.png";
        link.href = dataUrl;
        setReportImage(dataUrl);
      });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (chart) {
        generateReportImage();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [chart]);

  return !promiseInProgress ? (
    <Box sx={{ mt: 6 }}>
      {breadcrumbStore.breadcrumb && (
        <Fragment>
          <Box sx={{ my: 3 }}>
            <Breadcrumb />
          </Box>
          <Box sx={{ my: 3 }}>
            <BackButton route={"previous"} />
          </Box>
        </Fragment>
      )}

      {researchStore.researchAssessmentData && (
        <Box sx={{ textAlign: "right" }}>
          <PDFDownloadLink
            document={
              <ResearchPDF
                researchData={researchStore.researchAssessmentData}
                chart={reportImage}
              />
            }
            fileName="dxi-report.pdf"
          >
            {({ blob, url, loading, error }) => {
              return loading ? (
                <CircularProgress />
              ) : (
                <ButtonLoaderComponent
                  loadingText="Processando..."
                  text={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <DownloadIcon sx={{ mr: 1 }} />
                      Baixar PDF do relatório
                    </Box>
                  }
                ></ButtonLoaderComponent>
              );
            }}
          </PDFDownloadLink>
        </Box>
      )}

      {researchStore.researchAssessmentData && (
        <Box
          id="report-image"
          sx={{
            mt: 4,
            borderRadius: "8px",
            backgroundColor: "background.default",
            display: "flex",
            px: 8,
            py: 6,
            flexDirection: "column",
          }}
        >
          {researchStore.researchAssessmentData.company && (
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography variant="h4" color="secondary.contrastText">
                {researchStore.researchAssessmentData.company.name}
              </Typography>

              <Link underline="none" component={RouterLink} to="/secure/home">
                <MetaDxiLogo />
              </Link>
            </Box>
          )}

          <Divider sx={{ my: 4 }} />

          <Typography variant="body1" color="grey.c59">
            Relatório de:
          </Typography>
          {researchStore.researchAssessmentData &&
            researchStore.researchAssessmentData.assessment && (
              <Fragment>
                <Typography variant="h2" color="primary.dark">
                  {researchStore.researchAssessmentData.assessment.name}
                </Typography>

                <Box sx={{ display: "flex" }}>
                  {stages.map((stage, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          backgroundColor:
                            researchStore.researchAssessmentData?.activeLevel === index + 1
                              ? "primary.light"
                              : "grey.cf2",
                          p: 1,
                          mt: 6,
                          flexGrow: 1,
                          display: "flex",
                          alignItems: "center",
                          borderTopLeftRadius: index === 0 ? "8px" : 0,
                          borderBottomLeftRadius: index === 0 ? "8px" : 0,
                          borderTopRightRadius: index + 1 === stages.length ? "8px" : 0,
                          borderBottomRightRadius: index + 1 === stages.length ? "8px" : 0,
                        }}
                      >
                        {stage.icon(
                          researchStore.researchAssessmentData?.activeLevel === index + 1
                            ? "white"
                            : "#A3A3A3"
                        )}
                        <Typography
                          variant="body1"
                          sx={{
                            ml: 1,
                            color:
                              researchStore.researchAssessmentData?.activeLevel === index + 1
                                ? "white"
                                : "grey.ca3",
                          }}
                        >
                          Nível {index + 1}: {stage.title}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>

                {researchStore.researchAssessmentData.activeLevel && (
                  <Box
                    sx={{
                      backgroundColor: "grey.cf2",
                      p: 2,
                      mt: 2,
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography variant="body2" sx={{ color: "grey.c59" }}>
                      {
                        researchStore.researchAssessmentData.assessment.levels![
                          researchStore.researchAssessmentData.activeLevel
                        ].summary
                      }
                    </Typography>
                  </Box>
                )}

                {chart && (
                  <Box sx={{ width: 500, height: 500, margin: "auto" }}>
                    <Radar
                      id="radar-chart"
                      data={chart}
                      options={{
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                        scales: {
                          r: {
                            max: 5,
                            min: 0,
                            angleLines: {
                              color: "#595959",
                            },
                            grid: {
                              color: "#595959",
                            },
                            ticks: {
                              stepSize: 1,
                              backdropColor: themeStore.theme.palette.warning.main,
                              backdropPadding: 4,
                              color: "white",
                            },
                            pointLabels: {
                              font: {
                                size: 11,
                              },
                            },
                          },
                        },
                      }}
                    />
                  </Box>
                )}

                <ResearchReportPillars />

                <ResearchReportRecommendation />

                <ResearchReportSolutions />

                <ResearchReportMaturityLevel />

                <Divider sx={{ my: 4 }} />

                <ResearchReportMaterials />
              </Fragment>
            )}
        </Box>
      )}
    </Box>
  ) : (
    <LinearProgress sx={{ position: "absolute", width: "100%", top: 0, left: 0 }} />
  );
};

export default observer(ResearchReportPage);
