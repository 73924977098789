import { ArrowBackOutlined } from "@mui/icons-material";
import { Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface BackButtonProps {
  route: string;
}

const BackButton: React.FC<BackButtonProps> = (props: BackButtonProps) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (props.route === "previous") {
      navigate(-1);
    } else {
      navigate(props.route);
    }
  };

  return (
    <Link
      variant="button"
      underline="none"
      onClick={handleGoBack}
      color="primary"
      sx={{
        fontWeight: 600,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        maxWidth: "100px",
        "&:hover": {
          fontWeight: 600,
        },
      }}
    >
      <ArrowBackOutlined sx={{ mr: 2 }} />

      <Typography variant="body2" sx={{ fontWeight: "inherit" }}>
        Voltar
        <span className="line"></span>
      </Typography>
    </Link>
  );
};

export default BackButton;
