import React, { ReactNode, useContext } from "react";
import { StoreContext } from "../stores/store.context";
import { ModalOpts } from "../models/modal.model";
import { observer } from "mobx-react-lite";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

const CustomModal: React.FC = () => {
  const { modalStore } = useContext(StoreContext);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "background.default",
    p: 4,
    boxShadow: `0px 11px 15px -7px rgb(255 255 255 / 20%), 0px 24px 38px 3px rgb(255 255 255 / 14%), 0px 9px 46px 8px rgb(255 255 255 / 12%);`,
    borderRadius: 1,
    display: "flex",
    flexDirection: "column",
  };

  const getModalSize = (opts: ModalOpts): string => {
    let modalSize = "md";
    if (opts.size) {
      switch (opts.size) {
        case "xs":
          modalSize = "25%";
          break;
        case "sm":
          modalSize = "45%";
          break;
        case "md":
          modalSize = "65%";
          break;
        case "lg":
          modalSize = "85%";
          break;
        case "full":
          modalSize = "100%";
          break;
      }
    }

    return modalSize;
  };

  return (
    <>
      {modalStore.modals.map((modal: any, index: number) => {
        return (
          <Modal
            key={index}
            open={modal.opened}
            onClose={modalStore.handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
          >
            <Fade in={modal.opened}>
              <Box
                sx={{
                  ...style,
                  width: getModalSize(modal.modalOpts || "md"),
                }}
              >
                <CloseOutlined
                  sx={{ cursor: "pointer", mb: 2, mt: -2, marginLeft: "auto" }}
                  onClick={modalStore.handleClose}
                />
                {modal.children as ReactNode}
              </Box>
            </Fade>
          </Modal>
        );
      })}
    </>
  );
};

export default observer(CustomModal);
