export const howItWorksStepsClientAdmin = [
  {
    title: "Você é nosso contato principal",
    description:
      "Você foi escolhido como “Cliente Master” neste projeto. A sua principal responsabilidade será distribuir internamente as avaliações e, também, atuar como o ponto focal junto ao Consultor Meta.",
  },
  {
    title: "Convide usuários para responder as avaliações",
    description:
      "O seu Consultor Meta disponibilizará uma pesquisa personalizada para a sua empresa. Convide outros membros especialistas da sua organização para responder às avaliações.",
  },
  {
    title: "Avaliações pelos especialistas",
    description:
      "Especialistas técnicos e outros convidados deverão responder às avaliações que lhes foram atribuídas.",
  },
  {
    title: "Consultor Meta finaliza a pesquisa",
    description:
      "Após a participação da empresa, teremos dados suficientes para finalizar a pesquisa e elaborar o diagnóstico.",
  },
  {
    title: "Diagnóstico personalizado",
    description:
      "Será gerado um relatório individual para cada uma das práticas. Sua empresa será categorizada de acordo com um dos cinco níveis de maturidade.",
  },
  {
    title: "Vamos juntos realizar a Transformação Digital!",
    description:
      "Com os dados em mãos, fica muito mais fácil entender as necessidades da sua empresa e pensar em soluções para promover a Transformação Digital.",
  },
];
