export const infoBoxes = [
  {
    title: "Discordo totalmente",
    description:
      "Ainda em estágio preliminar, quando não se pratica ou reconhece os benefícios da atividade dentro do time.",
  },
  {
    title: "Discordo parcialmente",
    description:
      "Quando não se pratica (ou pratica de forma incipiente) mas os benefícios começam a ser conhecidos.",
  },
  {
    title: "Nem concordo nem discordo",
    description:
      "O time reconhece os benefícios mas a atividade ainda ocorre de forma descentralizada/desestruturada.",
  },
  {
    title: "Concordo parcialmente",
    description:
      "O time reconhece os benefícios e a atividade já ocorre em toda organização, mas ainda em processo de evolução.",
  },
  {
    title: "Concordo totalmente",
    description:
      "O time possui domínio, lidera e itera constantemente a prática dentro da organização.",
  },
];
