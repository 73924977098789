import "../request.interceptor";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthenticatedLayout from "../layouts/authenticated-layout";
import HomePage from "../pages/home";
import AboutPage from "../pages/about";
import CompanyPage from "../pages/company";
import CompanyAddPage from "../pages/company/company-add";
import CompanyListPage from "../pages/company/company-list";
import CompanyManagePage from "../pages/company/company-manage";
import AxiosInterceptor from "../request.interceptor";
import ResearchAddPage from "../pages/research/research-add";
import ResearchViewPage from "../pages/research/research-view";
import AssessmentPage from "../pages/assessment/assessment-page";
import AssessmentIntro from "../pages/assessment/components/assessment-intro";
import AssessmentAnswerPage from "../pages/assessment/assessment-answer-page";
import ResearchReportPage from "../pages/research/research-report";
import ResearchPage from "../pages/research/";
import UserAddPage from "../pages/user/user-add";
import UserResearchInvite from "../pages/user/user-research-invite";
import ScrollToTop from "../components/scroll-top";

export function RoutesWithAnimation({ children }: any) {
  return (
    <AxiosInterceptor>
      <BrowserRouter>
        <ScrollToTop />

        <Routes>
          <Route path="/" element={<AuthenticatedLayout />}>
            <Route index element={<HomePage />} />
            <Route path="/secure/home" element={<HomePage />} />
            <Route path="/secure/about" element={<AboutPage />} />
            <Route path="/secure/company" element={<CompanyPage />}>
              <Route path="/secure/company/list" element={<CompanyListPage />} />
              <Route path="/secure/company/add" element={<CompanyAddPage />} />
              <Route path="/secure/company/:id/manage" element={<CompanyManagePage />} />
              <Route path="/secure/company/manage" element={<CompanyManagePage />} />
            </Route>
            <Route path="/secure/research" element={<ResearchPage />} />
            <Route path="/secure/research/add" element={<ResearchAddPage />} />
            <Route path="/secure/research/:id" element={<ResearchViewPage />} />
            <Route
              path="/secure/research/:researchId/assessment/:assessmentId/user/:userId/answer"
              element={<AssessmentAnswerPage />}
            />
            <Route
              path="/secure/research/:id/assessment/:assessmentId/report"
              element={<ResearchReportPage />}
            />
            <Route path="/secure/assessment" element={<AssessmentPage />} />
            <Route path="/secure/assessment/:id" element={<AssessmentIntro />} />
            <Route
              path="/secure/assessment/:assessmentId/answer"
              element={<AssessmentAnswerPage />}
            />
            <Route path="/secure/user/research/:id/add" element={<UserAddPage />} />
            <Route path="/secure/user/:id/invite" element={<UserResearchInvite />} />
          </Route>
        </Routes>
        {children}
      </BrowserRouter>
    </AxiosInterceptor>
  );
}
