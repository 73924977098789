import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Box,
  Typography,
  IconButton,
  TableBody,
  Link,
  Chip,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { Fragment, ReactElement, useContext } from "react";
import { StoreContext } from "../stores/store.context";
import { Link as RouterLink } from "react-router-dom";
import { Pagination } from "../models/pagination.model";
import NotFoundSearch from "./not-found-search";
import ButtonLoader from "./button-loader";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface GridTableProps {
  tableColumns: Array<{
    field: string;
    text?: string;
    link?: string;
    ordered?: { orderBy: string };
  }>;
  tableData: Pagination<Array<any>>;
  customNoData: ReactElement;
  colorScheme?: string;
  highlightFirstRow?: boolean;
  researchList?: boolean;
}

const GridTable: React.FC<GridTableProps> = (props: GridTableProps) => {
  const { gridStore, generalStore } = useContext(StoreContext);

  const getStatusChip = (status: string | boolean) => {
    return (
      <Chip
        label={
          <Typography variant="body2" fontWeight={600}>
            {props.researchList
              ? generalStore.getResearchStatusText(status)
              : generalStore.getStatusText(status)}
          </Typography>
        }
        sx={{
          alignSelf: "flex-start",
          backgroundColor: generalStore.getStatusBackground(status),
        }}
      />
    );
  };

  const generateDynamicUrl = (url: string, data: any): string => {
    var splited = url.split("/");

    let newUrl = "";
    splited.forEach((text) => {
      if (!text.includes(":")) {
        newUrl += text + "/";
      }
      Object.entries(data).forEach((field) => {
        if (":" + field[0] === text) {
          newUrl += field[1] + "/";
        }
      });
    });
    return newUrl;
  };

  const getField = (field: any) => {
    if ((field[0] === "finishedAt" || field[0] === "createdAt") && field[1]) {
      return new Date(field[1]).toLocaleDateString("pt-BR", {
        hour: "numeric",
        minute: "numeric",
      });
    }

    return field[1] && (field[1] as string) !== "" ? (field[1] as string) : "-";
  };

  const getGridBody = () => {
    return props.tableData && props.tableData.totalItems > 0 ? (
      props.tableData.paginatedData.map((data, index) => {
        return (
          <Fragment key={index}>
            <TableRow>
              {props.tableColumns.map((column, k) => {
                if (column.field !== "action") {
                  return Object.entries(data).map((field, j) => {
                    if (column.field === field[0]) {
                      return column.field !== "status" && column.field !== "enabled" ? (
                        <TableCell key={j} sx={{ backgroundColor: "background.default" }}>
                          <Typography
                            variant="body2"
                            color="secondary.contrastText"
                            fontWeight={500}
                          >
                            {getField(field)}
                          </Typography>
                        </TableCell>
                      ) : (
                        <TableCell key={j} sx={{ backgroundColor: "background.default" }}>
                          {getStatusChip(field[1] as string)}
                        </TableCell>
                      );
                    }
                    return false;
                  });
                }
                if (column.field === "action" && column.link) {
                  return (
                    <TableCell key={k} sx={{ backgroundColor: "background.default" }}>
                      <Link
                        variant="button"
                        component={RouterLink}
                        to={generateDynamicUrl(column.link, data)}
                        color="primary"
                      >
                        {column.text}
                      </Link>
                    </TableCell>
                  );
                }
                return false;
              })}
            </TableRow>
            {props.highlightFirstRow && index === 0 && (
              <TableRow>
                <TableCell
                  colSpan={12}
                  sx={{ padding: "0", backgroundColor: "transparent", border: "initial" }}
                >
                  <Box
                    sx={{ height: "1px", width: "90%", backgroundColor: "black", margin: "auto" }}
                  ></Box>
                </TableCell>
              </TableRow>
            )}
          </Fragment>
        );
      })
    ) : (
      <TableRow>
        <TableCell sx={{ backgroundColor: "background.default" }} colSpan={10}>
          {gridStore.searchStatus !== "all" || gridStore.searchText !== "" ? (
            <NotFoundSearch />
          ) : (
            props.customNoData
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ borderRadius: "8px" }}>
        {props.tableData.totalItems > 0 && (
          <TableHead
            sx={{ backgroundColor: props.colorScheme !== "grey" ? "primary.dark" : "grey.200" }}
          >
            <TableRow>
              {props.tableColumns.map((column, index) => {
                return (
                  <TableCell
                    key={index}
                    sx={{
                      backgroundColor: props.colorScheme !== "grey" ? "primary.dark" : "grey.200",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="body1"
                        color={props.colorScheme !== "grey" ? "white" : "black"}
                        sx={{ fontWeight: 700 }}
                      >
                        {column.field !== "action" && column.text}
                      </Typography>
                      {column.ordered && (
                        <IconButton
                          sx={{ ml: 2 }}
                          onClick={() =>
                            (gridStore.orderBy = {
                              ...gridStore.orderBy,
                              direction: gridStore.orderBy.direction === "asc" ? "desc" : "asc",
                              field: column.ordered!.orderBy,
                            })
                          }
                        >
                          <ArrowDropDownIcon
                            sx={{
                              color: props.colorScheme !== "grey" ? "white" : "black",
                              transform:
                                gridStore.orderBy.direction === "asc" &&
                                column.ordered!.orderBy === gridStore.orderBy.field
                                  ? "rotate(180deg)"
                                  : "rotate(0deg)",
                            }}
                          />
                        </IconButton>
                      )}
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {getGridBody()}
          {props.tableData && props.tableData.totalPages > 1 && (
            <>
              <TableRow>
                <TableCell
                  colSpan={10}
                  align="center"
                  sx={{ backgroundColor: "background.default", border: 0 }}
                >
                  <Typography variant="body2" color="secondary.lighter">
                    Exibindo {props.tableData.paginatedData.length} de {props.tableData.totalItems}{" "}
                    registros
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  colSpan={10}
                  align="center"
                  sx={{ backgroundColor: "background.default" }}
                >
                  {props.tableData.paginatedData.length < props.tableData.totalItems && (
                    <ButtonLoader
                      variant="text"
                      onClick={() => gridStore.currentPage++}
                      loadingText={"Carregando..."}
                      text={"Carregar mais"}
                      area="load-more"
                    />
                  )}
                </TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default observer(GridTable);
