interface IconDirectionProps {
  color?: string;
}

const IconEvolution = (props: IconDirectionProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.25259 16.3356C2.88567 16.7484 2.92285 17.3805 3.33564 17.7474C3.74842 18.1143 4.38049 18.0771 4.74741 17.6644L3.25259 16.3356ZM20 7H21C21 6.44772 20.5523 6 20 6V7ZM15.2 6C14.6477 6 14.2 6.44772 14.2 7C14.2 7.55228 14.6477 8 15.2 8V6ZM19 12C19 12.5523 19.4477 13 20 13C20.5523 13 21 12.5523 21 12H19ZM10.2996 10.7538L9.55221 11.4181L10.2996 10.7538ZM4.74741 17.6644L10.2996 11.4181L8.8048 10.0894L3.25259 16.3356L4.74741 17.6644ZM9.55221 11.4181L12.3603 14.5773L13.8551 13.2486L11.047 10.0894L9.55221 11.4181ZM14.6026 14.5773L20.7474 7.66437L19.2526 6.33563L13.1077 13.2485L14.6026 14.5773ZM20 6H15.2V8H20V6ZM19 7V12H21V7H19ZM12.3603 14.5773C12.957 15.2485 14.0059 15.2486 14.6026 14.5773L13.1077 13.2485C13.3066 13.0248 13.6563 13.0248 13.8551 13.2486L12.3603 14.5773ZM10.2996 11.4181C10.1007 11.6419 9.7511 11.6419 9.55221 11.4181L11.047 10.0894C10.4504 9.41812 9.40149 9.41811 8.8048 10.0894L10.2996 11.4181Z"
        fill={props.color ?? "white"}
      />
    </svg>
  );
};

export default IconEvolution;
