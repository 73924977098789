import { SearchOutlined } from "@mui/icons-material";
import {
  Grid,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { StoreContext } from "../stores/store.context";
import { SearchStatus } from "../models/search";

interface GridFilterPros {
  inputPlaceholder: string;
}

const GridFilter: React.FC<GridFilterPros> = (props: GridFilterPros) => {
  const { gridStore } = useContext(StoreContext);
  const [tempSearch, setTempSearch] = useState("");

  const searchBy = (event: any) => {
    gridStore.currentPage = 1;
    gridStore.searchText = event ? (event.target.value as string) : "";
  };

  return (
    <Grid container justifyContent={"space-between"} alignItems="center">
      <Grid item xs={12} sm={8}>
        <TextField
          value={tempSearch}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "40px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "border.main",
              color: "text.primary",
            },
            ".MuiOutlinedInput-input::-webkit-input-placeholder": {
              color: "secondary.lighter",
              opacity: 1,
            },
          }}
          onChange={(e) => setTempSearch(e.target.value)}
          onKeyDown={(e) => (e.key === "Enter" ? searchBy(e) : null)}
          fullWidth
          placeholder={props.inputPlaceholder}
          InputProps={{
            startAdornment: <SearchOutlined sx={{ color: "grey.700" }} />,
            endAdornment: gridStore.searchText && (
              <IconButton
                onClick={() => {
                  searchBy(null);
                  setTempSearch("");
                }}
              >
                <CancelOutlinedIcon sx={{ color: "primary.main" }} />
              </IconButton>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
              borderWidth: "1px",
              borderColor: "border.main",
              borderStyle: "solid",
              ".MuiSelect-icon": {
                color: "black",
              },
            },
          }}
        >
          <InputLabel id="userStatus">Status</InputLabel>
          <Select
            labelId="userStatus"
            value={gridStore.searchStatus}
            label="Status"
            onChange={(e) => (gridStore.searchStatus = e.target.value as SearchStatus)}
          >
            <MenuItem value={"all"}>Todos</MenuItem>
            <MenuItem value={"active"}>Ativo</MenuItem>
            <MenuItem value={"inactive"}>Inativo</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default observer(GridFilter);
