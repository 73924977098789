/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { StoreContext } from "../../stores/store.context";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import Breadcrumb from "../../components/breadcrumb";
import GridFilter from "../../components/grid-filter";
import GridTable from "../../components/grid-table";
import CircularLoader from "../../components/circular-loader";
import { useLocation, useNavigate } from "react-router-dom";
import NoData from "../../components/no-data";
import AddIcon from "@mui/icons-material/Add";

const CompanyListPage: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker({ area: "loading-grid-data" });
  const { breadcrumbStore, companyStore, gridStore } = useContext(StoreContext);

  const navigate = useNavigate();

  const tableColumns = [
    { field: "name", text: "Empresa", ordered: { orderBy: "name" } },
    { field: "status", text: "Status" },
    { field: "lastUserAccessAt", text: "Último acesso" },
    { field: "action", text: "Gerenciar cliente", link: "/secure/company/:id/manage" },
  ];

  const location = useLocation();

  const getCompanies = async () => {
    var filter = JSON.stringify({
      name: gridStore.searchText ? gridStore.searchText : "",
      status: gridStore.searchStatus !== "all" ? gridStore.searchStatus : "",
    });

    await trackPromise(
      companyStore.listCompanies(
        gridStore.currentPage,
        gridStore.limit,
        gridStore.orderBy.field !== "" ? gridStore.orderBy.field : "name",
        gridStore.orderBy.direction,
        filter
      ),
      gridStore.currentPage > 1 ? "load-more" : "loading-grid-data"
    );
  };

  useEffect(() => {
    gridStore.currentPage = 1;
    gridStore.searchText = "";
    gridStore.searchStatus = "all";
    gridStore.orderBy = { direction: "asc", field: "" };
  }, [location.pathname]);

  useEffect(() => {
    breadcrumbStore.breadcrumb = [{ name: "Meus clientes" }];
    getCompanies();
  }, [
    breadcrumbStore,
    companyStore,
    gridStore.currentPage,
    gridStore.limit,
    gridStore.orderBy.direction,
    gridStore.orderBy.field,
    gridStore.searchText,
    gridStore.searchStatus,
  ]);

  return (
    <Box sx={{ mt: 6 }}>
      {breadcrumbStore.breadcrumb && (
        <Box sx={{ my: 3 }}>
          <Breadcrumb />
        </Box>
      )}

      <Grid container sx={{ mt: 6 }}>
        <Grid item xs={12}>
          <Typography variant="h2" sx={{ color: "primary.dark" }}>
            Gerencie informações dos clientes
          </Typography>
          <Typography variant="h3" color="grey.c59" sx={{ mt: 2 }}>
            Atualize dados e usuários, crie e acompanhe as pesquisas das empresas cadastradas.
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ py: 2, mt: 2 }}>
          <GridFilter inputPlaceholder="Pesquise pelo nome da empresa" />
        </Grid>

        <Grid
          container
          sx={{
            mt: 4,
            borderRadius: "10px",
            backgroundColor: "background.default",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {companyStore.companies && !promiseInProgress && (
            <GridTable
              tableColumns={tableColumns}
              tableData={companyStore.companies}
              customNoData={
                <NoData
                  text={
                    "Você ainda não possui nenhuma empresa cadastrada, cadastre para ter acesso a esse painel."
                  }
                  button={
                    <Button variant="contained" onClick={() => navigate("/secure/company/add")}>
                      <AddIcon sx={{ mr: 2 }} /> Cadastrar empresa
                    </Button>
                  }
                />
              }
            />
          )}

          {promiseInProgress && <CircularLoader />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default observer(CompanyListPage);
