import { Box, LinearProgress, Typography } from "@mui/material";

const TokenValidation: React.FC = () => {
  return (
    <Box
      sx={{
        height: "200px",
        width: "200px",
        position: "absolute",
        top: "50%",
        marginTop: "-100px",
        left: "50%",
        marginLeft: "-100px",
        p: 4,
        boxShadow: `0px 11px 15px -7px rgb(255 255 255 / 20%), 0px 24px 38px 3px rgb(255 255 255 / 14%), 0px 9px 46px 8px rgb(255 255 255 / 12%);`,
      }}
    >
      <LinearProgress />
      <Typography variant="body2" sx={{ mt: 2 }}>
        Validando acesso...
      </Typography>
    </Box>
  );
};

export default TokenValidation;
