import { ToastOptions } from "../models/toast.model";
import { makeAutoObservable } from "mobx";

export class ToastStore {
  private _toast: Array<ToastOptions>;

  constructor() {
    makeAutoObservable(this);
    this._toast = [];
  }

  pushToast = (toast: ToastOptions) => {
    this._toast.push(toast);
    setTimeout(() => {
      this.removeToast();
    }, 5000);
  };

  removeToast = () => this._toast.shift();

  get toast() {
    return this._toast;
  }
}
