import { observer } from "mobx-react-lite";
import AssessmentsList from "../../assessment/components/assessment-list";
import { useContext, useEffect } from "react";
import { StoreContext } from "../../../stores/store.context";
import CircularLoader from "../../../components/circular-loader";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import { Box } from "@mui/material";

const ResearchAssessmentListUser: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker({ area: "loading-data" });
  const { userStore, researchStore } = useContext(StoreContext);

  useEffect(() => {
    const getUserResearchAssessmentData = async () =>
      await trackPromise(
        researchStore.getResearchAssessmentsByUser(
          researchStore.lastAccessedResearch?._id!,
          userStore.user!.id
        ),
        "loading-data"
      );

    getUserResearchAssessmentData();
  }, [researchStore, userStore.user]);

  return researchStore.researchAssessments && !promiseInProgress ? (
    <Box sx={{ display: "flex", gap: "16px", "& > :first-of-type": { marginLeft: 0 } }}>
      <AssessmentsList
        cardCanBeSelected={false}
        hasHeader={false}
        direction="horizontal"
        assessmentsByResearch={true}
        hasProgress={true}
      />
    </Box>
  ) : (
    <CircularLoader />
  );
};
export default observer(ResearchAssessmentListUser);
