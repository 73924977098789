import { observer } from "mobx-react-lite";
import { Fragment, useContext, useEffect } from "react";
import { StoreContext } from "../../stores/store.context";
import { Box, Divider, Typography } from "@mui/material";
import BackButton from "../../components/back-button";
import Breadcrumb from "../../components/breadcrumb";
import AssessmentsList from "../assessment/components/assessment-list";
import { DXIRoles } from "../../enums/dxi_roles.enum";
import ResearchCombo from "../research/components/research-combo";
import { usePromiseTracker } from "react-promise-tracker";
import CircularLoader from "../../components/circular-loader";

const AssessmentPage: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker({ area: "loading-research-assessments" });
  const { breadcrumbStore, userStore, researchStore } = useContext(StoreContext);

  useEffect(() => {
    breadcrumbStore.breadcrumb = [{ name: "Avaliações" }];
  }, [breadcrumbStore]);

  return (
    <Box sx={{ mt: 6 }}>
      {breadcrumbStore.breadcrumb && (
        <Fragment>
          <Box sx={{ my: 3 }}>
            <Breadcrumb />
          </Box>
          <Box sx={{ my: 3 }}>
            <BackButton route={"previous"} />
          </Box>
        </Fragment>
      )}

      <Box sx={{ mt: 2, mb: 4 }}>
        <Typography variant="h2" color="primary.dark">
          Avaliações
        </Typography>
        <Typography variant="h3" color="grey.c59" sx={{ mt: 2 }}>
          {userStore.user && userStore.userRole === DXIRoles.DXI_CONSULTANT
            ? "Visualize todas as avaliações disponíveis para análise do nível de maturidade digital"
            : "Responda as avaliações disponíveis e analise o nível de maturidade digital da sua empresa"}
        </Typography>
      </Box>

      <Box
        sx={{
          borderRadius: "8px",
          backgroundColor: "background.default",
          px: 8,
          pt: 1,
        }}
      >
        {userStore.user && userStore.userRole === DXIRoles.DXI_CONSULTANT ? (
          <AssessmentsList
            cardCanBeSelected={false}
            hasHeader={false}
            direction="vertical"
            cardCanBeViewed={true}
          />
        ) : (
          <Box>
            <Box sx={{ mt: 4 }}>
              <ResearchCombo loadByUser="logged" />
            </Box>

            <Divider sx={{ my: 6 }} />

            {!promiseInProgress &&
              researchStore.selectedResearch &&
              researchStore.researchAssessments && (
                <Box>
                  <Typography variant="h4" color="primary.dark">
                    {researchStore.selectedResearch.name}
                  </Typography>

                  {researchStore.researches && (
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color="grey.c59"
                      sx={{ mb: 4, mt: 1 }}
                    >
                      {researchStore.researchAssessments.length} avaliações disponíveis
                    </Typography>
                  )}
                  {researchStore.researchAssessments && (
                    <AssessmentsList
                      cardCanBeSelected={false}
                      hasHeader={false}
                      direction="vertical"
                      cardCanBeViewed={false}
                      assessmentsByResearch={true}
                      hasProgress={true}
                    />
                  )}
                </Box>
              )}

            {promiseInProgress && <CircularLoader />}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default observer(AssessmentPage);
