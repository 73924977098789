import IconDiamond from "../../../assets/images/icon-diamond";
import IconDirection from "../../../assets/images/icon-direction";
import IconEvolution from "../../../assets/images/icon-evolution";
import IconLoading from "../../../assets/images/icon-loading";
import IconStar from "../../../assets/images/icon-star";

export const stages = [
  {
    bg: "#0075E1",
    icon: (color: string) => <IconLoading color={color} />,
    title: "Iniciante",
    min: 0,
    max: 1,
    description:
      "A estrutura para implantação da maioria das práticas digitais ainda é inexistente. Além disso, não existe um planejamento direcionado para evolução de produtos. A organização dedica seus esforços apenas à resolução de problemas.",
  },
  {
    bg: "#0050D6",
    icon: (color: string) => <IconDirection color={color} />,
    title: "Explorador",
    min: 1.1,
    max: 2,
    description:
      "A organização não tem um plano claro para as práticas da Transformação Digital e, embora as funções de TI e Negócios possam colaborar de tempos em tempos e de maneira ad hoc, as iniciativas de integração ainda surgem de forma incipiente.",
  },
  {
    bg: "#0A34D6",
    icon: (color: string) => <IconStar color={color} />,
    title: "Aprendiz",
    min: 2.1,
    max: 3,
    description:
      "Reconhecem a necessidade e o valor das tecnologias digitais, utilizando um roteiro de projetos em estágios iniciais. No entanto, unidades de negócios, funções e regiões ainda estão operando em silos funcionais, quando deveriam fazer parte de um esforço coordenado em toda a organização.",
  },
  {
    bg: "#072AB1",
    icon: (color: string) => <IconEvolution color={color} />,
    title: "Evoluindo",
    min: 3.1,
    max: 4,
    description:
      "Existe uma mentalidade digital robusta e o caminho de transformação é claro e definido. As plataformas de talentos e dados necessários para a digitalização já foram criadas. Várias iniciativas digitais foram bem-sucedidas e novos serviços e negócios digitais também foram lançados.",
  },
  {
    bg: "#00164F",
    icon: (color: string) => <IconDiamond color={color} />,
    title: "Líder",
    min: 4.1,
    max: 5,
    description:
      "O digital está incorporado em toda a organização, onde fatores como inovação, P&D, entrega de valor, adoção de tecnologias digitais, desenvolvimento de estratégias e operações diárias são impulsionados. A empresa supera seus pares nas principais métricas digitais e vê a Transformação Digital como um impulsionador essencial dos negócios.",
  },
];
