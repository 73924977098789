import { Theme } from '@mui/material/styles'

export default function Input(theme: Theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: 'transparent',
        },
        input: {
          '[data-autocompleted]': {
            backgroundColor: 'transparent !important',
          },
          '&:-webkit-autofill': {
            transition: 'background-color 600000s 0s, color 600000s 0s',
          },
          '&:-webkit-autofill:focus': {
            transition: 'background-color 600000s 0s, color 600000s 0s',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
          textUnderlineOffset: '8px',
          textDecorationColor: 'inherit',
        },
      },
    },
  }
}
