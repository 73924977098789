export const errHandler = (e: any) => {
  if (e.response) {
    if (e.response.data.additionalProperties && e.response.data.additionalProperties.message) {
      throw e.response.data.additionalProperties.message;
    }
  }

  if (e && e.code) {
    if (e.code !== "ERR_NETWORK" && e.code !== "ERR_BAD_RESPONSE") {
      throw e.response.data;
    }
  }
  throw e.message;
};
