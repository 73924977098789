import { observer } from "mobx-react-lite";
import { Fragment, useContext, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../../../stores/store.context";
import Banner from "../../../assets/images/banner-home-user.png";
import GirlARGlasses from "../../../assets/images/girl-augmented-reality-glasses.png";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import CircularLoader from "../../../components/circular-loader";
import HowItWorks from "../../about/components/how-it-works";
import { DXIRoles } from "../../../enums/dxi_roles.enum";
import ResearchAssessmentListUser from "../../research/components/research-assessment-list-user";

const HomeUser: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker({ area: "loading-data" });
  const { userStore, companyStore, researchStore } = useContext(StoreContext);

  const navigate = useNavigate();

  useEffect(() => {
    const getCompanyByUser = async () =>
      await trackPromise(companyStore.getCompanyByUser(userStore.user!.id), "loading-data");

    const getLastAccessedResearch = async () => {
      await trackPromise(researchStore.getLastAccessedResearch(userStore.user!.id), "loading-data");
    };

    if (userStore.user) {
      getCompanyByUser();
      getLastAccessedResearch();
    }
  }, [companyStore, researchStore, userStore.user]);

  return (
    <Fragment>
      <Box
        sx={{
          backgroundImage: `url("${Banner}")`,
          minHeight: { xs: "420px", xl: "570px" },
          width: "100vw",
          marginLeft: "calc(-50vw + 50%)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      />

      {!promiseInProgress ? (
        <Box
          sx={{
            mt: 4,
            borderRadius: "8px",
            borderColor: "grey.c59",
            borderWidth: "1px",
            borderStyle: "solid",
            p: 4,
            display: "flex",
            alignItems: "center",
          }}
        >
          {companyStore.company && (
            <Box sx={{ width: "160px", maxWidth: "160px", maxHeight: "160px" }}>
              <Box
                sx={{ objectFit: "cover", width: "120px", margin: "auto" }}
                component="img"
                src={
                  process.env.REACT_APP_COMPANY_SERVICE +
                  "/uploads/company/" +
                  companyStore.company.logo
                }
              />
            </Box>
          )}

          <Typography variant="h1" color="primary.dark" sx={{ ml: 2 }}>
            Olá, {userStore.userFullName}!
          </Typography>
        </Box>
      ) : (
        <CircularLoader />
      )}

      <Fragment>
        <Box sx={{ mt: 8, alignItems: "center" }}>
          <Box
            sx={{
              borderRadius: "8px",
              backgroundColor: "background.default",
              display: "flex",
              mt: 4,
              px: 8,
              py: 6,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", mr: 6, mt: 4 }}>
              <Typography variant="h2" color="primary.dark" sx={{ maxWidth: "375px" }}>
                Sobre o Meta DXi
              </Typography>

              <Typography variant="body1" color="grey.c59" sx={{ my: 2 }}>
                O Meta DXi é uma plataforma exclusiva, desenvolvida pela Meta para que você possa
                avaliar o estágio de Maturidade Digital da sua empresa a partir de quatro pilares:
                Estratégia, Cultura, Processos e Tecnologia & Ferramentas.
              </Typography>

              <Typography variant="body1" color="grey.c59">
                Após a realização da pesquisa, você terá em mãos dados fundamentais para pensar a
                jornada do seu negócio de forma mais inteligente e otimizada e estará preparado para
                tomar decisões de forma analítica e estratégica.
              </Typography>

              <Typography variant="body1" fontWeight={700} sx={{ color: "grey.700", mt: 2 }}>
                A Transformação Digital do seu negócio já começou e a Meta é sua parceira nessa
                jornada!
              </Typography>

              <Button
                onClick={() => navigate("/secure/company//manage")}
                variant="contained"
                sx={{ mt: 4, alignSelf: "self-start" }}
              >
                Gerenciar pesquisas
              </Button>
            </Box>

            <Box component="img" src={GirlARGlasses} sx={{ alignSelf: "center" }} />
          </Box>
        </Box>
      </Fragment>

      {userStore.userRole === DXIRoles.DXI_CLIENT_ADMIN && (
        <Fragment>
          <Box sx={{ mt: 12 }}>
            <Typography variant="h2" color="primary.dark">
              Como funciona
            </Typography>

            <Typography variant="body1" color="grey.c59" sx={{ mt: 2 }}>
              Confira o passo a passo da sua jornada de Transformação Digital.
            </Typography>
          </Box>

          <Box sx={{ mt: 4 }}>
            <HowItWorks />
          </Box>
        </Fragment>
      )}

      <Fragment>
        <Box sx={{ mt: 8, mb: 3 }}>
          <Typography variant="h2" color="primary.dark">
            Avaliações
          </Typography>

          {researchStore.lastAccessedResearch ? (
            <Typography variant="body1" color="grey.c59" sx={{ mt: 2 }}>
              Você está visualizando as avaliações disponíveis na pesquisa "
              {researchStore.lastAccessedResearch.name}"
            </Typography>
          ) : (
            <Typography variant="body1" color="grey.c59" sx={{ mt: 2 }}>
              Nenhuma pesquisa recente acessada
            </Typography>
          )}
        </Box>

        {researchStore.lastAccessedResearch && <ResearchAssessmentListUser />}
      </Fragment>
    </Fragment>
  );
};

export default observer(HomeUser);
