export interface MetaUserData {
  company: string;
  email: string;
  given_name: string;
  id: string;
  metaUUID: string;
  firstName: string;
  lastName: string;
  origin: string;
  roles: Array<string>;
  username: string;
}

export class UserData {
  id?: string;
  email: string;
  firstName: string;
  lastName: string;
  enabled?: boolean;

  constructor() {
    this.email = "";
    this.firstName = "";
    this.lastName = "";
    this.enabled = false;
  }
}
