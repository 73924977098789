import CircleIcon from "@mui/icons-material/Circle";
import { useContext } from "react";
import { StoreContext } from "../stores/store.context";

const StatusCircle = (props: { status: string | boolean }) => {
  const { generalStore } = useContext(StoreContext);

  return (
    <CircleIcon
      sx={{ mr: 1, color: generalStore.getStatusColor(props.status), fontSize: "14px" }}
    />
  );
};

export default StatusCircle;
