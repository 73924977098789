import { Box, Typography } from "@mui/material";
import NotFoundSearchIcon from "../assets/images/not-found-search-icon";

const NotFoundSearch: React.FC = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          mb: 4,
        }}
      >
        <NotFoundSearchIcon />
        <Typography variant="body1" color="secondary.lighter">
          Não encontramos nenhum resultado.
        </Typography>
      </Box>
    </>
  );
};

export default NotFoundSearch;
