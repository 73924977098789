import { Theme } from "@mui/material/styles";

export default function Accordion(theme: Theme) {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          "&.Mui-expanded": {
            margin: 0,
          },
          "&:before": {
            display: "none",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "initial",
          "&.Mui-expanded": {
            height: "35px",
            minHeight: "initial",
          },
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(180deg)",
          },
        },
        content: {
          marginLeft: 2,
          order: 1,
        },
        expandIconWrapper: {
          transform: "rotate(0deg)",
          order: 0,
          color: "white",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "initial",
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: {},
      },
    },
  };
}
