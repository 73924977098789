import * as Yup from "yup";

export const companySchema = Yup.object().shape({
  name: Yup.string().max(255).required("Campo obrigatório"),
  cnpj: Yup.string()
    .required("O CNPJ é obrigatório")
    .matches(
      /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/,
      "O CNPJ deve estar no formato XX.XXX.XXX/XXXX-XX"
    ),
  sector: Yup.string().required("O setor da empresa é obrigatório"),
  size: Yup.string().required("O tamanho da empresa é obrigatório"),
  website: Yup.string().matches(
    /^(?:https?:\/\/|s?ftps?:\/\/)?(?!www | www\.)[A-Za-z0-9_-]+\.+[A-Za-z0-9.\\/%&=\\?_:;-]+$/,
    {
      message: "Formato inválido",
      excludeEmptyString: true,
    }
  ),
  addAllowedDomain: Yup.string().matches(/^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,7}$/, {
    message: "Formato inválido. Exemplo válido: exemplo.com.br",
    excludeEmptyString: true,
  }),
});
