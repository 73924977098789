import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Button, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../../../stores/store.context";

const AssessmentDoneModal: React.FC = () => {
  const { modalStore } = useContext(StoreContext);
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: 1,
      }}
    >
      <CheckCircleOutlineIcon sx={{ fontSize: "64px", color: "success.dark", mb: 3 }} />

      <Typography variant="h4" color="black">
        Obrigado pela sua participação!
      </Typography>

      <Typography variant="body2" color="grey.c59">
        Suas respostas foram enviadas com sucesso.
      </Typography>

      <Button
        sx={{ my: 3 }}
        variant="contained"
        onClick={() => {
          navigate("/secure/assessment/");
          modalStore.handleClose();
        }}
      >
        Ir para avaliações
      </Button>
    </Box>
  );
};

export default observer(AssessmentDoneModal);
