import { IMaskInput } from 'react-imask'
import { forwardRef } from 'react'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
  mask: string
}

const MaskedInput = forwardRef<HTMLElement, CustomProps>(function MaskedInput(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask={props.mask}
      definitions={{
        '#': /[1-9]/,
      }}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  )
})

export default MaskedInput
