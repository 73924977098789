import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Fragment, useContext, useEffect } from "react";
import { StoreContext } from "../../../stores/store.context";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import GridTable from "../../../components/grid-table";
import CircularLoader from "../../../components/circular-loader";
import NoData from "../../../components/no-data";
import { DXIRoles } from "../../../enums/dxi_roles.enum";

const CompanyUsersList: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker({ area: "loading-grid-data" });
  const { gridStore, companyStore, userStore } = useContext(StoreContext);

  const noDataText =
    userStore.userRole === DXIRoles.DXI_CLIENT_ADMIN
      ? "Essa empresa ainda não possui usuários convidados. Crie uma pesquisa para iniciar."
      : "Essa empresa ainda não possui usuários convidados. Aguarde o Cliente Master convidá-los. ";

  const tableColumns: any = [
    { field: "firstName", text: "Nome", ordered: { orderBy: "name" } },
    { field: "lastName", text: "Sobrenome" },
    { field: "email", text: "Email" },
  ];

  if (userStore.userRole === DXIRoles.DXI_CLIENT_ADMIN) {
    tableColumns.push({
      field: "action",
      text: "Convidar para pesquisa",
      link: `/secure/user/:id/invite?company=${companyStore.company._id!}`,
    });
  }

  useEffect(() => {
    const getCompanyUsers = async () => {
      if (companyStore.company) {
        await trackPromise(
          companyStore.listCompanyUsers(
            companyStore.company._id!,
            gridStore.currentPage,
            gridStore.limit,
            gridStore.orderBy.field !== "" ? gridStore.orderBy.field : "firstName",
            gridStore.orderBy.direction
          ),
          gridStore.currentPage > 1 ? "load-more" : "loading-grid-data"
        );
      }
    };

    getCompanyUsers();
  }, [
    gridStore.currentPage,
    gridStore.limit,
    gridStore.searchText,
    companyStore.company,
    companyStore,
    gridStore.orderBy.field,
    gridStore.orderBy.direction,
  ]);

  return (
    <Fragment>
      <Grid
        container
        sx={{
          mt: 4,
          borderRadius: "10px",
          backgroundColor: "background.default",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {companyStore.companyUsers && !promiseInProgress && (
          <GridTable
            tableColumns={tableColumns}
            tableData={companyStore.companyUsers}
            colorScheme="grey"
            customNoData={<NoData text={noDataText} />}
            highlightFirstRow={true}
          />
        )}

        {promiseInProgress && <CircularLoader />}
      </Grid>
    </Fragment>
  );
};

export default observer(CompanyUsersList);
