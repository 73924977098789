import { observer } from "mobx-react-lite";
import { Fragment, useContext, useEffect, useState } from "react";
import BackButton from "../../components/back-button";
import Breadcrumb from "../../components/breadcrumb";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { StoreContext } from "../../stores/store.context";
import { Box, Divider, LinearProgress, Typography } from "@mui/material";
import ResearchCombo from "../research/components/research-combo";
import CircularLoader from "../../components/circular-loader";
import AssessmentsList from "../assessment/components/assessment-list";
import ButtonLoader from "../../components/button-loader";
import { useNavigate, useParams } from "react-router-dom";
import { action } from "mobx";

const UserResearchInvite: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker({ area: "loading-research-assessments" });
  const { breadcrumbStore, researchStore, companyStore, assessmentStore, toastStore } =
    useContext(StoreContext);
  const [redirecting, setRedirecting] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    action(() => (assessmentStore.selectedAssessments = []))();
  }, []);

  useEffect(() => {
    if (companyStore.company) {
      breadcrumbStore.breadcrumb = [
        {
          name: "Gerenciar pesquisas",
          route: `/secure/company/${companyStore.company._id}/manage/`,
        },
        { name: "Convidar usuário para avaliação" },
      ];
    }
  }, [assessmentStore, breadcrumbStore, companyStore.company, researchStore.researchAssessments]);

  const handleSave = async () => {
    if (id && researchStore.selectedResearch) {
      const inviteUser = await trackPromise(
        researchStore.inviteUserToResearch(
          researchStore.selectedResearch._id!,
          id,
          assessmentStore.selectedAssessments
        ),
        "invite-user"
      );

      if (inviteUser) {
        toastStore.pushToast({ message: "Convite enviado com sucesso!", type: "success" });
        setRedirecting(true);
        setTimeout(() => {
          navigate("/secure/research/" + researchStore.selectedResearch?._id);
        }, 3000);
      }
    }
  };

  return (
    <Box sx={{ mt: 6 }}>
      {breadcrumbStore.breadcrumb && (
        <Fragment>
          <Box sx={{ my: 3 }}>
            <Breadcrumb />
          </Box>
          <Box sx={{ my: 3 }}>
            <BackButton route={"previous"} />
          </Box>
        </Fragment>
      )}

      <Box sx={{ mt: 2, mb: 4 }}>
        <Typography variant="h2" color="primary.dark">
          Convidar usuário para avaliação
        </Typography>
      </Box>

      <Box
        sx={{
          mt: 4,
          borderRadius: "8px",
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          px: 8,
          py: 6,
        }}
      >
        <ResearchCombo />

        {!promiseInProgress && researchStore.selectedResearch && researchStore.research && (
          <Box>
            <Divider sx={{ my: 6 }} />

            <Typography variant="h4" color="primary.dark">
              {researchStore.selectedResearch.name}
            </Typography>

            {researchStore.researches && (
              <Typography variant="body2" fontWeight={500} color="grey.c59" sx={{ mb: 4, mt: 1 }}>
                {researchStore.research.assessments!.length} avaliações disponíveis
              </Typography>
            )}
            {researchStore.research && (
              <AssessmentsList
                cardCanBeSelected={true}
                hasHeader={false}
                direction="vertical"
                cardCanBeViewed={false}
                assessmentsByResearch={true}
                hasProgress={false}
                useResearchData={true}
              />
            )}
            {researchStore.research && (
              <Box sx={{ textAlign: "center", mt: 2 }}>
                {!redirecting ? (
                  <ButtonLoader
                    style={{ display: "flex", margin: "auto" }}
                    loadingText="Processando..."
                    text="Enviar convite"
                    type="button"
                    area="invite-user"
                    disabled={assessmentStore.selectedAssessments.length <= 0}
                    onClick={handleSave}
                  />
                ) : (
                  <Fragment>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Aguarde, redirecionando para pesquisa
                    </Typography>

                    <LinearProgress sx={{ width: "300px", margin: "auto" }} />
                  </Fragment>
                )}
              </Box>
            )}
          </Box>
        )}

        {promiseInProgress && <CircularLoader />}
      </Box>
    </Box>
  );
};

export default observer(UserResearchInvite);
