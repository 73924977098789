import { UserData } from "../../../models/user.model";
import { CompanyStore } from "../../../stores/company.store";
import { UserStore } from "../../../stores/user.store";

export const getInitialValues = (
  companyStore: CompanyStore,
  userStore: UserStore,
  edit: boolean | undefined
) => {
  const initialValues = {
    name: edit && companyStore.company ? companyStore.company.name : "",
    cnpj: edit && companyStore.company ? companyStore.company.cnpj : "",
    size: edit && companyStore.company ? companyStore.company.size : "",
    sector: edit && companyStore.company ? companyStore.company.sector : "",
    otherSector: edit && companyStore.company ? companyStore.company.otherSector : "",
    website:
      edit && companyStore.company && companyStore.company.website
        ? companyStore.company.website
        : "",
    addAllowedDomain: "",
    allowedDomains: edit && companyStore.company ? companyStore.company.allowedDomains : [],
    createdBy: {
      id: userStore.user!.id,
      email: userStore.user!.email,
      firstName: userStore.user!.firstName,
      lastName: userStore.user!.lastName,
    },
    keyUser: new UserData(),
  };

  return initialValues;
};
