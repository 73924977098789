import React from "react";
import ThemeStore from "./theme.store";
import ModalStore from "./modal.store";
import { UserStore } from "./user.store";
import { BreadcrumbStore } from "./breadcrumb.store";
import { CompanyStore } from "./company.store";
import { ToastStore } from "./toast.store";
import { GridStore } from "./grid.store";
import { KeycloakStore } from "./keycloak.store";
import { ResearchStore } from "./research.store";
import { GeneralStore } from "./general.store";
import { AssessmentStore } from "./assessment.store";

export interface IStoreContext {
  themeStore: ThemeStore;
  modalStore: ModalStore;
  userStore: UserStore;
  breadcrumbStore: BreadcrumbStore;
  companyStore: CompanyStore;
  toastStore: ToastStore;
  gridStore: GridStore;
  keycloakStore: KeycloakStore;
  generalStore: GeneralStore;
  researchStore: ResearchStore;
  assessmentStore: AssessmentStore;
}

const toastStore = new ToastStore();
const themeStore = new ThemeStore();
const modalStore = new ModalStore();
const userStore = new UserStore();
const breadcrumbStore = new BreadcrumbStore();
const gridStore = new GridStore();
const keycloakStore = new KeycloakStore();
const generalStore = new GeneralStore();
const companyStore = new CompanyStore(toastStore);
const researchStore = new ResearchStore(toastStore);
const assessmentStore = new AssessmentStore(toastStore);

export const StoreContext = React.createContext<IStoreContext>({
  themeStore,
  modalStore,
  userStore,
  breadcrumbStore,
  companyStore,
  toastStore,
  gridStore,
  keycloakStore,
  generalStore,
  researchStore,
  assessmentStore,
});
