import { GlobalStyles as MUIGlobalStyles, Theme } from "@mui/material";

export default function GlobalStyles(props: { theme: Theme }) {
  const globalStyles = (
    <MUIGlobalStyles
      styles={{
        "*": {
          boxSizing: "border-box",
        },
        ".MuiPopover-paper": {
          backgroundColor: `${props.theme.palette.background.default} !important`,
        },
        ".MuiOutlinedInput-root": {
          border: "initial !important",
        },
        ".MuiOutlinedInput-root:hover": {
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "#0000003b !important",
          },
        },
        form: {
          width: "100%",
        },
        html: {
          margin: 0,
          padding: 0,
          width: "100%",
          height: "100%",
          WebkitOverflowScrolling: "touch",
        },
        body: {
          margin: 0,
          padding: 0,
          width: "100%",
          height: "100%",
          background: props.theme.palette.background.paper,
          overflowX: "hidden",
        },
        "#root": {
          width: "100%",
          height: "100%",
        },
        input: {
          "&[type=number]": {
            MozAppearance: "textfield",
            "&::-webkit-outer-spin-button": {
              margin: 0,
              WebkitAppearance: "none",
            },
            "&::-webkit-inner-spin-button": {
              margin: 0,
              WebkitAppearance: "none",
            },
          },
        },
        img: {
          display: "block",
          maxWidth: "100%",
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        ".MuiButton-contained": {
          minHeight: "56px",
          borderRadius: "40px !important",
          paddingRight: "35px !important",
          paddingLeft: "35px !important",
        },
        ".MuiButton-outlined:hover, .MuiButton-contained:hover": {
          backgroundColor: `${props.theme.palette.primary.light} !important`,
          color: "white",
        },
        ".MuiLink-root:hover span": {
          "&.line": {
            display: "block",
            width: 0,
            height: "1px",
            backgroundColor: "#0075e1",
            WebkitAnimation: "lineGrowth 12s forwards",
            MozAnimation: "lineGrowth 1s forwards",
            MsAnimation: "lineGrowth 1s forwards",
            OAnimation: "lineGrowth 1s forwards",
            animation: "lineGrowth 1s forwards",
            "@keyframes lineGrowth": {
              from: {
                width: 0,
              },
              to: {
                width: "100%",
              },
            },
          },
        },
      }}
    />
  );

  return globalStyles;
}
