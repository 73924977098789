import { makeAutoObservable } from "mobx";

export class GeneralStore {
  constructor() {
    makeAutoObservable(this);
  }

  getStatusText(status: string | boolean) {
    var statusText = "";
    switch (status) {
      case "new":
        statusText = "Novo";
        break;
      case "active":
      case true:
        statusText = "Ativo";
        break;
      case "inactive":
      case false:
        statusText = "Inativo";
        break;
      case "draft":
        statusText = "Rascunho";
        break;
      case "suspended":
        statusText = "Suspensa";
        break;
      case "duplicated":
        statusText = "Duplicado";
        break;
      case "done":
        statusText = "Finalizado";
        break;
      case "cancelled":
        statusText = "Cancelado";
        break;
    }

    return statusText;
  }

  getResearchStatusText(status: string | boolean) {
    var statusText = "";
    switch (status) {
      case "active":
      case true:
        statusText = "Aberto";
        break;
      case "inactive":
      case "cancelled":
      case false:
        statusText = "Cancelado";
        break;
      case "done":
        statusText = "Finalizado";
        break;
    }

    return statusText;
  }

  getStatusColor(status: string | boolean) {
    var color = "";
    switch (status) {
      case "active":
      case true:
        color = "success.light";
        break;

      case "done":
        color = "success.light";
        break;

      case "new":
        color = "primary.main";
        break;

      case "inactive":
      case false:
        color = "error.main";
        break;

      case "draft":
        color = "warning.main";
        break;

      case "suspended":
        color = "grey.100";
        break;

      case "duplicated":
        color = "#9747FF";
        break;
    }

    return color;
  }

  getStatusBackground(status: string | boolean) {
    var color = "";
    switch (status) {
      case "active":
      case true:
        color = "info.main";
        break;

      case "done":
        color = "success.main";
        break;

      case "new":
        color = "primary.light";
        break;

      case "inactive":
      case "cancelled":
      case false:
        color = "error.main";
        break;

      case "draft":
        color = "warning.light";
        break;

      case "suspended":
        color = "grey.116";
        break;

      case "duplicated":
        color = "secondary.dark";
        break;
    }

    return color;
  }
}
