import { observer } from "mobx-react-lite";
import { Assessment } from "../../../models/assessment.model";
import { Fragment, useContext } from "react";
import { Box, Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { StoreContext } from "../../../stores/store.context";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { barTextPosition } from "../../../helpers/";
import { DXIRoles } from "../../../enums/dxi_roles.enum";

interface AssessmentCardProps {
  assessmentData: Assessment;
  hasCheck?: boolean;
  hasViewLink?: boolean;
  hasProgress?: boolean;
}

const AssessmentCard: React.FC<AssessmentCardProps> = (props: AssessmentCardProps) => {
  const { assessmentStore, userStore, researchStore } = useContext(StoreContext);

  const navigate = useNavigate();

  return (
    <Fragment>
      <Box
        sx={{
          p: 4,
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "#F1F2F6",
          borderRadius: "8px",
          minHeight: "340px",
          boxShadow: "2px 4px 5px rgba(0, 0, 0, 0.05)",
          backgroundColor: "background.default",
        }}
      >
        <Box
          component="img"
          src={`${process.env.REACT_APP_ASSESSMENT_SERVICE}/uploads/assessment/${props.assessmentData.image}`}
          sx={{
            // maxWidth: "375px",
            // maxHeight: "125px",
            objectFit: "scale-down",
            margin: "auto",
          }}
        />

        <Typography variant="h5" fontWeight={500} sx={{ my: 2 }}>
          {props.assessmentData.name}
        </Typography>

        <Typography
          variant="body2"
          sx={{ my: 2, minHeight: "100px", maxHeight: "100px", overflow: "auto" }}
        >
          {props.assessmentData.description}
        </Typography>
        {props.hasViewLink && (
          <Button
            variant="text"
            sx={{ textDecoration: "underline", textUnderlineOffset: "8px" }}
            onClick={() =>
              navigate(
                `/secure/assessment/${props.assessmentData._id}?name=${props.assessmentData.name}`
              )
            }
          >
            Visualizar
          </Button>
        )}
        {props.hasCheck && (
          <FormControlLabel
            control={
              <Checkbox
                checked={assessmentStore.selectedAssessments?.includes(props.assessmentData._id!)}
                onChange={() =>
                  assessmentStore.handleSelectedAssessments(props.assessmentData._id!)
                }
                name="select-assessment"
                color="primary"
                size="small"
              />
            }
            label={
              <Typography variant="body2" fontWeight={500}>
                Selecionar
              </Typography>
            }
          />
        )}

        {props.hasProgress && (
          <Box
            sx={{
              width: "100%",
              height: "32px",
              position: "relative",
              mt: 3,
              display: "flex",
              alignItems: "center",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "grey.c59",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                left: 0,
                backgroundColor: "success.main",
                height: "100%",
                width:
                  props.assessmentData.progress === 0
                    ? 2 + "%"
                    : props.assessmentData.progress + "%",
              }}
            ></Box>
            <Typography
              variant="body2"
              fontWeight={500}
              sx={{
                zIndex: 99,
                marginLeft: barTextPosition(props.assessmentData.progress!),
              }}
            >
              {props.assessmentData.progress}% concluído
            </Typography>
          </Box>
        )}

        {props.hasProgress && props.assessmentData.progress !== 100 && (
          <Button
            variant="text"
            sx={{ mt: 3, marginLeft: "auto", display: "flex" }}
            onClick={() => {
              if (userStore.userRole !== DXIRoles.DXI_CONSULTANT) {
                if (researchStore.selectedResearch) {
                  assessmentStore.updateAssessmentAccess(
                    props.assessmentData!._id!,
                    researchStore.selectedResearch?._id!,
                    userStore.user?.id!
                  );
                  if (props.assessmentData.progress === 0) {
                    navigate(
                      `/secure/research/${researchStore.selectedResearch?._id}/assessment/${props.assessmentData._id}/user/${userStore.user?.id}/answer`
                    );
                  } else {
                    navigate(
                      `/secure/research/${researchStore.selectedResearch?._id}/assessment/${props.assessmentData._id}/user/${userStore.user?.id}/answer?progress=${props.assessmentData.progress}`
                    );
                  }
                } else {
                  if (researchStore.researchAssessments) {
                    if (props.assessmentData.progress === 0) {
                      navigate(
                        `/secure/research/${researchStore.lastAccessedResearch?._id}/assessment/${props.assessmentData._id}/user/${userStore.user?.id}/answer`
                      );
                    } else {
                      navigate(
                        `/secure/research/${researchStore.lastAccessedResearch?._id}/assessment/${props.assessmentData._id}/user/${userStore.user?.id}/answer?progress=${props.assessmentData.progress}`
                      );
                    }
                  }
                }
              } else {
                navigate(
                  `/secure/assessment/${props.assessmentData._id}?name=${props.assessmentData.name}`
                );
              }
            }}
          >
            {props.assessmentData.progress! > 0 ? "Continuar" : "Iniciar"}{" "}
            <ArrowForwardIcon fontSize="small" sx={{ ml: 2 }} />
          </Button>
        )}
      </Box>
    </Fragment>
  );
};

export default observer(AssessmentCard);
