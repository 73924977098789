import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../../../stores/store.context";
import { Fragment, useContext } from "react";
import { stages } from "../../about/constants/stages";

const ResearchReportMaturityLevel: React.FC = () => {
  const { researchStore } = useContext(StoreContext);

  const currentLevel = researchStore.researchAssessmentData!.activeLevel!;

  const getBackgroundColor = (pos: number) => {
    const colors = ["#0075E1", "#0050D6", "#002FB9", "#00164F"];
    return colors[pos];
  };

  const insights = researchStore.researchAssessmentData!.assessment.levels![currentLevel].insights!;

  return (
    researchStore.researchAssessmentData && (
      <Fragment>
        <Box
          sx={{ display: "flex", justifyContent: "space-between", alignContent: "center", mt: 6 }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", mr: 4 }}>
            <Typography variant="h4" color="primary.dark" sx={{ mb: 2 }}>
              Por que meu nível de maturidade importa?
            </Typography>

            {researchStore.researchAssessmentData.assessment &&
              researchStore.researchAssessmentData.assessment.levels && (
                <Typography variant="body2" color="grey.c59">
                  {
                    researchStore.researchAssessmentData.assessment.levels[currentLevel]
                      .maturityLevelText
                  }
                </Typography>
              )}
          </Box>

          <Box
            sx={{
              borderWidth: "1px",
              borderColor: "grey.ce9",
              borderStyle: "solid",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "370px",
            }}
          >
            <Typography variant="body1" color="primary.dark" fontWeight={700}>
              Próximo nível:
            </Typography>

            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  backgroundColor: "grey.cf2",
                  p: 1,
                  mt: 2,
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "8px",
                }}
              >
                {stages[currentLevel].icon("#A3A3A3")}
                <Typography
                  variant="body1"
                  sx={{
                    ml: 1,
                    color: "grey.ca3",
                  }}
                >
                  Nível {currentLevel + 1}: {stages[currentLevel].title}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        {researchStore.researchAssessmentData.assessment &&
          researchStore.researchAssessmentData.assessment.levels && (
            <Box>
              <Typography variant="body1" color="black" sx={{ my: 4, maxWidth: "640px" }}>
                Nossa pesquisa mostra que se uma empresa em nível de convencimento passasse para o
                nível de implementação veria:
              </Typography>

              {insights && (
                <Box sx={{ display: "flex" }}>
                  {insights.map((insight, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          backgroundColor: getBackgroundColor(index),
                          p: 2,
                          borderTopLeftRadius: index === 0 ? "8px" : 0,
                          borderBottomLeftRadius: index === 0 ? "8px" : 0,
                          borderTopRightRadius: index + 1 === insights.length ? "8px" : 0,
                          borderBottomRightRadius: index + 1 === insights.length ? "8px" : 0,
                        }}
                      >
                        <Typography
                          variant="h5"
                          color="white"
                          textAlign={"center"}
                          dangerouslySetInnerHTML={{ __html: insight }}
                        />
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
          )}
      </Fragment>
    )
  );
};

export default observer(ResearchReportMaturityLevel);
