import { Pagination } from "../models/pagination.model";
import axios from "axios";
import { Research } from "../models/research.model";
import { UserData } from "../models/user.model";
import { errHandler } from "../helpers/error-handler";

export class ResearchService {
  async addAssessmentsToResearch(researchId: string, assessments: Array<string>) {
    try {
      const res = await axios.put(
        process.env.REACT_APP_RESEARCH_SERVICE + `/research/${researchId}/assessments`,
        assessments
      );
      return res.data.body;
    } catch (e: any) {
      throw errHandler(e);
    }
  }

  async addResearch(company: string, research: Research) {
    try {
      const res = await axios.post(process.env.REACT_APP_RESEARCH_SERVICE + `/research/add`, {
        company,
        name: research.name,
        assessments: research.assessments,
        createdBy: research.createdBy,
      });
      return res.data.body;
    } catch (e: any) {
      throw errHandler(e);
    }
  }

  async changeResearchStatus(researchId: string, status: string) {
    try {
      const res = await axios.put(
        process.env.REACT_APP_RESEARCH_SERVICE + `/research/${researchId}/status/${status}`
      );
      return res.data.body;
    } catch (e: any) {
      throw errHandler(e);
    }
  }

  async listResearches(
    page: number,
    limit: number,
    orderBy?: string,
    orderByDirection?: string,
    filter?: string
  ): Promise<Pagination<Array<Research>>> {
    try {
      const res = await axios.get(process.env.REACT_APP_RESEARCH_SERVICE + `/research/list`, {
        params: { page, limit, orderBy, orderByDirection, filter },
      });

      if (res.status !== 200) throw new Error("Erro ao carregar avaliações");

      const parsedResponse: Pagination<Array<Research>> = await res.data.body;

      parsedResponse.paginatedData = parsedResponse.paginatedData.map((research: Research) => {
        return {
          _id: research._id,
          name: research.name,
          createdAt: research.createdAt,
          finishedAt: research.finishedAt,
          status: research.status,
          companyName: research.company?.name,
        };
      });

      return parsedResponse;
    } catch (e: any) {
      if (e && e.code) {
        if (e.code !== "ERR_NETWORK" && e.code !== "ERR_BAD_RESPONSE") {
          throw e.response.data;
        }
      }
      throw e.message;
    }
  }

  async getResearchById(id: string): Promise<Research> {
    try {
      const res = await axios.get(process.env.REACT_APP_RESEARCH_SERVICE + `/research/${id}`);
      let parsedResponse = await res.data.body;
      return parsedResponse;
    } catch (e: any) {
      throw errHandler(e);
    }
  }

  async getLastAccessedResearch(userId: string): Promise<Research> {
    try {
      const res = await axios.get(
        process.env.REACT_APP_RESEARCH_SERVICE + `/research/user/${userId}/last-accessed-research`
      );
      let parsedResponse = await res.data.body;
      return parsedResponse.research;
    } catch (e: any) {
      throw errHandler(e);
    }
  }

  async listResearchesByCompany(
    companyId: string,
    page: number,
    limit: number,
    orderBy?: string,
    orderByDirection?: string,
    filter?: string
  ): Promise<Pagination<Array<Research>>> {
    try {
      const res = await axios.get(
        process.env.REACT_APP_RESEARCH_SERVICE + `/research/company/${companyId}/list`,
        {
          params: { page, limit, orderBy, orderByDirection, filter },
        }
      );
      const parsedResponse = await res.data.body;

      return parsedResponse;
    } catch (e: any) {
      throw errHandler(e);
    }
  }

  async getResearchAssessmentsByUser(researchId: string, userId: string): Promise<any> {
    try {
      const res = await axios.get(
        process.env.REACT_APP_RESEARCH_SERVICE +
          `/research/${researchId}/user/${userId}/assessments`
      );
      const parsedResponse = await res.data.body;
      return parsedResponse;
    } catch (e: any) {
      throw errHandler(e);
    }
  }

  async getResearchAssessmentData(researchId: string, assessmentId: string): Promise<any> {
    try {
      const res = await axios.get(
        process.env.REACT_APP_RESEARCH_SERVICE +
          `/research/${researchId}/assessment/${assessmentId}/report`
      );
      const parsedResponse = await res.data.body;
      return parsedResponse;
    } catch (e: any) {
      throw errHandler(e);
    }
  }

  async getUserResearchAssessment(
    researchId: string,
    assessmentId: string,
    userId: string
  ): Promise<any> {
    try {
      const res = await axios.get(
        process.env.REACT_APP_RESEARCH_SERVICE +
          `/research/${researchId}/assessment/${assessmentId}/user/${userId}/answers`
      );
      const parsedResponse = await res.data.body;
      return parsedResponse;
    } catch (e: any) {
      throw errHandler(e);
    }
  }

  async saveResearchAssessmentAnswer(
    researchId: string,
    assessmentId: string,
    userId: string,
    question: { questionId: string; answer: number }
  ): Promise<boolean> {
    try {
      const res = await axios.put(
        process.env.REACT_APP_RESEARCH_SERVICE +
          `/research/${researchId}/assessment/${assessmentId}/user/${userId}/save`,
        question
      );
      const parsedResponse = await res.data.body;
      return parsedResponse;
    } catch (e: any) {
      throw errHandler(e);
    }
  }

  async inviteUserToResearch(researchId: string, user: UserData | string, assessments: string[]) {
    try {
      const res = await axios.post(
        process.env.REACT_APP_RESEARCH_SERVICE + `/research/${researchId}/invite/user`,
        {
          user,
          assessments,
        }
      );
      return res.data.body;
    } catch (e: any) {
      throw errHandler(e);
    }
  }
}
