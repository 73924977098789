import { CssBaseline, ThemeProvider } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { StoreContext } from '../stores/store.context'
import GlobalStyles from './globalStyles'

const ThemeCustomization = ({ children }: any) => {
  const { themeStore } = useContext(StoreContext)

  return (
    <ThemeProvider theme={themeStore.theme}>
      <CssBaseline />
      <GlobalStyles theme={themeStore.theme} />
      {children}
    </ThemeProvider>
  )
}

export default observer(ThemeCustomization)
