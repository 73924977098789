import { observer } from "mobx-react-lite";
import { Fragment } from "react";
import { Outlet } from "react-router-dom";

const CompanyPage: React.FC = () => {
  return (
    <Fragment>
      <Outlet />
    </Fragment>
  );
};

export default observer(CompanyPage);
