import React from 'react'
import Linkedin from '../assets/images/social-linkedin'
import Instagram from '../assets/images/social-instagram'
import Facebook from '../assets/images/social-facebook'
import Youtube from '../assets/images/social-youtube'
import Box from '@mui/material/Box'
import { Link, Typography } from '@mui/material'

const SocialMediaIcons = (props: { sitePos: String }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }} mt={{ xs: 3 }}>
      {props.sitePos === 'left' && (
        <Link
          sx={{ mr: 2, textDecoration: 'none' }}
          display={{ xs: 'none', sm: 'block' }}
          href="https://www.meta.com.br"
          target="_blank"
          rel="noreferrer"
        >
          <Typography variant="button" color={'primary.contrastText'}>
            www.meta.com.br
          </Typography>
        </Link>
      )}

      <Link href="https://www.linkedin.com/company/metaoficial/" target="_blank" rel="noreferrer">
        <Linkedin />
      </Link>

      <Link sx={{ mx: 1 }} href="https://www.instagram.com/meta_oficial" target="_blank" rel="noreferrer">
        <Instagram />
      </Link>

      <Link href="https://www.facebook.com/metaoficialbr" target="_blank" rel="noreferrer">
        <Facebook />
      </Link>

      <Link
        sx={{ mx: props.sitePos === 'right' ? 1 : 0, ml: 1 }}
        href="https://www.youtube.com/channel/UC8dSjPVE19268irSO-itupw"
        target="_blank"
        rel="noreferrer"
      >
        <Youtube />
      </Link>

      {props.sitePos === 'right' && (
        <Link
          href="https://www.meta.com.br"
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: 'none' }}
          display={{ xs: 'none', sm: 'block' }}
        >
          <Typography variant="button" color={'primary.contrastText'}>
            www.meta.com.br
          </Typography>
        </Link>
      )}
    </Box>
  )
}

export default SocialMediaIcons
