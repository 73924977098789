export enum CompanySectorsEnum {
  agronegocio = 'Agronegócio',
  bens_consumo = 'Bens de Consumo',
  educacao = 'Educação',
  energia = 'Energia',
  financeiro = 'Financeiro',
  governo = 'Governo',
  imobiliario = 'Imobiliário',
  logestica = 'Logística',
  petroleo_gas = 'Petroleo e Gas',
  saude = 'Saúde',
  seguros = 'Seguros',
  tecnologia = 'Tecnologia',
  telecomunicacao = 'Telecomunicação',
  varejo = 'Varejo',
  outros = 'Outros',
}
