import { Box, Divider, Typography, Link } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Fragment, useContext } from "react";
import { StoreContext } from "../stores/store.context";
import { NavLink as RouterLink } from "react-router-dom";

const Breadcrumb: React.FC = () => {
  const { breadcrumbStore } = useContext(StoreContext);

  const textAlign = {
    height: "30px",
    display: "flex",
    alignItems: "center",
  };

  return (
    <Fragment>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Link underline="none" component={RouterLink} to="/secure/home" sx={{ ...textAlign }}>
          <Typography variant="button" sx={{ color: "primary.main" }}>
            Home {">"}
          </Typography>
        </Link>
        {breadcrumbStore.breadcrumb &&
          breadcrumbStore.breadcrumb.map((breadcrumb, index) => {
            return (
              <Fragment key={index}>
                {breadcrumb.route ? (
                  <Link underline="none" component={RouterLink} to={breadcrumb.route}>
                    <Typography
                      variant="button"
                      sx={{ ...textAlign, color: "primary.main", ml: 1 }}
                    >
                      {breadcrumb.name} {">"}
                    </Typography>
                  </Link>
                ) : (
                  <Typography variant="body2" sx={{ ...textAlign, color: "grey.c59", mx: 1 }}>
                    {breadcrumb.name}
                  </Typography>
                )}
              </Fragment>
            );
          })}
      </Box>

      <Divider sx={{ my: 2 }} />
    </Fragment>
  );
};

export default observer(Breadcrumb);
