import { Box, Button, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { StoreContext } from "../../../stores/store.context";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { trackPromise } from "react-promise-tracker";

interface ResearchCancelModalProps {
  id: string;
}

const ResearchCancelModal: React.FC<ResearchCancelModalProps> = (
  props: ResearchCancelModalProps
) => {
  const { modalStore, researchStore, toastStore } = useContext(StoreContext);

  const handleCancel = async () => {
    if (props.id) {
      const res = await trackPromise(
        researchStore.changeResearchStatus(props.id, "cancelled"),
        "canceling-research"
      );

      if (res) {
        toastStore.pushToast({ message: "Pesquisa cancelada com sucesso!", type: "success" });
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: 1,
      }}
    >
      <ErrorOutlineOutlinedIcon sx={{ fontSize: "64px", color: "warning.main" }} />

      <Typography variant="h4" color="black">
        Deseja cancelar esta pesquisa?
      </Typography>

      <Typography variant="body2" color="grey.c59" sx={{ my: 2, textAlign: "center" }}>
        O Cliente Master e todos os usuários convidados não conseguirão acessar as avaliações e
        nenhum diagnóstico será gerado.
      </Typography>

      <Typography variant="body2" color="grey.c59" sx={{ textAlign: "center" }}>
        Não será possível retornar para o status Aberto ou Finalizado.
      </Typography>

      <Button
        sx={{ my: 3 }}
        variant="contained"
        onClick={() => {
          handleCancel();
          modalStore.handleClose();
        }}
      >
        Sim, desejo cancelar
      </Button>

      <Button variant="text" onClick={() => modalStore.handleClose()}>
        Não, desejo manter o status atual{" "}
      </Button>
    </Box>
  );
};

export default observer(ResearchCancelModal);
