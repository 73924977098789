import { Grid, Stack, Typography, TextField, FormHelperText, Box, Button } from "@mui/material";
import { Formik } from "formik";
import { useContext, useState } from "react";
import { StoreContext } from "../../stores/store.context";
import * as Yup from "yup";
import InfoIcon from "@mui/icons-material/Info";

interface UserFormProps {
  invitingResearch?: boolean;
}

const UserForm: React.FC<UserFormProps> = (props: UserFormProps) => {
  const { userStore, researchStore, companyStore } = useContext(StoreContext);
  const [emailTooltip, showEmailTooltip] = useState<boolean>(false);

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
      }}
      validateOnMount={true}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required("O campo nome é obrigatório"),
        lastName: Yup.string().required("O campo sobrenome é obrigatório"),
        email: Yup.string()
          .email("E-mail inválido")
          .max(255)
          .required("O campo email é obrigatório")
          .test("invalidDomain", "Email inválido. Domínio não permitido.", (value) => {
            var domainExists = false;
            var domainExistsArray = false;
            const checkDomain = (obj: any) => obj.name === value.split("@")[1];
            const checkDomainArray = (obj: any) => obj === value.split("@")[1];

            if (props.invitingResearch) {
              if (value && researchStore.research) {
                domainExists = researchStore.research.company!.allowedDomains.some(checkDomain);
                domainExistsArray =
                  researchStore.research.company!.allowedDomains.some(checkDomainArray);
              }
              return domainExists || domainExistsArray;
            } else {
              if (value && companyStore) {
                domainExists = companyStore.company!.allowedDomains.some(checkDomain);
                domainExistsArray = companyStore.company!.allowedDomains.some(checkDomainArray);
              }
              return domainExists || domainExistsArray;
            }
          }),
      })}
      onSubmit={async () => {}}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values, isValid }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {!props.invitingResearch && (
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Typography variant="h5" color="secondary.contrastText">
                  Agora, preencha os dados do usuário Cliente Master.
                </Typography>
                <Typography variant="h5" color="secondary.contrastText">
                  Ele deve ser o seu contato principal na empresa.
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      borderColor: "grey.200",
                    },
                  }}
                  fullWidth
                  error={Boolean(touched.firstName && errors.firstName)}
                  id="firstName"
                  type="firstName"
                  value={values.firstName}
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    userStore.userForm = {
                      ...userStore.userForm,
                      firstName: e.target.value,
                    };
                  }}
                  placeholder="Digite o nome"
                  variant="outlined"
                  label="Nome"
                  inputProps={{
                    autoComplete: "user-firstName",
                    form: {
                      autocomplete: "off",
                    },
                  }}
                />
                {touched.firstName && errors.firstName && (
                  <FormHelperText error>{errors.firstName}</FormHelperText>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      borderColor: "grey.200",
                    },
                  }}
                  fullWidth
                  error={Boolean(touched.lastName && errors.lastName)}
                  id="lastName"
                  type="lastName"
                  value={values.lastName}
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    userStore.userForm = {
                      ...userStore.userForm,
                      lastName: e.target.value,
                    };
                  }}
                  placeholder="Digite o sobrenome"
                  variant="outlined"
                  label="Sobrenome"
                  inputProps={{
                    autoComplete: "user-lastName",
                    form: {
                      autocomplete: "off",
                    },
                  }}
                />
                {touched.lastName && errors.lastName && (
                  <FormHelperText error>{errors.lastName}</FormHelperText>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={props.invitingResearch ? 12 : 6}>
              <Stack spacing={1} sx={{ position: "relative" }}>
                {props.invitingResearch && (
                  <InfoIcon
                    onClick={() => showEmailTooltip(!emailTooltip)}
                    sx={{ ml: "auto", cursor: "pointer" }}
                  />
                )}
                {props.invitingResearch && emailTooltip && (
                  <Box
                    sx={{
                      position: "absolute",
                      right: "0",
                      top: "-150px",
                      zIndex: "99",
                      backgroundColor: "grey.c59",
                      p: 2,
                      maxWidth: "310px",
                      textAlign: "center",
                    }}
                  >
                    <Typography variant="overline" color="white" sx={{ lineHeight: "16px" }}>
                      Você só pode convidar usuários que possuam email com os domínios
                      pré-definidos. Caso você precise que um novo domínio seja liberado, solicite
                      ao seu consultor Meta.
                    </Typography>
                  </Box>
                )}
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      borderColor: "grey.200",
                    },
                  }}
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                  id="email"
                  type="email"
                  value={values.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    userStore.userForm = {
                      ...userStore.userForm,
                      email: e.target.value,
                    };
                  }}
                  placeholder="Utilize um dos domínios permitidos"
                  variant="outlined"
                  label="Email"
                  inputProps={{
                    autoComplete: "user-email",
                    form: {
                      autocomplete: "off",
                    },
                  }}
                />
                {touched.email && errors.email && (
                  <FormHelperText error>{errors.email}</FormHelperText>
                )}

                {props.invitingResearch && (
                  <Box>
                    {researchStore.research &&
                      researchStore.research.company!.allowedDomains.map((domain, index) => {
                        return (
                          <Box
                            sx={{
                              backgroundColor: "grey.cf2",
                              py: 1,
                              px: 2,
                              borderRadius: 3,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              mt: 1,
                            }}
                            key={index}
                          >
                            <Typography variant="body2">{domain}</Typography>
                          </Box>
                        );
                      })}
                  </Box>
                )}
              </Stack>

              {props.invitingResearch && (
                <Grid item xs={12} sx={{ textAlign: "right", mt: 3 }}>
                  <Button
                    onClick={() => userStore.userFormStep++}
                    variant="contained"
                    disabled={!isValid}
                  >
                    Avançar
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default UserForm;
