import { Box, Typography, Stack } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Fragment, useState, useContext } from "react";
import { StoreContext } from "../../../stores/store.context";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ButtonLoader from "../../../components/button-loader";
import NoImage from "../../../assets/images/no-image.png";
import { useParams } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";

const CompanyImage: React.FC = () => {
  const { companyStore, toastStore } = useContext(StoreContext);
  const [selectedImage, setSelectedImage] = useState();

  let { id } = useParams();

  const imageChange = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      companyStore.company = { ...companyStore.company, logo: file };

      if (id) {
        await trackPromise(companyStore.changeCompanyLogo(file, id), "change-logo");
        toastStore.pushToast({ message: "Logo atualizado com sucesso!", type: "success" });
      }

      setSelectedImage(file);
    }
  };

  return (
    <Fragment>
      <Box
        sx={{
          height: "240px",
          maxWidth: "300px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          border: 1,
          borderColor: "grey.200",
          borderRadius: "8px",
          mb: 2,
        }}
      >
        {companyStore.company && companyStore.company.logo && !selectedImage ? (
          <Box
            sx={{ objectFit: "cover", maxWidth: "210px", maxHeight: "210px" }}
            component="img"
            src={
              process.env.REACT_APP_COMPANY_SERVICE +
              "/uploads/company/" +
              companyStore.company.logo
            }
          />
        ) : !selectedImage ? (
          <Box
            sx={{
              objectFit: "cover",
              width: "270px",
              height: "200px",
              backgroundImage: `url("${NoImage}")`,
            }}
          />
        ) : (
          <Box
            sx={{ objectFit: "cover", maxWidth: "210px", maxHeight: "210px" }}
            component="img"
            src={URL.createObjectURL(selectedImage)}
          />
        )}
      </Box>

      <Typography variant="h6" color="primary.dark">
        Adicione o logotipo da empresa
      </Typography>

      <Typography variant="body2" color="secondary.lighter">
        Formatos permitidos: JPG ou PNG.
      </Typography>

      <Stack direction="row" alignItems="center" spacing={2}>
        <ButtonLoader
          component="label"
          variant="outlined"
          loadingText="Processando..."
          text={
            <>
              <FileUploadOutlinedIcon sx={{ mr: 2 }} /> Alterar imagem
              <input
                onChange={imageChange}
                hidden
                accept="image/jpg,image/png,image/jpeg"
                type="file"
              />
            </>
          }
          area="change-logo"
          style={{
            borderRadius: 8,
            mt: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 2,
            px: 4,
          }}
        />
      </Stack>
    </Fragment>
  );
};

export default observer(CompanyImage);
