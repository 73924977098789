interface IconDirectionProps {
  color?: string;
}

const IconDiamond = (props: IconDirectionProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6275 20.7215L12.8137 20.1403L13.6275 20.7215ZM2.65186 7.88252L3.51564 8.38639L2.65186 7.88252ZM2.75195 10.0527L3.56569 9.4715L2.75195 10.0527ZM4.92118 3.99226L5.78496 4.49613L4.92118 3.99226ZM17.3513 3L17.3513 4L17.3513 3ZM21.3481 7.88252L22.2119 7.37865L21.3481 7.88252ZM21.248 10.0527L22.0618 10.634L21.248 10.0527ZM22.2119 7.37865L19.9426 3.48839L18.215 4.49613L20.4844 8.38639L22.2119 7.37865ZM17.3513 2L6.64874 2L6.64874 4L17.3513 4L17.3513 2ZM4.0574 3.48839L1.78808 7.37865L3.51564 8.38639L5.78496 4.49613L4.0574 3.48839ZM1.93822 10.634L9.5588 21.3028L11.1863 20.1403L3.56569 9.4715L1.93822 10.634ZM14.4412 21.3028L22.0618 10.634L20.4343 9.4715L12.8137 20.1403L14.4412 21.3028ZM9.5588 21.3028C10.7553 22.9778 13.2447 22.9778 14.4412 21.3028L12.8137 20.1403C12.4149 20.6987 11.5851 20.6987 11.1863 20.1403L9.5588 21.3028ZM1.78808 7.37865C1.19304 8.39873 1.25181 9.673 1.93822 10.634L3.56569 9.4715C3.33688 9.15117 3.31729 8.72642 3.51564 8.38639L1.78808 7.37865ZM6.64874 2C5.58174 2 4.59504 2.56673 4.0574 3.48839L5.78496 4.49613C5.96417 4.18891 6.29307 4 6.64874 4L6.64874 2ZM19.9426 3.48839C19.405 2.56673 18.4183 2 17.3513 2L17.3513 4C17.7069 4 18.0358 4.18891 18.215 4.49613L19.9426 3.48839ZM20.4844 8.38639C20.6827 8.72641 20.6631 9.15117 20.4343 9.4715L22.0618 10.634C22.7482 9.673 22.807 8.39872 22.2119 7.37865L20.4844 8.38639Z"
        fill={props.color ?? "white"}
      />
      <path
        d="M3 8C2.44772 8 2 8.44772 2 9C2 9.55228 2.44772 10 3 10V8ZM21 10C21.5523 10 22 9.55228 22 9C22 8.44772 21.5523 8 21 8V10ZM3 10H21V8H3V10Z"
        fill={props.color ?? "white"}
      />
    </svg>
  );
};

export default IconDiamond;
