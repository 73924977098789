/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography, Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../stores/store.context";
import { trackPromise } from "react-promise-tracker";
import { Company } from "../../models/company.model";
import { UserData } from "../../models/user.model";
// import CompanyForm from "./components/company-form";
import Breadcrumb from "../../components/breadcrumb";
// import CompanyAdded from "./components/company-added";
import ButtonLoader from "../../components/button-loader";
import UserForm from "../user/user-form";
import UserFormStepper from "./components/user-form-stepper";
import { useParams } from "react-router-dom";
import AssessmentList from "../assessment/components/assessment-list";
import UserInvited from "./components/user-invited";
import { action } from "mobx";

const UserAddPage: React.FC = () => {
  const { breadcrumbStore, companyStore, userStore, toastStore, researchStore, assessmentStore } =
    useContext(StoreContext);
  const [formsKey, setFormsKey] = useState(0);

  let { id } = useParams();

  useEffect(() => {
    var getResearchData = async () => {
      if (!researchStore.research) {
        await trackPromise(researchStore.getResearchById(id as string), "getting-data");
      }

      if (researchStore.research) {
        breadcrumbStore.breadcrumb = [
          { name: "Gerenciar pesquisas", route: "/secure/company/manage" },
          {
            name: researchStore.research.name!,
            route: `/secure/research/${researchStore.research._id}/`,
          },
          { name: "Convidar para pesquisa" },
        ];
      }
    };

    getResearchData();
  }, [breadcrumbStore, companyStore, userStore]);

  const handleSave = async () => {
    const inviteUser = await trackPromise(
      researchStore.inviteUserToResearch(
        researchStore.research?._id!,
        userStore.userForm,
        assessmentStore.selectedAssessments
      ),
      "invite-user"
    );

    if (inviteUser) {
      toastStore.pushToast({ message: "Convite enviado com sucesso!", type: "success" });
      userStore.userFormStep++;
    }
  };

  const resetForms = () => {
    setFormsKey(Math.floor(Math.random() * 100));
    userStore.userForm = new UserData();
    userStore.userFormStep = 0;
    action(() => (assessmentStore.selectedAssessments = []))();
  };

  return (
    <Box sx={{ mt: 6 }}>
      {breadcrumbStore.breadcrumb && (
        <Box sx={{ my: 3 }}>
          <Breadcrumb />
        </Box>
      )}

      <Grid container sx={{ mt: 6 }}>
        <Grid item xs={12}>
          <Typography variant="h2" sx={{ color: "primary.dark" }}>
            Convidar para pesquisa
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          mt: 4,
          borderRadius: "8px",
          backgroundColor: "background.default",
          display: "flex",
          px: 8,
          py: 6,
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <UserFormStepper />
          </Grid>

          <Box
            sx={{
              margin: "auto",
              display: userStore.userFormStep === 0 ? "block" : "none",
            }}
          >
            <Grid item xs={12} sx={{ mb: 4, textAlign: "center" }}>
              <Box sx={{ maxWidth: "670px", margin: "auto" }}>
                <Typography variant="body2" color="grey.c59">
                  O critério para convidar os usuários para responder às avaliações é definido por
                  você, não há limite de convidados. Pode ser o time técnico de cada área,
                  coordenadores, gerentes e até o CEO. O importante é que seja uma amostra
                  significativa de todos os envolvidos no assunto.
                </Typography>

                <Typography variant="body2" color="grey.c59">
                  Assim, o diagnóstico será mais assertivo e servirá de base para decisões
                  estratégicas.
                </Typography>
              </Box>
            </Grid>
            <UserForm invitingResearch={true} key={formsKey} />
          </Box>

          <Box sx={{ display: userStore.userFormStep === 1 ? "block" : "none" }}>
            <AssessmentList
              cardCanBeSelected={true}
              hasHeader={true}
              direction="vertical"
              listHeaderText="Selecione as avaliações que esse convidado deverá responder."
              cardCanBeViewed={false}
              assessmentsByResearch={true}
              hasProgress={false}
              useResearchData={true}
            />

            <Grid item xs={12} sx={{ textAlign: "right" }}>
              {/* {//TODO: Disable logic} */}
              <ButtonLoader
                type="button"
                loadingText="Processando..."
                text="Enviar convite"
                area="invite-user"
                onClick={handleSave}
              />
            </Grid>
          </Box>

          <Box sx={{ display: userStore.userFormStep === 2 ? "flex" : "none", flexGrow: 1 }}>
            <UserInvited research={researchStore.research} onReset={resetForms} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default observer(UserAddPage);
