import { Button, CircularProgress, SxProps } from "@mui/material";
import { MouseEventHandler, ReactElement } from "react";
import { usePromiseTracker } from "react-promise-tracker";

type ButtonProps = {
  component?: any;
  text: string | ReactElement;
  type?: "button" | "submit" | "reset";
  area?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  loadingText: string;
  disabled?: boolean;
  style?: SxProps;
  variant?: "text" | "contained" | "outlined";
};

const defaultStyle = {
  borderRadius: 10,
  paddingX: 4,
  paddingY: 1.5,
  textTransform: "none",
  minWidth: "195px",
};

const ButtonLoaderComponent: React.FC<ButtonProps> = (props: ButtonProps) => {
  const { promiseInProgress } = usePromiseTracker({ area: props.area });

  return (
    <>
      <Button
        component={props.component ?? "button"}
        disableElevation
        disabled={props.disabled || promiseInProgress}
        size="large"
        type={props.type ?? "submit"}
        variant={props.variant ?? "contained"}
        color="primary"
        onClick={props.onClick}
        sx={props.style ? props.style : defaultStyle}
      >
        {promiseInProgress ? (
          <>
            <CircularProgress color="secondary" size={20} sx={{ mr: 2 }} />
            {props.loadingText}
          </>
        ) : (
          props.text
        )}
      </Button>
    </>
  );
};

export default ButtonLoaderComponent;
