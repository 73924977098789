/* eslint-disable react-hooks/exhaustive-deps */
import { observer } from "mobx-react-lite";
import { Fragment, useContext, useEffect, useState } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { StoreContext } from "../../../stores/store.context";
import { Box, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import AssessmentCard from "./assessment-card";
import ButtonLoader from "../../../components/button-loader";
import CircularLoader from "../../../components/circular-loader";

interface AssessmentsListPros {
  listHeaderText?: string;
  cardCanBeSelected?: boolean;
  hasHeader?: boolean;
  direction?: string;
  cardCanBeViewed?: boolean;
  assessmentsByResearch?: boolean;
  hasProgress?: boolean;
  useResearchData?: boolean;
  ignoreAssessmentsList?: Array<string>;
}

const AssessmentsList: React.FC<AssessmentsListPros> = (props: AssessmentsListPros) => {
  const { promiseInProgress } = usePromiseTracker({ area: "loading-assessments" });
  const { assessmentStore, gridStore, researchStore } = useContext(StoreContext);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const getAssessments = async () => {
      var filter = JSON.stringify({
        name: gridStore.searchText ? gridStore.searchText : "",
        status: "",
      });

      await trackPromise(
        assessmentStore.listAssessments(
          gridStore.currentPage,
          props.direction === "horizontal" || props.ignoreAssessmentsList ? 0 : gridStore.limit,
          "name",
          "asc",
          filter
        ),
        gridStore.currentPage > 1 ? "get-assessments" : "loading-assessments"
      );
    };

    if (!props.assessmentsByResearch) {
      getAssessments();
    }
  }, [
    assessmentStore,
    gridStore.currentPage,
    gridStore.limit,
    gridStore.searchText,
    props.direction,
    props.assessmentsByResearch,
  ]);

  const handleCheckAll = (e: any) => {
    setChecked(e.target.checked);
    assessmentStore.handleCheckAllAssessments(e.target.checked);
  };

  return (
    <Fragment>
      <Grid container sx={{ overflow: "auto" }}>
        {props.hasHeader && (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h4">{props.listHeaderText}</Typography>
            {props.cardCanBeSelected && !props.ignoreAssessmentsList && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={(e) => handleCheckAll(e)}
                    name="select-all"
                    color="primary"
                    size="small"
                  />
                }
                label={
                  <Typography variant="body2" fontWeight={500}>
                    Selecionar todos
                  </Typography>
                }
              />
            )}
          </Grid>
        )}

        {!props.assessmentsByResearch ? (
          <Box sx={{ width: "100%" }}>
            {assessmentStore.assessments && !promiseInProgress && assessmentStore.assessments ? (
              props.direction !== "horizontal" ? (
                <Grid container spacing={3} sx={{ mt: 2 }}>
                  {assessmentStore.assessments.paginatedData.map((assessment, index) => {
                    if (props.ignoreAssessmentsList) {
                      if (!props.ignoreAssessmentsList.includes(assessment._id!.toString())) {
                        return (
                          <Grid item xs={12} sm={6} xl={4} key={index}>
                            <Box sx={{ maxWidth: "440px" }}>
                              <AssessmentCard
                                assessmentData={assessment}
                                hasCheck={props.cardCanBeSelected}
                                hasViewLink={props.cardCanBeViewed}
                              />
                            </Box>
                          </Grid>
                        );
                      }
                    } else {
                      return (
                        <Grid item xs={12} sm={6} xl={4} key={index}>
                          <Box sx={{ maxWidth: "440px" }}>
                            <AssessmentCard
                              assessmentData={assessment}
                              hasCheck={props.cardCanBeSelected}
                              hasViewLink={props.cardCanBeViewed}
                            />
                          </Box>
                        </Grid>
                      );
                    }
                  })}
                  <Grid item xs={12} sx={{ textAlign: "center", my: 2 }}>
                    {!props.ignoreAssessmentsList && (
                      <ButtonLoader
                        loadingText="Carregando..."
                        text="Carregar mais"
                        type="button"
                        area="get-assessments"
                        disabled={gridStore.currentPage >= assessmentStore.assessments.totalPages}
                        onClick={() => gridStore.currentPage++}
                      />
                    )}
                  </Grid>
                </Grid>
              ) : (
                <Box sx={{ pb: 2 }}>
                  <Box
                    sx={{ display: "flex", gap: "16px", "& > :first-of-type": { marginLeft: 0 } }}
                  >
                    {assessmentStore.assessments.paginatedData.map((assessment, index) => (
                      <Fragment key={index}>
                        <Box sx={{ flex: "0 0 440px", maxHeight: "385px" }}>
                          <AssessmentCard
                            assessmentData={assessment}
                            hasCheck={props.cardCanBeSelected}
                          />
                        </Box>
                      </Fragment>
                    ))}
                  </Box>
                </Box>
              )
            ) : (
              <CircularLoader />
            )}
          </Box>
        ) : props.direction === "vertical" ? (
          <Grid
            container
            spacing={3}
            sx={{
              pb: 2,
              display: "flex",
              "& > :first-of-type": { marginLeft: 0 },
            }}
          >
            {!props.useResearchData
              ? researchStore.researchAssessments!.map((research, index) => (
                  <Grid item xs={12} sm={6} xl={4} key={index}>
                    <AssessmentCard
                      assessmentData={research.assessment}
                      hasCheck={props.cardCanBeSelected}
                      hasProgress={props.hasProgress}
                    />
                  </Grid>
                ))
              : researchStore.research?.assessments!.map((assessment, index) => (
                  <Grid item xs={12} sm={6} xl={4} key={index}>
                    <AssessmentCard
                      assessmentData={assessment}
                      hasCheck={props.cardCanBeSelected}
                      hasProgress={props.hasProgress}
                    />
                  </Grid>
                ))}
          </Grid>
        ) : (
          <Box sx={{ pb: 2 }}>
            <Box sx={{ display: "flex", gap: "16px", "& > :first-of-type": { marginLeft: 0 } }}>
              {researchStore.researchAssessments!.map((research, index) => (
                <Fragment key={index}>
                  <Box sx={{ flex: "0 0 440px" }}>
                    <AssessmentCard
                      assessmentData={research.assessment}
                      hasCheck={props.cardCanBeSelected}
                      hasProgress={true}
                    />
                  </Box>
                </Fragment>
              ))}
            </Box>
          </Box>
        )}

        {props.cardCanBeSelected && (
          <Grid item xs={12} textAlign={"center"} sx={{ mt: 2 }}>
            <Typography variant="body2" color="grey.c59">
              {assessmentStore.selectedAssessments.length <= 0
                ? `Nenhuma avaliação selecionada`
                : `${assessmentStore.selectedAssessments.length} avaliações selecionadas`}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

export default observer(AssessmentsList);
