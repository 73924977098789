/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useContext, useEffect, useState } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb";
import { StoreContext } from "../../stores/store.context";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import ResearchViewAccordion from "./components/research-view-accordion";
import BackButton from "../../components/back-button";
import CircularProgressWithLabel from "../../components/circular-progress-with-label";
import AddIcon from "@mui/icons-material/Add";
import { DXIRoles } from "../../enums/dxi_roles.enum";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import AssessmentList from "../assessment/components/assessment-list";
import ButtonLoader from "../../components/button-loader";
import { action } from "mobx";
import ResearchCancelModal from "./modals/assessment-cancel";
import ResearchFinishModal from "./modals/assessment-finish";

const ResearchViewPage: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker({ area: "getting-data" });
  const {
    researchStore,
    breadcrumbStore,
    userStore,
    assessmentStore,
    toastStore,
    modalStore,
    generalStore,
  } = useContext(StoreContext);
  let { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [assessmentsToIgnore, setAssessmentsToIgnore] = useState<Array<string> | null>(null);

  const page = searchParams.get("page")?.replace("/", "");

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  }));

  const getResearchData = async () => {
    await trackPromise(researchStore.getResearchById(id as string), "getting-data");

    if (researchStore.research && researchStore.research.assessments) {
      let assessmentsList: Array<string> = [];
      researchStore.research.assessments.forEach((assessment) => {
        assessmentsList.push(assessment._id);
      });

      setAssessmentsToIgnore(assessmentsList);
    }

    if (userStore.userRole === DXIRoles.DXI_CONSULTANT) {
      if (researchStore.research!.company) {
        breadcrumbStore.breadcrumb = [
          {
            name: page !== "company" ? "Minhas pesquisas" : "Meus clientes",
            route: page !== "company" ? `/secure/research` : `/secure/company/list`,
          },
          {
            name: researchStore.research!.company.name,
            route: `/secure/company/${researchStore.research!.company._id}/manage`,
          },
          { name: researchStore.research!.name! },
        ];
      }
    } else {
      breadcrumbStore.breadcrumb = [
        {
          name: "Gerenciar pesquisas",
          route: "/secure/company/manage",
        },
        { name: researchStore.research?.name! },
      ];
    }
  };

  useEffect(() => {
    action(() => {
      assessmentStore.selectedAssessments = [];
      researchStore.research = null;
    })();
  }, []);

  useEffect(() => {
    if (id) {
      getResearchData();
    }
  }, [breadcrumbStore, id, page, researchStore, userStore.userRole]);

  const handleAdd = async () => {
    if (assessmentStore.selectedAssessments && id) {
      const res = await trackPromise(
        researchStore.addAssessmentsToResearch(id, assessmentStore.selectedAssessments),
        "add-to-research"
      );

      if (res) {
        toastStore.pushToast({ message: "Pesquisa atualizada com sucesso!", type: "success" });
        action(() => {
          assessmentStore.selectedAssessments = [];
        })();
        getResearchData();
      }
    }
  };

  const getAccordion = (users: any, label: string) => (
    <ResearchViewAccordion users={users} label={label} />
  );

  return !promiseInProgress ? (
    <Box sx={{ mt: 6 }}>
      {breadcrumbStore.breadcrumb && researchStore.research && researchStore.research.company && (
        <Fragment>
          <Box sx={{ my: 3 }}>
            <Breadcrumb />
          </Box>
          <Box sx={{ my: 3 }}>
            <BackButton route={`/secure/company/${researchStore.research.company!._id}/manage`} />
          </Box>
        </Fragment>
      )}

      {researchStore.research && researchStore.research.company && (
        <Fragment>
          <Box sx={{ display: "flex", alignItems: "center", mt: 4 }}>
            <Typography variant="h2" color="primary.dark">
              {researchStore.research.name}{" "}
              <Chip
                label={
                  <Typography variant="body2" fontWeight={600}>
                    {generalStore.getResearchStatusText(researchStore.research.status!)}
                  </Typography>
                }
                sx={{
                  alignSelf: "flex-start",
                  backgroundColor: generalStore.getStatusBackground(researchStore.research.status!),
                }}
              />
            </Typography>
          </Box>
          <Typography variant="caption" color="secundary.lighter" sx={{ mt: 4 }}>
            ID: {researchStore.research._id}
          </Typography>

          <Box
            sx={{
              mt: 4,
              borderRadius: "8px",
              backgroundColor: "background.default",
              display: "flex",
              px: 8,
              py: 6,
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                border: 1,
                borderColor: "grey.200",
                borderRadius: "8px",
                p: 2,
              }}
            >
              <Box
                sx={{
                  width: "130px",
                  height: "100px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  border: 1,
                  borderColor: "grey.200",
                  borderRadius: "8px",
                }}
              >
                <Box
                  sx={{ objectFit: "cover", maxWidth: "120px", maxHeight: "90px" }}
                  component="img"
                  src={
                    process.env.REACT_APP_COMPANY_SERVICE +
                    "/uploads/company/" +
                    researchStore.research.company.logo
                  }
                />
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column", ml: 4 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ApartmentOutlinedIcon sx={{ mr: 1, color: "primary.dark" }} />
                  <Typography variant="body2" color="primary.dark" sx={{ fontWeight: 500, mr: 1 }}>
                    Empresa:
                  </Typography>

                  <Typography variant="body2" color="secondary.lighter">
                    {researchStore.research.company.name}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
                  <StarBorderOutlinedIcon sx={{ mr: 1, color: "primary.dark" }} />
                  <Typography variant="body2" color="primary.dark" sx={{ fontWeight: 500, mr: 1 }}>
                    Cliente Master:
                  </Typography>

                  <Typography variant="body2" color="secondary.lighter">
                    {researchStore.research.company.keyUser!.firstName +
                      " " +
                      researchStore.research.company.keyUser!.lastName}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <PersonOutlineOutlinedIcon sx={{ mr: 1, color: "primary.dark" }} />
                  <Typography variant="body2" color="primary.dark" sx={{ fontWeight: 500, mr: 1 }}>
                    Consultor Meta:
                  </Typography>

                  <Typography variant="body2" color="secondary.lighter">
                    {researchStore.research.company.createdBy!.firstName}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{ display: "flex", flexDirection: "column", placeSelf: "flex-start", ml: 8 }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <AccessTimeOutlinedIcon sx={{ mr: 1, color: "primary.dark" }} />
                  <Typography variant="body2" color="primary.dark" sx={{ fontWeight: 500, mr: 1 }}>
                    Iniciado em:
                  </Typography>

                  <Typography variant="body2" color="secondary.lighter">
                    {researchStore.research.createdAt
                      ? new Date().toLocaleDateString("pt-BR")
                      : "-"}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
                  <BorderColorOutlinedIcon sx={{ mr: 1, color: "primary.dark" }} />
                  <Typography variant="body2" color="primary.dark" sx={{ fontWeight: 500, mr: 1 }}>
                    Criador por:
                  </Typography>

                  <Typography variant="body2" color="secondary.lighter">
                    {researchStore.research.company.createdBy!.firstName +
                      " " +
                      researchStore.research.company.createdBy!.lastName}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {userStore.userRole === DXIRoles.DXI_CLIENT_ADMIN &&
              researchStore.research.status === "active" && (
                <Box sx={{ my: 4, textAlign: "right" }}>
                  <Button
                    variant="contained"
                    onClick={() =>
                      navigate(
                        `/secure/user/research/${researchStore.research?._id}/add?newUser=true`
                      )
                    }
                  >
                    <AddIcon sx={{ mr: 2 }} /> Convidar usuário
                  </Button>
                </Box>
              )}

            {userStore.userRole === DXIRoles.DXI_CONSULTANT &&
              researchStore.research.status === "active" && (
                <Box sx={{ mt: 4, mb: 2, textAlign: "center" }}>
                  <ButtonLoader
                    loadingText="Processando..."
                    text={
                      <>
                        <DeleteIcon sx={{ mr: 1 }} /> Cancelar pesquisa
                      </>
                    }
                    area="canceling-research"
                    type="button"
                    variant="text"
                    onClick={() => modalStore.openModal(<ResearchCancelModal id={id!} />)}
                    style={{ alignSelf: "center" }}
                  />

                  <Button
                    variant="contained"
                    sx={{ ml: 2 }}
                    onClick={() => modalStore.openModal(<ResearchFinishModal id={id!} />)}
                  >
                    <CheckIcon sx={{ mr: 1 }} /> Finalizar pesquisa
                  </Button>
                </Box>
              )}

            {researchStore.research && (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {researchStore.research.assessments &&
                  researchStore.research.assessments.map((assessment, index) => {
                    return (
                      <Grid item xs={12} sm={6} xl={4} key={index}>
                        <Item>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start",
                              }}
                            >
                              <Typography variant="h4" color="text.primary">
                                {assessment.name}
                              </Typography>
                              <Typography variant="body2" color="secondary.lighter">
                                <>{assessment.totalUsers} participantes</>
                              </Typography>
                            </Box>

                            <Box>
                              <CircularProgressWithLabel
                                size={65}
                                color="success"
                                variant="determinate"
                                value={assessment.progress}
                              />
                            </Box>
                          </Box>
                          <Divider sx={{ my: 2 }} />
                          {getAccordion(assessment.doneUsers, "Concluído")}
                          {getAccordion(assessment.inProgressUsers, "Em andamento")}
                          {getAccordion(assessment.notStartedUsers, "Não iniciados")}

                          {researchStore.research?.status === "done" &&
                            assessment.progress === 100 && (
                              <Box sx={{ mt: 3, textAlign: "right" }}>
                                <Divider sx={{ mb: 3 }} />
                                <Button
                                  variant="text"
                                  sx={{ textDecoration: "underline", textUnderlineOffset: "8px" }}
                                  onClick={() =>
                                    navigate(
                                      `/secure/research/${researchStore.research!._id}/assessment/${
                                        assessment._id
                                      }/report`
                                    )
                                  }
                                >
                                  Acessar diagnóstico
                                </Button>
                              </Box>
                            )}
                        </Item>
                      </Grid>
                    );
                  })}
              </Grid>
            )}

            {userStore.userRole === DXIRoles.DXI_CONSULTANT &&
              researchStore.research.status === "active" && (
                <Fragment>
                  <Divider sx={{ my: 6 }} />

                  <AssessmentList
                    cardCanBeSelected={true}
                    hasHeader={true}
                    direction="vertical"
                    listHeaderText="Deseja incluir uma nova avaliação a esta pesquisa?"
                    cardCanBeViewed={false}
                    assessmentsByResearch={false}
                    hasProgress={false}
                    useResearchData={false}
                    ignoreAssessmentsList={assessmentsToIgnore!}
                  />

                  <ButtonLoader
                    loadingText="Carregando..."
                    text="Incluir à pesquisa"
                    area="add-to-research"
                    type="button"
                    disabled={assessmentStore.selectedAssessments.length === 0}
                    onClick={handleAdd}
                    style={{ alignSelf: "center", mt: 2 }}
                  />
                </Fragment>
              )}
          </Box>
        </Fragment>
      )}
    </Box>
  ) : (
    <LinearProgress sx={{ position: "absolute", width: "100%", top: 0, left: 0 }} />
  );
};

export default observer(ResearchViewPage);
