import { Typography, Grid, Box, Button, LinearProgress } from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb";
import { StoreContext } from "../../stores/store.context";
import { observer } from "mobx-react-lite";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CompanyForm from "./components/company-form";
import BackButton from "../../components/back-button";
import CompanyDataCard from "./components/company-data-card";
import CompanyKeyUserDataCard from "./components/company-key-user-data-card";
import ResearchList from "../research/components/research-list";
import CompanyUsersList from "./components/company-users-list";
import { DXIRoles } from "../../enums/dxi_roles.enum";

const CompanyManagePage: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker({ area: "loading-data" });
  const { breadcrumbStore, companyStore, userStore } = useContext(StoreContext);
  let { id } = useParams();
  const [currentTab, setTab] = useState(0);
  const tabs = [
    { icon: <ArticleOutlinedIcon />, name: "Dados cadastrais" },
    { icon: <PersonOutlineOutlinedIcon />, name: "Usuários convidados" },
    { icon: <AssessmentOutlinedIcon />, name: "Pesquisas" },
  ];

  const handleTabChange = (newValue: number) => setTab(newValue);

  useEffect(() => {
    if (userStore.userRole === DXIRoles.DXI_CLIENT_ADMIN) {
      const getCompanyByUser = async () => {
        await trackPromise(companyStore.getCompanyByUser(userStore.user!.id), "loading-data");
        setTab(1);
      };

      if (userStore.user) {
        getCompanyByUser();
      }
    }

    if (id) {
      var getCompanyData = async () => {
        await trackPromise(companyStore.getCompanyById(id as string), "loading-data");

        if (companyStore.company) {
          if (userStore.userRole === DXIRoles.DXI_CONSULTANT) {
            breadcrumbStore.breadcrumb = [
              { name: "Meus clientes", route: `/secure/company/list` },
              { name: companyStore.company.name },
            ];
          } else {
            breadcrumbStore.breadcrumb = [{ name: "Gerenciar pesquisas" }];
          }
        }
      };

      getCompanyData();
    }
  }, [breadcrumbStore, companyStore, id, userStore.user, userStore.userRole]);

  const getActiveTab = () => {
    let component = <CompanyForm edit={true} />;
    switch (currentTab) {
      case 0:
        component = <CompanyForm edit={true} />;
        break;
      case 1:
        component = <CompanyUsersList />;
        break;
      case 2:
        component = (
          <ResearchList
            hasFilter={true}
            filterCompany={true}
            gridColorScheme="grey"
            page="company"
          />
        );
        break;
    }
    return component;
  };

  return !promiseInProgress ? (
    <Box sx={{ mt: 6 }}>
      {breadcrumbStore.breadcrumb && (
        <Fragment>
          <Box sx={{ my: 3 }}>
            <Breadcrumb />
          </Box>
          <Box sx={{ my: 3 }}>
            <BackButton route={"/secure/company/list"} />
          </Box>
        </Fragment>
      )}

      <Fragment>
        {companyStore.company && (
          <Grid container sx={{ mt: 4 }} spacing={3}>
            <Grid item xs={12} sm={7}>
              <CompanyDataCard />
            </Grid>

            <Grid item xs={12} sm={5}>
              <CompanyKeyUserDataCard />
            </Grid>
          </Grid>
        )}

        <Grid container spacing={2} sx={{ mt: 4 }}>
          {tabs.map((tab, index) => {
            return (
              <Grid
                item
                xs={12}
                sm={index !== 0 && userStore.userRole !== DXIRoles.DXI_CONSULTANT ? 6 : 4}
                key={index}
                sx={{
                  display:
                    index === 0 && userStore.userRole !== DXIRoles.DXI_CONSULTANT
                      ? "none"
                      : "block",
                }}
              >
                <Box
                  className={currentTab !== index ? "custom-hover" : "no-hover"}
                  component={Button}
                  onClick={() => handleTabChange(index)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: currentTab === index ? "background.default" : "primary.dark",
                    minHeight: "65px",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: currentTab === index ? "primary.main" : "white",
                    }}
                  >
                    {tab.icon}
                  </Box>
                  <Typography
                    variant="body1"
                    color={currentTab === index ? "primary.main" : "white"}
                    sx={{ ml: 1 }}
                  >
                    {tab.name}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
        {companyStore.company && (
          <Box sx={{ backgroundColor: "background.default", py: 4 }}>{getActiveTab()}</Box>
        )}
      </Fragment>
    </Box>
  ) : (
    <LinearProgress sx={{ position: "absolute", width: "100%", top: 0, left: 0 }} />
  );
};

export default observer(CompanyManagePage);
