import axios from "axios";
import { Company } from "../models/company.model";
import { Pagination } from "../models/pagination.model";
import { UserData } from "../models/user.model";
import { errHandler } from "../helpers/error-handler";

export class CompanyService {
  async listCompanies(
    page: number,
    limit: number,
    orderBy?: string,
    orderByDirection?: string,
    filter?: string
  ): Promise<Pagination<Array<Company>>> {
    try {
      const res = await axios.get(process.env.REACT_APP_COMPANY_SERVICE + `/company/list`, {
        params: { page, limit, orderBy, orderByDirection, filter },
      });
      const parsedResponse = await res.data.body;

      parsedResponse.paginatedData = parsedResponse.paginatedData.map((company: any) => {
        return {
          id: company._id,
          name: company.name,
          lastUserAccessAt: company.lastUserAccessAt,
          status: company.status,
          logo: company.logo,
          cnpj: company.cnpj,
          size: company.size,
          sector: company.sector,
          website: company.website,
          allowedDomains: company.allowedDomains,
          keyUser: company.keyUser,
        };
      });

      return parsedResponse;
    } catch (e: any) {
      throw errHandler(e);
    }
  }

  async getCompanyByUser(id: string): Promise<Company> {
    try {
      const res = await axios.get(process.env.REACT_APP_COMPANY_SERVICE + `/company/user/${id}`);

      const parsedResponse: Company = await res.data.body;

      return parsedResponse;
    } catch (e: any) {
      throw errHandler(e);
    }
  }

  async getCompanyById(id: string): Promise<Company> {
    try {
      const res = await axios.get(process.env.REACT_APP_COMPANY_SERVICE + `/company/${id}`);

      const parsedResponse: Company = await res.data.body;

      return parsedResponse;
    } catch (e: any) {
      throw errHandler(e);
    }
  }

  async listConsultantCompanies(
    consultantId: string,
    page: number,
    limit: number,
    orderBy?: string,
    orderByDirection?: string,
    filter?: string
  ): Promise<Pagination<Array<Company>>> {
    try {
      const res = await axios.get(
        process.env.REACT_APP_COMPANY_SERVICE + `/company/consultant/${consultantId}/list`,
        {
          params: { page, limit, orderBy, orderByDirection, filter },
        }
      );
      const parsedResponse = await res.data.body;

      parsedResponse.paginatedData = parsedResponse.paginatedData.map((company: any) => {
        return {
          _id: company._id,
          name: company.name,
          lastUserAccessAt: company.lastUserAccessAt,
          status: company.status,
          logo: company.logo,
          cnpj: company.cnpj,
          size: company.size,
          sector: company.sector,
          website: company.website,
          allowedDomains: company.allowedDomains,
          keyUser: company.keyUser,
        };
      });

      return parsedResponse;
    } catch (e: any) {
      throw errHandler(e);
    }
  }

  async listCompanyUsers(
    id: string,
    page: number,
    limit: number,
    orderBy?: string,
    orderByDirection?: string,
    filter?: string
  ): Promise<Pagination<Array<UserData>>> {
    try {
      const res = await axios.get(process.env.REACT_APP_COMPANY_SERVICE + `/company/${id}/users`, {
        params: { page, limit, orderBy, orderByDirection, filter },
      });
      const parsedResponse = await res.data.body;
      return parsedResponse;
    } catch (e: any) {
      throw errHandler(e);
    }
  }

  async addCompany(companyData: FormData): Promise<Company> {
    try {
      const res = await axios.post(
        process.env.REACT_APP_COMPANY_SERVICE + `/company/add`,
        companyData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const parsedResponse: Company = await res.data.body;
      return parsedResponse;
    } catch (e: any) {
      throw errHandler(e);
    }
  }

  async changeCompanyLogo(logo: File, id: string) {
    try {
      var formData = new FormData();
      formData.append("logo", logo);

      const res = await axios.put(
        process.env.REACT_APP_COMPANY_SERVICE + `/company/${id}/logo`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const parsedResponse: Company = await res.data.body;
      return parsedResponse;
    } catch (e: any) {
      throw errHandler(e);
    }
  }

  async editCompany(id: string, formData: Company): Promise<Company> {
    try {
      const res = await axios.put(
        process.env.REACT_APP_COMPANY_SERVICE + `/company/${id}`,
        formData
      );

      const parsedResponse: Company = await res.data.body;

      return parsedResponse;
    } catch (e: any) {
      throw errHandler(e);
    }
  }
}
