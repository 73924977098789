import { Box, Button, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Research } from "../../../models/research.model";

interface UserInvitedProps {
  research: Research | null;
  onReset: () => void;
}

const UserInvited: React.FC<UserInvitedProps> = (props: UserInvitedProps) => {
  return (
    props.research && (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <CheckCircleOutlineIcon sx={{ fontSize: "64px", color: "success.dark", mb: 3 }} />

        <Typography variant="h5">Convite enviado com sucesso!</Typography>

        <Box display="inline" sx={{ my: 3, textAlign: "center", maxWidth: "600px" }}>
          <Typography variant="body1">
            O usuário receberá um email para finalizar o cadastro e acessar a plataforma.
          </Typography>
        </Box>

        {props.research && (
          <Button
            sx={{ my: 3 }}
            variant="contained"
            onClick={() => {
              if (props.research) {
                props.onReset();
              }
            }}
          >
            Adicionar outro usuário convidado
          </Button>
        )}
      </Box>
    )
  );
};

export default observer(UserInvited);
