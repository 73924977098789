const NotFoundSearchIcon: React.FC = () => {
  return (
    <svg
      width="250"
      height="200"
      viewBox="0 0 250 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93 135H176C179.866 135 183 131.866 183 128C183 124.134 179.866 121 176 121C176 121 170 117.866 170 114C170 110.134 173.952 107 178.826 107H189C192.866 107 196 103.866 196 100C196 96.134 192.866 93 189 93H167C170.866 93 174 89.866 174 86C174 82.134 170.866 79 167 79H207C210.866 79 214 75.866 214 72C214 68.134 210.866 65 207 65H109C105.134 65 102 68.134 102 72C102 75.866 105.134 79 109 79H69C65.134 79 62 82.134 62 86C62 89.866 65.134 93 69 93H94C97.866 93 101 96.134 101 100C101 103.866 97.866 107 94 107H54C50.134 107 47 110.134 47 114C47 117.866 50.134 121 54 121H93C89.134 121 86 124.134 86 128C86 131.866 89.134 135 93 135ZM200 100C200 103.866 203.134 107 207 107C210.866 107 214 103.866 214 100C214 96.134 210.866 93 207 93C203.134 93 200 96.134 200 100Z"
        fill="#F3F7FF"
      />
      <circle cx="120.5" cy="99.5" r="33.5" fill="#F3F7FF" stroke="#4866D9" strokeWidth="2.5" />
      <circle
        cx="120.5"
        cy="99.5"
        r="26.5"
        fill="white"
        stroke="#4866D9"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <ellipse
        cx="107.4"
        cy="122.161"
        rx="3.60172"
        ry="2.08595"
        stroke="#4866D9"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <circle
        cx="111.5"
        cy="90.5"
        r="9.5"
        stroke="#4866D9"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path d="M148 126L154 132" stroke="#4866D9" strokeWidth="2.5" />
      <path
        d="M153.03 137.884C151.138 135.992 151.138 132.923 153.03 131.03C154.923 129.138 157.992 129.138 159.884 131.03L170.97 142.116C172.862 144.008 172.862 147.077 170.97 148.97C169.077 150.862 166.008 150.862 164.116 148.97L153.03 137.884Z"
        fill="#E8F0FE"
        stroke="#4866D9"
        strokeWidth="2.5"
      />
      <path d="M158 133L169 144" stroke="white" strokeWidth="2.5" strokeLinecap="round" />
      <path
        d="M163.324 89.5C161.944 89.5 160.824 90.6193 160.824 92H163.324V89.5ZM180.5 89.5H163.324V92H180.5V89.5ZM183 92C183 90.6193 181.881 89.5 180.5 89.5V92H183ZM180.5 94.5C181.881 94.5 183 93.3807 183 92H180.5V94.5ZM163.324 94.5H180.5V92H163.324V94.5ZM160.824 92C160.824 93.3807 161.944 94.5 163.324 94.5V92H160.824ZM185.279 89.5C183.898 89.5 182.779 90.6193 182.779 92H185.279V89.5ZM187.5 89.5H185.279V92H187.5V89.5ZM190 92C190 90.6193 188.881 89.5 187.5 89.5V92H190ZM187.5 94.5C188.881 94.5 190 93.3807 190 92H187.5V94.5ZM185.279 94.5H187.5V92H185.279V94.5ZM182.779 92C182.779 93.3807 183.898 94.5 185.279 94.5V92H182.779ZM166 99.7773H166V97.2773C164.619 97.2773 163.5 98.3966 163.5 99.7773H166ZM166 99.7773H166H163.5C163.5 101.158 164.619 102.277 166 102.277V99.7773ZM174.176 99.7773H166V102.277H174.176V99.7773ZM174.176 99.7773V102.277C175.556 102.277 176.676 101.158 176.676 99.7773H174.176ZM174.176 99.7773H176.676C176.676 98.3966 175.556 97.2773 174.176 97.2773V99.7773ZM166 99.7773H174.176V97.2773H166V99.7773Z"
        fill="#4866D9"
      />
      <path
        d="M52.2793 110.5C50.8986 110.5 49.7793 111.619 49.7793 113H52.2793V110.5ZM56.5005 110.5H52.2793V113H56.5005V110.5ZM59.0005 113C59.0005 111.619 57.8812 110.5 56.5005 110.5V113H59.0005ZM56.5005 115.5C57.8812 115.5 59.0005 114.381 59.0005 113H56.5005V115.5ZM52.2793 115.5H56.5005V113H52.2793V115.5ZM49.7793 113C49.7793 114.381 50.8986 115.5 52.2793 115.5V113H49.7793ZM62.3247 110.5C60.944 110.5 59.8247 111.619 59.8247 113H62.3247V110.5ZM79.5005 110.5H62.3247V113H79.5005V110.5ZM82.0005 113C82.0005 111.619 80.8812 110.5 79.5005 110.5V113H82.0005ZM79.5005 115.5C80.8812 115.5 82.0005 114.381 82.0005 113H79.5005V115.5ZM62.3247 115.5H79.5005V113H62.3247V115.5ZM59.8247 113C59.8247 114.381 60.944 115.5 62.3247 115.5V113H59.8247ZM76.0005 121.777V119.277C74.6198 119.277 73.5005 120.397 73.5005 121.777H76.0005ZM76.0005 121.777H73.5005C73.5005 123.158 74.6198 124.277 76.0005 124.277V121.777ZM84.1763 121.777H76.0005V124.277H84.1763V121.777ZM84.1763 121.777V124.277C85.557 124.277 86.6763 123.158 86.6763 121.777H84.1763ZM84.1763 121.777H86.6763C86.6763 120.397 85.557 119.277 84.1763 119.277V121.777ZM76.0005 121.777H84.1763V119.277H76.0005V121.777Z"
        fill="#4866D9"
      />
    </svg>
  );
};

export default NotFoundSearchIcon;
