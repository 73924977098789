import { Accordion, AccordionSummary, Typography, AccordionDetails, Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { UserData } from "../../../models/user.model";

interface ResearchViewAccordionProps {
  users: Array<UserData>;
  label: string;
}

const ResearchViewAccordion: React.FC<ResearchViewAccordionProps> = (
  props: ResearchViewAccordionProps
) => {
  return props.users && props.users.length > 0 ? (
    <Accordion sx={{ backgroundColor: "background.default" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "grey.c59" }} />}>
        <Typography>
          {props.label} ({props.users.length})
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {props.users.map((userAssessmentData: any, index: number) => (
            <Box sx={{ display: "flex", alignItems: "center", pl: 4 }} key={index}>
              <Typography variant="body2" color="grey.c59">
                {userAssessmentData.user.firstName + " " + userAssessmentData.user.lastName}
              </Typography>
              <Box
                sx={{
                  ml: 3,
                  backgroundColor: "grey.cf2",
                  py: 1,
                  px: 2,
                  borderRadius: 8,
                }}
              >
                <Typography variant="body2" color="black" fontWeight={500}>
                  {Math.round(userAssessmentData.progress)} %
                </Typography>
              </Box>

              <Box
                sx={{
                  ml: 2,
                  borderRadius: 8,
                }}
              >
                {userAssessmentData.lastUserAccessAt ? new Date().toLocaleDateString("pt-BR") : ""}
              </Box>
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  ) : (
    <Accordion sx={{ backgroundColor: "background.default" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "grey.c59" }} />}>
        <Typography>{props.label} (0)</Typography>
      </AccordionSummary>
    </Accordion>
  );
};

export default observer(ResearchViewAccordion);
