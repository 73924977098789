import { makeAutoObservable } from "mobx";
import { AssessmentService } from "../services/assessment.service";
import { Pagination } from "../models/pagination.model";
import { Assessment } from "../models/assessment.model";
import { ToastStore } from "./toast.store";

export class AssessmentStore {
  private _assessments: Pagination<Array<Assessment>> | null = null;
  private _selectedAssessments: Array<string> = [];
  private _assessment: Assessment | null = null;
  private _currentQuestion: number = 1;

  toastStore?: ToastStore;

  constructor(toastStore?: ToastStore) {
    makeAutoObservable(this);
    if (toastStore) {
      this.toastStore = toastStore;
    }
  }

  async listAssessments(
    page: number,
    limit: number,
    orderBy?: string,
    orderbyDirection?: string,
    filter?: string
  ): Promise<void> {
    try {
      const assessmentService = new AssessmentService();
      const res: Pagination<Array<Assessment>> = await assessmentService.listAssessments(
        page,
        limit,
        orderBy,
        orderbyDirection
      );

      if (res && page === 1) {
        this.assessments = res;
      } else {
        this.pushAssessments(res.paginatedData);
      }
    } catch (e: any) {
      this.assessments = null;
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e.toString(), type: "error" });
      }
    }
  }

  async getAssessmentById(id: string): Promise<void> {
    try {
      const assessmentService = new AssessmentService();
      const res: Assessment = await assessmentService.getAssessmentById(id);
      this.assessment = res;
    } catch (e: any) {
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e.toString(), type: "error" });
      }
    }
  }

  async updateAssessmentAccess(
    assessmentId: string,
    researchId: string,
    userId: string
  ): Promise<void> {
    try {
      const assessmentService = new AssessmentService();
      await assessmentService.updateAssessmentAccess(assessmentId, researchId, userId);
    } catch (e: any) {
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e.toString(), type: "error" });
      }
    }
  }

  handleCheckAllAssessments = (checked: any) => {
    if (this.assessments) {
      if (checked) {
        this.assessments.paginatedData.forEach((assessment) => {
          this._selectedAssessments.push(assessment._id!);
        });
      } else {
        this._selectedAssessments = [];
      }
    }

    this._selectedAssessments = [...this.selectedAssessments];
  };

  handleSelectedAssessments = (assessment: string) => {
    if (this._selectedAssessments?.includes(assessment)) {
      let index = this._selectedAssessments?.indexOf(assessment);
      this._selectedAssessments?.splice(index, 1);
    } else {
      this._selectedAssessments?.push(assessment);
    }

    this._selectedAssessments = [...this.selectedAssessments];
  };

  pushAssessments = (assessments: Array<Assessment>) =>
    (this._assessments!.paginatedData = [...this._assessments!.paginatedData, ...assessments]);

  set assessments(assessmentsList: Pagination<Array<Assessment>> | null) {
    this._assessments = assessmentsList;
  }

  get assessments(): Pagination<Array<Assessment>> | null {
    return this._assessments;
  }

  get selectedAssessments() {
    return this._selectedAssessments;
  }

  set selectedAssessments(assessments: Array<string>) {
    this._selectedAssessments = assessments;
  }

  set assessment(assessmentData: Assessment | null) {
    this._assessment = assessmentData;
  }

  get assessment() {
    return this._assessment;
  }

  get currentQuestion() {
    return this._currentQuestion;
  }

  set currentQuestion(question: number) {
    this._currentQuestion = question;
  }
}
