import { Box, CircularProgress } from "@mui/material";

const CircularLoader: React.FC = () => {
  return (
    <Box sx={{ p: 4, margin: "auto" }}>
      <CircularProgress />
    </Box>
  );
};

export default CircularLoader;
