import { Stepper, Step, StepButton, StepLabel, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { StoreContext } from "../../../stores/store.context";

const CompanyFormStepper: React.FC = () => {
  const steps = ["Informe os dados da empresa", "Dados do usuário cliente master", "Pronto!"];
  const { companyStore } = useContext(StoreContext);

  const stepperConnector = () => {
    return {
      ".MuiStepConnector-root": {
        top: "68px",
        ".MuiStepConnector-line": {
          borderColor: "success.main",
        },
      },
    };
  };

  const StepperLabelSx = {
    "&.MuiStepLabel-alternativeLabel": {
      flexDirection: "column-reverse",

      justifyContent: "space-between",
    },
    ".MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      marginTop: "initial",
      minHeight: "50px",
      marginBottom: ".5rem",
    },
  };

  return (
    <Stepper activeStep={companyStore.companyFormStep} alternativeLabel sx={{ mb: 6 }}>
      {steps.map((label, index) => (
        <Step key={label} sx={stepperConnector()}>
          {index !== 2 ? (
            <StepButton color="inherit" onClick={() => (companyStore.companyFormStep = index)}>
              <StepLabel sx={StepperLabelSx}>
                <Typography
                  variant="h4"
                  sx={{ maxWidth: "180px" }}
                  color={
                    companyStore.companyFormStep === index ? "primary.dark" : "secondary.lighter"
                  }
                >
                  {label}
                </Typography>
              </StepLabel>
            </StepButton>
          ) : (
            <StepLabel sx={StepperLabelSx}>
              <Typography
                variant="h4"
                color={
                  companyStore.companyFormStep === index ? "primary.dark" : "secondary.lighter"
                }
              >
                {label}
              </Typography>
            </StepLabel>
          )}
        </Step>
      ))}
    </Stepper>
  );
};

export default observer(CompanyFormStepper);
