import { UserData } from "./user.model";

export class Company {
  _id?: string;
  status?: string;
  name: string;
  cnpj: string;
  sector: string;
  size: string;
  allowedDomains: Array<string>;
  createdBy?: UserData;
  keyUser?: UserData;
  logo?: File | null | string;
  addAllowedDomain?: string;
  logoPreview?: string;
  addedDomain?: string;
  otherSector?: string;
  website?: string;

  constructor() {
    this.name = "";
    this.cnpj = "";
    this.sector = "";
    this.size = "";
    this.allowedDomains = [];
    this.createdBy = new UserData();
    this.keyUser = new UserData();
    this.logo = null;
  }
}
