import { makeAutoObservable } from "mobx";
import { Breadcrumb } from "../models/breadcrumb.model";

export class BreadcrumbStore {
  private _breadcrumb: Array<Breadcrumb> | null = null;

  constructor() {
    makeAutoObservable(this);
    this.breadcrumb = null;
  }

  get breadcrumb(): Array<Breadcrumb> | null {
    return this._breadcrumb;
  }

  set breadcrumb(breadcrumb: Array<Breadcrumb> | null) {
    this._breadcrumb = breadcrumb;
  }
}
