import { Box, Container, Grid } from "@mui/material";
import MetaDxiLogoFooter from "../assets/images/meta-dxi-logo-footer";
import SocialMediaIcons from "../components/social-media";
import { useContext } from "react";
import { StoreContext } from "../stores/store.context";

const Footer: React.FC = () => {
  const { themeStore } = useContext(StoreContext);

  return (
    <Box sx={{ mt: 10, width: "100%" }}>
      <Box sx={{ backgroundColor: "primary.main", py: 4 }}>
        <Container
          sx={{
            maxWidth: {
              xs: themeStore.theme.breakpoints.values.lg,
              xl: themeStore.theme.breakpoints.values.xl,
            },
          }}
        >
          <Grid
            container
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent={{ xs: "center", sm: "space-between" }}
            alignItems="center"
          >
            <Grid item>
              <MetaDxiLogoFooter />
            </Grid>

            <Grid item>
              <SocialMediaIcons sitePos="left" />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
